import React, { useRef, useEffect, useState } from "react";

interface ExerciseTitleProps {
  text: string;
}

const UPExerciseTitle: React.FC<ExerciseTitleProps> = ({ text }) => {
  const titleRef = useRef<HTMLDivElement | null>(null);
  const [fontSize, setFontSize] = useState(16); // Initial font size
  const minFontSize = 12; // Minimum font size threshold

  const checkOverflow = (element: HTMLDivElement): boolean => {
    return element.scrollHeight > element.clientHeight;
  };

  useEffect(() => {
    const titleElement = titleRef.current;
    if (!titleElement) return;

    let currentFontSize = fontSize;

    const adjustFontSize = () => {
      let lastValidFontSize = currentFontSize;
      while (checkOverflow(titleElement) && currentFontSize > minFontSize) {
        currentFontSize -= 1;
        titleElement.style.fontSize = `${currentFontSize}px`;
        if (!checkOverflow(titleElement)) {
          lastValidFontSize = currentFontSize;
        }
      }
      // Ensure the state is updated to the last valid font size that fits
      setFontSize(lastValidFontSize);
    };

    adjustFontSize();
  }, [text, fontSize, minFontSize]);

  return (
    <div
      ref={titleRef}
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: 0,
        display: "-webkit-box",
        marginTop: "2px",
        lineHeight: "21px",
        maxHeight: "63px",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        fontSize: `${fontSize}px`
      }}
      className="ep-exercise-title"
    >
      {text}
    </div>
  );
};

export default UPExerciseTitle;
