import React, { useEffect, useState } from "react";
import { Form, Button, Checkbox, Input } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import MyDivider from "../../components/helper/MyDivider";
import TextTemplateEdit from "../../components/helper/TextTemplateEdit";

const WhatsappSmsOptions: React.FC = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    setLoading(true);
    // return // console.log(values, "rval");
    post("account/upsertSettings", values)
      .then(r => {
        // console.log("res data", r.data);
        if (r.data.user) {
          setUser(r.data.user);
        }
        // if (r.data.clinics.length > 1) navigate("/choose-clinic", {replace:true});
        // else navigate("/patients", {replace:true});
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // console.log(user);
    if (user !== null)
      form.setFieldsValue({
        display_whatsapp_sms_button: user.settings.display_whatsapp_sms_button,
        notify_when_credit_run_low: user.notify_when_credit_run_low,
        default_phone_dial_code: user.settings.default_phone_dial_code
      });
  }, [user, navigate]);

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="on"
    >
      <Form.Item name="display_whatsapp_sms_button" valuePropName="checked">
        <Checkbox>{t("settings.displayWaSmsBtn")} </Checkbox>
      </Form.Item>
      <Form.Item name="notify_when_credit_run_low" valuePropName="checked">
        <Checkbox>{t("settings.notifyLowCredit")} </Checkbox>
      </Form.Item>
      <Form.Item
        label={t("settings.defaultDialCode")}
        name="default_phone_dial_code"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <MyDivider title={t("sms.defaultSmsText")} />

      <Form.Item name="sms_msg" hidden>
        <Input />
      </Form.Item>
      <TextTemplateEdit
        type="sms"
        rows={4}
        onChange={d => {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            sms_msg: d
          });
        }}
      />

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" block>
          {t("save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WhatsappSmsOptions;
