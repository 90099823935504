import {
  Button,
  Dropdown,
  Input,
  Result,
  Space,
  Table,
  Typography
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useState } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import { formatDate } from "../../helpers/dateHelper";
import { PlanListProfile } from "../../types/Patient";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faChevronRight,
  faChild,
  faPlus,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pract from "../../components/helper/Pract";
import { getPlanEditLink } from "../../helpers/helper";
import {
  faEdit,
  faEnvelope,
  faFileLines,
  faTrashAlt
} from "@fortawesome/free-regular-svg-icons";
import ConfirmButton from "../../components/helper/ConfirmButton";
import { get } from "../../api/Request";
import { Dialog, List, SwipeAction } from "antd-mobile";
import { config } from "../../data/Constants";
import { useAuth } from "../../context/AuthContext";
import LangPrint from "../../components/helper/LangPrint";

interface Props {
  plans: PlanListProfile[];
  patientId: number;
  firstName: string;
  lastName: string;
  onChange: () => void;
}

const PatientPlans: React.FC<Props> = ({
  plans,
  patientId,
  firstName,
  lastName,
  onChange
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [emailPlanLoading, setEmailPlanLoading] = useState(0);
  const [search, setSearch] = useState("");
  const { Text } = Typography;

  const filteredPlans = plans.filter(p =>
    p.name.toLowerCase().includes(search.toLowerCase())
  );
  const { language, isTabletOrMobile } = useGlobalContext();

  const columns: ColumnsType<PlanListProfile> = [
    {
      title: t("created"),
      dataIndex: "created",
      key: "created",
      className: "pointer",
      sorter: (a, b) => a.created.localeCompare(b.created),
      render: date => (
        <span className="tsec_col">{formatDate(date, language)}</span>
      ),
      responsive: ["md"]
    },
    {
      title: t("name"),
      key: "name",
      className: "pointer",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <>
          <Link to={"/patient/plan/" + record.id} className="tmain_col">
            {record.has_tracking === 1 && (
              <FontAwesomeIcon
                icon={faChartSimple}
                style={{ marginRight: 8 }}
              />
            )}
            {record.name.length ? record.name : t("plan.noName")}{" "}
            {record.lang !== user?.lang && <LangPrint lang={record.lang} />}
          </Link>
        </>
      )
    },
    {
      title: t("practitioner"),
      key: "practitioner",
      className: "pointer",
      sorter: (a, b) => a.created.localeCompare(b.created),
      render: (_, record) => <Pract id={record.practitioner_id} />,
      responsive: ["md"]
    },
    {
      key: "actions",
      className: "pointer",
      render: (text, record) => (
        <Space.Compact style={{ float: "right" }}>
          <Button
            key="etr"
            // className=""
            // className="green-btn"
            // type="primary"
            title={t("edit")}
            onClick={e => {
              navigate(getPlanEditLink(record));
              e.stopPropagation();
            }}
            size="middle"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            key="eplan"
            size="middle"
            className="e"
            title={t("plan.sendEmail")}
            onClick={e => {
              e.stopPropagation();
              setEmailPlanLoading(record.id);
              get("send/emailPlan/" + record.id)
                .catch(_ => {})
                .finally(() => setEmailPlanLoading(0));
            }}
            loading={emailPlanLoading === record.id}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </Button>

          <Button
            key="pplan"
            size="middle"
            title={t("print")}
            className="e"
            onClick={e => {
              e.stopPropagation();
              let data = {
                plan_id: record.id.toString(),
                action: "print"
              };
              window.open(
                config.API_URL +
                  "plan/planPrintDownload?" +
                  new URLSearchParams(data).toString()
              );
            }}
          >
            <FontAwesomeIcon icon={faPrint} />
          </Button>

          <Dropdown
            placement="bottomRight"
            menu={{
              items: [
                {
                  label: t("plan.exportToTemplate"),
                  key: "export",
                  onClick: e => {
                    e.domEvent.stopPropagation();
                    get("plan/exportPlanToTemplate/" + record.id)
                      .then(() => {
                        //  onChange();
                      })
                      .catch(e => {});
                  }
                },
                {
                  label: t("download"),
                  key: "down",
                  onClick: e => {
                    e.domEvent.stopPropagation();
                    let data = {
                      plan_id: record.id.toString(),
                      action: "download"
                    };
                    window.open(
                      config.API_URL +
                        "plan/planPrintDownload?" +
                        new URLSearchParams(data).toString(),
                      "Download"
                    );
                  }
                }
              ]
            }}
          >
            <Button size="middle" onClick={e => e.stopPropagation()}>
              ...
            </Button>
          </Dropdown>
          <ConfirmButton
            key="4"
            modalBtnText={t("delete")}
            btnText={<FontAwesomeIcon icon={faTrashAlt} />}
            message={t("confirmations.deletePlan")}
            btnProps={{ danger: true, size: "middle" }}
            onConfirm={() => {
              get("plan/delete/" + record.id)
                .then(() => {
                  onChange();
                })
                .catch(e => {});
            }}
          />
        </Space.Compact>
      )
    }
  ];

  return (
    <>
      {!isTabletOrMobile && plans.length > 0 && (
        <>
          <Input.Search
            placeholder={t("search")}
            onChange={e => setSearch(e.target.value)}
            style={{ marginBottom: 16 }}
          />
          <Table
            pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
            dataSource={filteredPlans}
            columns={columns}
            rowKey="id"
            onRow={record => {
              return {
                onClick: event => {
                  navigate("/patient/plan/" + record.id);
                },
                onDoubleClick: event => {}, // double click row
                onContextMenu: event => {}, // right button click row
                onMouseEnter: event => {}, // mouse enter row
                onMouseLeave: event => {} // mouse leave row
              };
            }}
          />
        </>
      )}
      {isTabletOrMobile && plans.length > 0 && (
        <List>
          {plans.length > 0 &&
            plans.map(p => (
              <SwipeAction
                // ref={ref}
                key={p.id}
                closeOnAction={true}
                closeOnTouchOutside={false}
                rightActions={[
                  {
                    key: "delete",
                    text: t("delete"),
                    color: "danger",
                    onClick: e => {
                      Dialog.confirm({
                        content: t("confirmations.deletePlan"),
                        confirmText: t("delete"),
                        cancelText: t("cancel"),
                        onConfirm: () => {
                          get("plan/delete/" + p.id)
                            .then(() => {
                              onChange();
                            })
                            .catch(e => {});
                        }
                      });
                    }
                  },
                  {
                    key: "edit",
                    text: t("edit"),
                    onClick: e => {
                      navigate(getPlanEditLink(p));
                      e.stopPropagation();
                    }
                  }
                ]}
              >
                <List.Item
                  className="one-line-desc"
                  key={p.id}
                  clickable
                  onClick={() => {
                    navigate("/patient/plan/" + p.id);
                  }}
                  prefix={
                    p.has_tracking === 1 ? (
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        className="prefix-icon"
                        style={{
                          color: "#1179bb"
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faFileLines}
                        className="prefix-icon"
                        style={{
                          color: "#1179bb"
                        }}
                      />
                    )
                  }
                  arrow={
                    <>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </>
                  }
                  description={
                    <>
                      <Text type="secondary">
                        {formatDate(p.created, language)}
                      </Text>
                    </>
                  }
                >
                  {p.name.length > 0 ? (
                    p.name
                  ) : (
                    <Text type="secondary">{t("plan.noName")}</Text>
                  )}
                </List.Item>
              </SwipeAction>
            ))}
        </List>
      )}
      {plans.length === 0 && patientId && (
        <Result
          icon={<FontAwesomeIcon icon={faChild} size="4x" />}
          title={t("plan.noPlans")}
          extra={
            <Button type="primary" className="green-btn">
              <Link to={"/exercise-plan?patient=" + patientId}>
                <FontAwesomeIcon icon={faPlus} /> {t("plan.createNewPlan")}
              </Link>
            </Button>
          }
        />
      )}
    </>
  );
};

export default PatientPlans;
