import React from "react";
import { Col, Row, Alert } from "antd";
import { useTranslation } from "react-i18next";

import MyPageHeader from "../../components/layout/MyPageHeader";
import { useMediaQuery } from "react-responsive";

const BannedAccount: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { t } = useTranslation();

  return (
    <div className="site-card-wrapper">
      {isTabletOrMobile && (
        <MyPageHeader title={t("account.bannedTitle")}></MyPageHeader>
      )}
      <Row>
        <Col xs={{ span: 24, offset: 0 }} xl={{ span: 10, offset: 7 }}>
          {!isTabletOrMobile && (
            <MyPageHeader title={t("account.bannedTitle")}></MyPageHeader>
          )}
          <Alert
            message={t("account.bannedTitle")}
            description={t("account.bannedText")}
            type="error"
          />
        </Col>
      </Row>
    </div>
  );
};

export default BannedAccount;
