import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import Column from "../components/layout/Col";
import MyPageHeader from "../components/layout/MyPageHeader";

const NotFound: React.FC = () => {
  return (
    <Column size="standard" className="box">
      <MyPageHeader key="ph" title="404" />
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary">
            <Link to="/">Back Home</Link>
          </Button>
        }
      />
    </Column>
  );
};

export default NotFound;
