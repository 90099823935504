import React, { useEffect } from "react";
import TopPrescribersMemo from "./TopPrescribers";
import AccountTotalStats from "./AccountTotalStats";

const StatsGlobal: React.FC = () => {
  useEffect(() => {
    // get("account/totalStats").then(res => {
    //   console.log("t stats, ", res.data);
    //   // setTopPrescribers(res.data);
    // });
  }, []);

  return (
    <>
      <>
        <AccountTotalStats />
        <TopPrescribersMemo />
      </>
    </>
  );
};

function areEqual(prevProps, nextProps) {
  // console.log("prev, next preview", prevProps, nextProps);

  return true;
  // return (
  //   prevProps.key === nextProps.key &&
  //   prevProps.imageType === nextProps.imageType &&
  //   prevProps.added === nextProps.added
  // );
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

const StatsGlobalMemo = React.memo(StatsGlobal, areEqual);
export default StatsGlobalMemo;
// export default React.memo(Templates, areEqual);
// export default Templates;
