import { Language } from "./General";

export enum UserRole {
  practitioner = 0,
  admin = 1,
  owner = 22
}
export interface PractitionerList {
  role: UserRole;
  archived: boolean;
  can_view_other_patients: boolean;
  access_to_all_clinics: boolean;
  first_name: string;
  last_name: string;
  id: number;
  email: string;
  last_login: string | null;
  created: string;
  clinics: number[];
}

export interface PractitionerUpsert {
  id: number;
  role: UserRole;
  archived: boolean;
  can_view_other_patients: boolean;
  access_to_all_clinics: boolean;
  first_name: string;
  last_name: string;
  email: string;
  clinics: number[];
  sldve?: boolean;
  require_password_change?: boolean;
  share_templates?: boolean;
  new_password?: string;
  password?: string;
  last_login: string | null;
}

export interface ExerciseInStats {
  id: number;
  code: string;
  lineart: string;
  lineart_thumbnail: string;
  image: string;
  image_thumbnail: string;
  video: string;
  active: number;
  advice_sheet_pdf: string;
  last_update: string;
  owner_id: number;
  practitioner_id: number;
  title: string;
  desc: string;
  exercise_id: number;
}

export interface ExercisesStat {
  exercise_id: number;
  total: number;
  e: ExerciseInStats;
}

export interface LoginLog {
  id: number;
  email: string;
  pass: string;
  ip: string;
  user_agent: string;
  time: string;
  success: number;
  member_id?: number;
  practitioner_id: number;
}

export interface PractitionerStats {
  plans_count: number;
  templates_count: number;
  patients_count: number;
  sms_sent_count: number;
  emails_sent_count: number;
  whatsapp_sent_count: number;
  exercises_stats: ExercisesStat[];
  exercises_count: number;
  login_log: LoginLog[];
}
export interface TotalStats {
  plans_count: number;
  templates_count: number;
  patients_count: number;
  tr_sessions: number;
}
