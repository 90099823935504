import React, { useEffect, useState } from "react";
import { get, post } from "../../api/Request";
import {
  PatientProfile,
  PatientTable,
  PatientTableDTO,
  PatientUpsert
} from "../../types/Patient";
import {
  Table,
  Button,
  Dropdown,
  Typography,
  Input,
  Select,
  Row,
  Col,
  Result,
  Skeleton,
  Alert,
  Space
} from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import Column from "../../components/layout/Col";
import UpsertPatientModal from "./UpsertPatientModal";
import { ImageType } from "../../types/Plan";
import { formatDate, formatDobForForm } from "../../helpers/dateHelper";
import { useGlobalContext } from "../../context/GlobalContext";
import { useNavigate, Link } from "react-router-dom";
import { FilterValue } from "antd/lib/table/interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faFilter,
  faPlus,
  faSearch
} from "@fortawesome/free-solid-svg-icons";

import MyPageHeader from "../../components/layout/MyPageHeader";
import debounce from "lodash/debounce";
import {
  faCircleDown,
  faRectangleList,
  faUser
} from "@fortawesome/free-regular-svg-icons";
import ConfirmButton from "../../components/helper/ConfirmButton";
import Pract from "../../components/helper/Pract";
import { Dialog, Divider, DotLoading, List, SwipeAction } from "antd-mobile";
import InfiniteScroll from "react-infinite-scroll-component";
import TransferPatientModal from "./TransferPatientModal";
import { UserRole } from "../../types/Practitioner";
import ChooseClinicSelect from "../../components/helper/ChooseClinicSelect";

const { Option } = Select;
interface TableProps {
  pageSize: number;
  page: number;
  sort: string;
  sortDirection: "ascend" | "descend";
  search: string;
  allClinics: number;
  practitionerId: number;
}

const MyPatients: React.FC = () => {
  const { Search } = Input;
  const { user } = useAuth();
  const { Text } = Typography;
  const { language, isTabletOrMobile, isTablet, isMobile } = useGlobalContext();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [patients, setPatients] = useState<PatientTable[]>();

  const [tableProps, setTableProps] = useState<TableProps>();
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ["bottomCenter"]
  });
  const [loading, setLoading] = useState(true);
  const [loadingImportNew, setLoadingImportNew] = useState(false);
  const [invalidApiKeyMsg, setInvalidApiKeyMsg] = useState<string>();
  const [showClinicsFilter, setShowClinicsFilter] = useState(false);
  const [showPractitionerFilter, setShowPractitionerFilter] = useState(false);
  const [showFilterOnMobile, setShowFilterOnMobile] = useState(
    isTabletOrMobile ? false : true
  );
  const [noData, setNoData] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [patientToTransfer, setPatientToTransfer] = useState<{
    patientId: number;
    memberId: number;
  }>();

  useEffect(() => {
    if (
      patients !== undefined &&
      tableProps !== undefined &&
      tableProps.search.length === 0 &&
      patients.length === 0 &&
      tableProps.practitionerId === 0 &&
      tableProps.allClinics === 0
    ) {
      setNoData(true);
    } else setNoData(false);
    return;
  }, [patients, tableProps, showPractitionerFilter]);

  useEffect(() => {
    if (user) {
      setShowClinicsFilter(user.current_clinics.length > 1);
      if (
        (user.practitioner.can_view_other_patients ||
          user.practitioner.role === UserRole.owner) &&
        Object.keys(user.practitioners).length > 1
      )
        setShowPractitionerFilter(true);
      else setShowPractitionerFilter(false);
    }
  }, [user]);

  const [showPatientModal, setShowPatientModal] = useState(false);
  const newPatientUpsert = {
    id: 0,
    first_name: "",
    last_name: "",
    send_login_details: true,
    patient_consent: true,
    dial_code: user?.settings.default_phone_dial_code,
    image_type: ImageType.photo
  };

  const openEditModal = (id: number) => {
    get<PatientProfile>("patient/profile/" + id)
      .then(r => {
        setPatientUpsertData({
          ...r.data.patient,
          patient_consent: true,
          dob:
            r.data.patient.dob && formatDobForForm(r.data.patient.dob, language)
        });
        setShowPatientModal(true);
      })
      .catch(_ => {})
      .finally(() => {});
  };
  const [patientUpsertData, setPatientUpsertData] =
    useState<PatientUpsert>(newPatientUpsert);

  useEffect(() => {
    if (user) {
      setTableProps({
        page: 1,
        pageSize: isTabletOrMobile ? 18 : 10,
        sort: "last_activity",
        // sort: "",
        sortDirection: "descend",
        search: tableProps?.search ?? "",
        allClinics:
          user.practitioner.role === UserRole.admin ||
          user.practitioner.role === UserRole.owner ||
          user.practitioner.access_to_all_clinics
            ? tableProps?.allClinics === 0
              ? 0
              : 1
            : 0,
        practitionerId:
          user.practitioner.can_view_other_patients ||
          user.practitioner.role === UserRole.owner
            ? 0
            : user.practitioner.id
      });
    }
  }, [user]);
  useEffect(() => {
    if (tableProps !== undefined) getTabelData();
  }, [tableProps, user]);

  const getTabelData = () => {
    setLoading(true);

    get<PatientTableDTO>("patient/all", tableProps)
      .then(r => {
        if (r.data.patients.length === 0) setNoData(true);
        if (
          patients !== undefined &&
          tableProps &&
          tableProps.page > 1 &&
          isTabletOrMobile
        )
          setPatients(patients.concat(r.data.patients));
        else setPatients(r.data.patients);
        // setPatients(r.data.patients);
        setPagination({ ...pagination, total: r.data.total_items });
        setHasMore(r.data.current_page <= r.data.total_pages);
      })
      .catch(_ => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      user?.clinic.integration_enabled === "cliniko" ||
      user?.clinic.integration_enabled === "nookal"
    ) {
      post("integrations/checkApiKey")
        .then(r => {
          if (r.data.status === "error") setInvalidApiKeyMsg(r.data.msg);
        })
        .catch(e => {});
    }
  }, [user]);

  const openPatient = (id: number) => {
    navigate("/patient/" + id);
  };

  const deletePatient = (id: number) => {
    get("patient/delete/" + id)
      .then(() => {
        setPatients(patients?.filter(p => p.id !== id));
        // getTabelData();
      })
      .catch(_ => {});
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter
  ) => {
    // console.log("filters TABLE Change", filters);
    if (tableProps !== undefined) {
      setPagination(pagination);
      setTableProps({
        ...tableProps,
        page: pagination.current ?? tableProps.page,
        pageSize: pagination.pageSize ?? tableProps.pageSize,
        sort: sorter.columnKey ?? tableProps.sort,
        sortDirection: sorter.order ?? tableProps.sortDirection
      });
    }
  };

  const renderNoDataMessage = () => {
    if (!loading && tableProps && patients && patients.length === 0) {
      if (
        tableProps.search.length > 0 ||
        tableProps.practitionerId > 0 ||
        tableProps.allClinics > 0
      ) {
        return (
          <Result
            icon={<FontAwesomeIcon icon={faUser} size="4x" />}
            title={t("patient.nothingFound")}
            subTitle={t("patient.nothingFound2")}
            extra={
              <Button
                type="primary"
                className="green-btn"
                onClick={() => {
                  setPatientUpsertData(newPatientUpsert);
                  setShowPatientModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />

                {t("patient.addNewPatient")}
              </Button>
            }
          />
        );
      } else {
        return (
          <Result
            icon={<FontAwesomeIcon icon={faUser} size="4x" />}
            title={t("patient.noPatients")}
            subTitle={t("patient.noPatients2")}
            extra={
              <Button
                type="primary"
                className="green-btn"
                onClick={() => {
                  setPatientUpsertData(newPatientUpsert);
                  setShowPatientModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />

                {t("patient.createFirst")}
              </Button>
            }
          />
        );
      }
    }
    return null;
  };

  const columns: ColumnsType<PatientTable> = user?.clinics
    ? [
        {
          title: t("lastActivity"),
          dataIndex: "last_activity",
          key: "last_activity",
          sorter: true,
          defaultSortOrder: "ascend",

          render: date => (
            <Text type="secondary">{formatDate(date, language)}</Text>
          ),
          responsive: ["lg"]
        },
        {
          title: t("name"),
          key: "name",
          sorter: true,

          render: (_, record) => (
            <>
              {isTabletOrMobile ? (
                <Row align="middle">
                  <Col>
                    <Text className="tmain_col">
                      {record.first_name} {record.last_name}
                    </Text>
                    <br />
                    <Text type="secondary">
                      {/* {t("lastActivity")}:{" "} */}
                      {formatDate(record.last_activity, language)}
                    </Text>
                  </Col>
                  <Col flex="auto" style={{ textAlign: "center" }}>
                    {isTablet && <Pract id={record.practitioner_id} />}
                  </Col>
                  <Col>
                    <Text type="secondary">
                      {record.plans_count}{" "}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Text>
                  </Col>
                </Row>
              ) : (
                <Text className="tmain_col">
                  {record.first_name} {record.last_name}
                </Text>
              )}
            </>
          )
        },
        {
          title: t("plans"),
          dataIndex: "plans_count",
          key: "plans_count",
          render: text => <Text type="secondary">{text}</Text>,
          responsive: ["md"]
        },

        {
          title: t("practitioner"),
          key: "practitioner",

          render: (_, record) => <Pract id={record.practitioner_id} />,
          responsive: ["lg"]
        },
        {
          title: t("clinic.title"),
          key: "id",
          // key: user.clinics.length === 1 ? "hiddencid" : "clinic_id",

          render: (d, record) => (
            <Text type="secondary">
              {user.clinics.find(c => c.id === record.clinic_id)?.clinic_name}
            </Text>
          ),
          responsive: ["lg"]
          // hidden: true
        },
        {
          key: "actions",
          render: (d, record) => (
            <div
              style={{ float: "right" }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Space.Compact>
                <Button size="middle" type="default" className="green-btn">
                  <Link to={"/exercise-plan?patient=" + record.id}>
                    {t("plan.createNewPlan")}
                  </Link>
                </Button>
                <Button
                  size="middle"
                  type="primary"
                  onClick={() => openPatient(record.id)}
                >
                  {t("view")}
                </Button>
                <Dropdown
                  // style={{ float: "right" }}
                  // size="large"

                  placement="bottomRight"
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: t("edit"),
                        onClick: e => {
                          openEditModal(record.id);
                        }
                      },

                      {
                        key: "2",
                        label: t("patient.transferToAnotherClinic"),
                        onClick: e => {
                          setPatientToTransfer({
                            patientId: record.id,
                            memberId: record.member_id
                          });
                        },
                        disabled: user?.clinics.length === 1
                      },
                      {
                        key: "3",
                        label: (
                          <ConfirmButton
                            btnText={t("delete")}
                            message={t("confirmations.deletePatient")}
                            noBtn={true}
                            btnProps={{
                              type: "text",
                              size: "small",
                              danger: true
                            }}
                            onConfirm={() => {
                              deletePatient(record.id);
                            }}
                          />
                        ),

                        danger: true
                      }
                    ]
                  }}
                >
                  <Button
                    size="middle"
                    type="primary"
                    onClick={e => e.stopPropagation()}
                  >
                    ...
                  </Button>
                </Dropdown>
              </Space.Compact>
            </div>
          ),
          responsive: ["lg"]
        }
      ]
    : [];

  return (
    <>
      <Column size="standard" style={{ marginTop: -50 }} key="col">
        <MyPageHeader
          key="ph"
          title={t("menu.myPatients")}
          extra={[
            <Space.Compact key="btns">
              <Button
                key="anp"
                className="phb"
                onClick={() => {
                  setPatientUpsertData(newPatientUpsert);
                  setShowPatientModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />

                {t("patient.addNewPatient")}
              </Button>

              <Button key="cnp" className="phb1">
                <Link to="/exercise-plan?id=0&mode=plan">
                  <FontAwesomeIcon icon={faRectangleList} />

                  {t("plan.createNewPlan")}
                </Link>
              </Button>
              {user?.clinic.integration_enabled === "cliniko" && (
                <Button
                  key="cinp"
                  className="phb1"
                  loading={loadingImportNew}
                  onClick={_ => {
                    setLoadingImportNew(true);
                    get("integrations/importNewPatients")
                      .then(r => {
                        getTabelData();
                      })
                      .catch(_ => {})
                      .finally(() => {
                        setLoadingImportNew(false);
                      });
                  }}
                >
                  <FontAwesomeIcon icon={faCircleDown} />

                  {t("integrations.importNewPatients", {
                    integration: "Cliniko"
                  })}
                </Button>
              )}
              {user?.clinic.integration_enabled === "nookal" && (
                <Button
                  key="cinp2"
                  className="phb1"
                  loading={loadingImportNew}
                  onClick={_ => {
                    setLoadingImportNew(true);
                    get("integrations/importNewPatients")
                      .then(r => {
                        getTabelData();
                      })
                      .catch(_ => {})
                      .finally(() => {
                        setLoadingImportNew(false);
                      });
                  }}
                >
                  <FontAwesomeIcon icon={faCircleDown} />

                  {t("integrations.importNewPatients", {
                    integration: "Nookal"
                  })}
                </Button>
              )}
            </Space.Compact>
          ]}
          mobileRight={
            <Button className={showFilterOnMobile ? "" : "top-btn-ghost"}>
              <FontAwesomeIcon
                // style={{ padding: 16 }}
                icon={faFilter}
                onClick={() => setShowFilterOnMobile(f => !f)}
              />
            </Button>
          }
        />
        {invalidApiKeyMsg && (
          <Alert
            type="error"
            // message="Invalid API key"
            description={
              <>
                {user?.clinic.integration_enabled === "cliniko" && (
                  <>
                    {t("cliniko.badApiKey")}{" "}
                    <a
                      href="https://youtu.be/3eUL6r96_6M"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://youtu.be/3eUL6r96_6M
                    </a>
                  </>
                )}{" "}
                {user?.clinic.integration_enabled === "nookal" && (
                  <>{t("nookal.badApiKey")} </>
                )}
              </>
            }
            closable
            style={{ marginBottom: 16 }}
          />
        )}
        {user && tableProps && (
          <>
            <div className="box">
              {patients === undefined && <Skeleton loading />}
              {patients !== undefined && (
                <>
                  <Space.Compact
                    size="large"
                    block
                    direction={isMobile ? "vertical" : "horizontal"}
                    style={{ marginBottom: 8 }}
                    // hidden={noData}
                  >
                    <Search
                      key="search"
                      // className="table-search vertical-margin"
                      // style={{ width: "50% " }}
                      allowClear
                      placeholder={t("search")}
                      onChange={debounce(e => {
                        // console.log(e);
                        if (tableProps !== undefined) {
                          setPagination({
                            ...pagination,
                            current: 1,
                            className:
                              e.target.value.length > 0
                                ? "hide-pagination-numbers"
                                : ""
                          });

                          setTableProps({
                            ...tableProps,
                            search: e.target.value,
                            page: 1
                          });
                        }
                      }, 300)}
                      enterButton={<FontAwesomeIcon icon={faSearch} />}
                      loading={loading && tableProps.search.length > 0}
                    />
                    {user.clinics.length > 1 &&
                      // tableProps.allClinics === 0 &&
                      isTabletOrMobile && (
                        <ChooseClinicSelect
                          style={{ width: "100%", marginBottom: 8 }}
                        />
                      )}
                    {showClinicsFilter && showFilterOnMobile && (
                      <Select
                        key="all_c"
                        value={tableProps.allClinics}
                        style={{ width: "100% " }}
                        // showSearch
                        optionFilterProp="children"
                        filterOption={true}
                        onChange={e => {
                          setPagination({ ...pagination, current: 1 });
                          setTableProps({
                            ...tableProps,
                            allClinics: e,
                            page: 1
                          });
                        }}
                      >
                        <Option value={1}>{t("allClinics")}</Option>
                        <Option value={0}>{t("currentClinic")}</Option>
                      </Select>
                    )}

                    {showPractitionerFilter && showFilterOnMobile && (
                      <Select
                        key="pract"
                        allowClear
                        value={tableProps.practitionerId}
                        showSearch={
                          Object.entries(user.practitioners).length > 15
                        }
                        optionFilterProp="children"
                        filterOption={true}
                        style={{ width: "100% " }}
                        onChange={e => {
                          setPagination({ ...pagination, current: 1 });
                          setTableProps({
                            ...tableProps,
                            practitionerId: e,
                            page: 1
                          });
                        }}
                        onClear={() => {
                          // e.preventDefault();
                          setPagination({ ...pagination, current: 1 });

                          return setTableProps({
                            ...tableProps,
                            practitionerId: 0,
                            page: 1
                          });
                        }}
                      >
                        <Option value={0}>{t("allPractitioners")}</Option>
                        {Object.entries(user.practitioners)
                          .sort(([k1, a], [k2, b]) =>
                            a.first_name.localeCompare(b.first_name)
                          )
                          .map(([key, p]) => (
                            <Option key={p.id.toString()} value={p.id}>
                              {p.first_name + " " + p.last_name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Space.Compact>

                  {/* </Input.Group> */}
                  {!noData && !isTabletOrMobile && (
                    <Table
                      className="table-striped-rows"
                      locale={{
                        emptyText: <> </>
                      }}
                      showHeader={!isTabletOrMobile}
                      columns={columns}
                      dataSource={patients}
                      rowKey="id"
                      pagination={pagination}
                      loading={loading}
                      onChange={handleTableChange}
                      style={{ width: "100%" }}
                      onRow={record => {
                        return {
                          onClick: event => {
                            openPatient(record.id);
                          },
                          onDoubleClick: event => {}, // double click row
                          onContextMenu: event => {}, // right button click row
                          onMouseEnter: event => {}, // mouse enter row
                          onMouseLeave: event => {} // mouse leave row
                        };
                      }}
                    />
                  )}
                  {isTabletOrMobile && (
                    <List>
                      <InfiniteScroll
                        dataLength={patients.length}
                        next={() => {
                          // console.log("loading next", patients);
                          setTableProps({
                            ...tableProps,
                            page: tableProps.page + 1
                          });
                        }}
                        hasMore={hasMore}
                        loader={<DotLoading />}
                        endMessage={noData ? <></> : <Divider />}
                        // scrollableTarget="scrollableDiv"
                      >
                        {patients.map(p => (
                          <SwipeAction
                            // ref={ref}
                            key={p.id}
                            closeOnAction={true}
                            closeOnTouchOutside={false}
                            rightActions={[
                              {
                                key: "delete",
                                text: t("delete"),
                                color: "danger",
                                onClick: e => {
                                  Dialog.confirm({
                                    content: t("confirmations.deletePatient"),
                                    confirmText: t("delete"),
                                    cancelText: t("cancel"),
                                    onConfirm: () => {
                                      deletePatient(p.id);
                                    }
                                  });
                                }
                              },
                              {
                                key: "edit",
                                // color: "@primary-color",

                                // className: "testbtn",
                                text: t("edit"),
                                onClick: e => {
                                  openEditModal(p.id);
                                }
                              }
                            ]}
                          >
                            <List.Item
                              key={p.id}
                              clickable
                              onClick={() => {
                                openPatient(p.id);
                              }}
                              arrow={
                                <>
                                  {p.plans_count}{" "}
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </>
                              }
                              prefix={
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className="prefix-icon"
                                />
                              }
                              description={formatDate(
                                p.last_activity,
                                language
                              )}
                            >
                              {p.first_name} {p.last_name}
                            </List.Item>
                          </SwipeAction>
                        ))}
                      </InfiniteScroll>
                    </List>
                  )}
                  {renderNoDataMessage()}
                </>
              )}
            </div>
          </>
        )}
      </Column>
      {/* <FloatButton onClick={() => console.log("click")} /> */}
      <UpsertPatientModal
        initialData={patientUpsertData}
        showModal={showPatientModal}
        onDone={id => {
          setShowPatientModal(false);
          getTabelData();
          openPatient(id);
        }}
        onDoneAndCNP={id => {
          setShowPatientModal(false);
          navigate("/exercise-plan?patient=" + id);
        }}
        onCancel={() => {
          setShowPatientModal(false);
        }}
      />
      <TransferPatientModal
        patient={patientToTransfer}
        showModal={patientToTransfer !== undefined}
        onCancel={() => {
          setPatientToTransfer(undefined);
        }}
        onDone={() => {
          getTabelData();
          setPatientToTransfer(undefined);
        }}
      />
    </>
  );
};

export default MyPatients;
