import { Button, Form, Input, Select, Space } from "antd";
import { TextAreaRef } from "antd/lib/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "../../api/Request";
import { useAuth } from "../../context/AuthContext";

interface Props {
  type:
    | "letterhead"
    | "sms"
    | "custom_outgoing_msg"
    | "tr_invite_msg"
    | "clinical_note_msg";
  onChange: (newValue: string) => void;
  rows?: number;
}
const TextTemplateEdit: React.FC<Props> = ({ type, onChange, rows }) => {
  const { user } = useAuth();

  const [template, setTemplate] = useState("");
  const textarea = useRef<TextAreaRef>(null);
  const { t } = useTranslation();

  const [fields, setFields] = useState([
    "SALUTATION",
    "PATIENT_TITLE",
    "PATIENT_FIRST_NAME",
    "PATIENT_LAST_NAME",
    "PRACTITIONER_NAME",
    "PATIENT_LOGIN_DETAILS",
    "PRACTITIONER_FIRST_NAME",
    "PRACTITIONER_LAST_NAME",
    "PRACTITIONER_EMAIL",
    "CLINIC_NAME",
    "CLINIC_ADDRESS",
    "CLINIC_EMAIL",
    "WEBSITE",
    "PHONE",
    "MOBILE",
    "FAX",
    "LOGO"
  ]);
  useEffect(() => {
    if (user) {
      if (type === "letterhead") setTemplate(user.settings.exercise_notes);
      if (type === "sms") setTemplate(user.settings.sms_msg);
      if (type === "custom_outgoing_msg")
        setTemplate(user.settings.custom_outgoing_msg);
      if (type === "tr_invite_msg")
        setTemplate(user.settings.meeting_invite_msg);
      if (type === "clinical_note_msg")
        setTemplate(user.settings.clinical_note_msg);
    }
  }, [user, type]);

  useEffect(() => {
    onChange(template);
  }, [template]);

  useEffect(() => {
    if (type === "tr_invite_msg") {
      // add ["MEETING_START_TIME", "MEETING_LINK"] to fields
      if(!fields.includes("MEETING_START_TIME") && !fields.includes("MEETING_LINK"))
      setFields([...fields, "MEETING_START_TIME", "MEETING_LINK"]);




    }
  }, [type, fields]);

  return (
    <div style={{ marginBottom: 16 }}>
      <Form.Item
        // name="exercise_notes"
        shouldUpdate
        style={{ marginBottom: 0 }}
      >
        {template && (
          <Input.TextArea
            value={template}
            onChange={e => setTemplate(e.target.value)}
            maxLength={4500}
            autoSize={{ minRows: 3, maxRows: 25 }}
            rows={rows ?? 10}
            ref={textarea}
          />
        )}
      </Form.Item>
      <Space.Compact block size="middle" style={{ marginTop: 8 }}>
        <Select
          placeholder={t("settings.insertField")}
          style={{ width: "100%" }}
          onChange={s => {
            const position =
              textarea.current?.resizableTextArea?.textArea.selectionStart ?? 0;

            var output = [
              template.slice(0, position),
              s,
              template.slice(position)
            ].join("");

            setTemplate(output);
            // textarea.current?.resizableTextArea.;
          }}
          options={fields.map(f => {
            return { label: f, value: " [!" + f + "!] " };
          })}
        />
        <Button
          block
          loading={false}
          onClick={() => {
            get("account/getTemplate", { type: type }).then(r => {
              setTemplate(r.data);
            });
          }}
        >
          {t("settings.resetToDefault")}
        </Button>
      </Space.Compact>
    </div>
  );
};

export default TextTemplateEdit;
