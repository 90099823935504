import { Col, ColProps, Row } from "antd";
import React from "react";
interface Props extends ColProps {
  children: React.ReactNode;
  hidden?: boolean;
  before?: React.ReactNode;
  size: "small" | "medium" | "standard";
}

const Column: React.FC<Props> = ({ children, hidden, size, ...rest }) => {
  let colProps: ColProps = {};
  if (size === "medium")
    colProps = {
      xxl: { span: 8 },
      xl: { span: 12 },
      lg: { span: 24 },
      md: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 }
    };
  else if (size === "small")
    colProps = {
      xxl: { span: 10 },
      xl: { span: 12 },
      lg: { span: 12 },
      md: { span: 16 },
      sm: { span: 24 },
      xs: { span: 24 }
    };
  else
    colProps = {
      xxl: { span: 24 },
      xl: { span: 24 },
      lg: { span: 24 },
      md: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 }
    };
  return (
    <Row justify="center">
      <Col {...colProps}>{children}</Col>
    </Row>
  );
};

export default Column;
