import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { post } from "../api/Request";
import MyPageHeader from "../components/layout/MyPageHeader";
import { useAuth } from "../context/AuthContext";
import TextArea from "antd/lib/input/TextArea";
import ColHalf from "../components/layout/ColHalf";
interface Props {
  onlyForm: boolean;
}
const Contact: React.FC<Props> = ({ onlyForm }) => {
  // console.log("tr load");
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const onFinish = () => {
    setLoading(true);
    post("account/contact", form.getFieldsValue())
      .then(r => {
        // if (r.data.user) setUser(r.data.user);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  return (
    <>
      {onlyForm === false && (
        <MyPageHeader
          key="ph"
          // onBack={() => null}

          title={t("menu.contact")}
          // subTitle="This is a subtitle"
        />
      )}

      <div
        className={onlyForm ? "" : "box"}
        style={{ maxWidth: 600, margin: "0 auto" }}
      >
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{
            name:
              user?.practitioner.first_name +
              " " +
              user?.practitioner.last_name,
            email: user?.practitioner.email,
            first_name: user?.practitioner.first_name
          }}
          onFinish={onFinish}
          autoComplete="on"
        >
          <Row gutter={4}>
            <Col flex="1">
              {" "}
              <Form.Item
                label={t("contact.name")}
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col flex="1">
              {" "}
              <Form.Item
                label={t("contact.email")}
                name="email"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label={t("contact.subject")} name="subject">
            <Input />
          </Form.Item>
          <Form.Item
            label={t("contact.message")}
            name="message"
            rules={[{ required: true }]}
          >
            <TextArea autoSize={{ minRows: 4, maxRows: 25 }} />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" block>
              {t("contact.send")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Contact;
