import React, { useEffect, useState } from "react";
import { Form, Button, Select, Radio, Tooltip, Alert, Space } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { useGlobalContext } from "../../context/GlobalContext";
import { ApiResWithUser, ReplyOptions } from "../../types/General";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { CreditOptions } from "../../types/Payment";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import StripeSinglePayment from "./StripeSinglePayment";
import { config } from "../../data/Constants";
import MyDivider from "../../components/helper/MyDivider";

const { Option } = Select;
interface AuthFormDTO {
  email: string;
  password: string;
}
const BuyCredit: React.FC = () => {
  const navigate = useNavigate();

  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const { isTabletOrMobile } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState<CreditOptions>();
  const [amount, setAmount] = useState<number>(50);

  const getData = () => {
    get<CreditOptions>("payment/creditOtions")
      .then(r => {
        // console.log(r.data, "p data");

        setPaymentOptions(r.data);
        // setPagination({ ...pagination, total: r.data.total_items });
      })
      .catch(_ => {})
      .finally(() => {});
  };

  useEffect(() => {
    getData();
  }, []);

  const onFinish = (values: AuthFormDTO) => {
    setLoading(true);
    post<ApiResWithUser>("upsertSettings", values)
      .then(r => {
        if (r.data.user) setUser(r.data.user);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  const paypalOptions = {
    clientId: config.PP_CLIENT_ID,
    currency: paymentOptions?.currency,
    intent: "capture"
    // "data-client-token": "abc123xyz"
  };

  return (
    <>
      <MyDivider title={t("account.whatsappSmsCredit")} />
      <Form.Item
      // initialValue={amount}
      >
        <Radio.Group onChange={e => setAmount(e.target.value)} value={amount}>
          <Space direction="vertical">
            {paymentOptions?.credits.map((c, i) => (
              <Radio value={c.amount} key={c.amount}>
                {paymentOptions.currency_sign}
                {c.amount} ({c.credits} {t("payments.credits")})
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>

      {paymentOptions && (
        <>
          <PayPalScriptProvider options={paypalOptions}>
            <PayPalButtons
              forceReRender={[amount, paymentOptions]}
              createOrder={(data, actions) => {
                return actions.order.create({
                  intent: "CAPTURE",
                  purchase_units: [
                    {
                      amount: {
                        value: amount.toString(),
                        currency_code: paymentOptions.currency
                      },
                      description: "RMP CREDIT PAYMENT",
                      custom_id: user?.id.toString()
                      // items: [
                      //   {
                      //     name: "RMP credits payment",
                      //     description:
                      //       "Credit for sending SMS and Whatsapp messages",
                      //     quantity: "1",
                      //     unit_amount: {
                      //       value: amount.toString()
                      //     }
                      //   }
                      // ]
                    }
                  ]
                });
              }}
              onApprove={function (data, actions) {
                return actions!.order!.capture().then(function (details) {
                  // Your code here after capture the order
                  // console.log(details);
                  const name = details ? details.payer?.name?.given_name : "";
                  // alert(`Transaction c ompleted by ${name}`);
                });
              }}

              // onApprove={(data, actions) => {
              //   if (actions && actions.order)
              //     return actions.order.capture().then(details => {
              //       const name = details ? details.payer?.name?.given_name : "";
              //       alert(`Transaction completed by ${name}`);
              //     });
              // }}
            />
          </PayPalScriptProvider>
          {/* <StripeSinglePayment
            amount={amount}
            currency={paymentOptions.currency}
          /> */}
        </>
      )}
    </>
  );
};

export default BuyCredit;
