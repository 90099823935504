import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import Column from "../../components/layout/Col";
import MyPageHeader from "../../components/layout/MyPageHeader";
import PractitionerPlanChart from "./PractitionerPlanChart";
import PractitionerTotalStats from "../settings/PractitionerTotalStats";
import { Select } from "antd";
import { get } from "../../api/Request";
import TopPrescribersMemo from "../settings/TopPrescribers";
import MyDivider from "../../components/helper/MyDivider";
import { PractitionerList, UserRole } from "../../types/Practitioner";
import AccountTotalStats from "../settings/AccountTotalStats";
import PractitionerLevelDisplay from "./PractitionerLevelDisplay";
const { Option } = Select;

const Stats: React.FC = () => {
  // console.log("load component templates");

  const { user } = useAuth();
  const { t } = useTranslation();
  const [practitioners, setPractitioners] = useState<PractitionerList[]>([]);
  useEffect(() => {
    getPractitioners();
  }, [user]);

  const getPractitioners = () => {
    get<PractitionerList[]>("practitioner/getAll/")
      .then(r => {
        // console.log(r.data, "practitioners");
        setPractitioners(r.data);
      })
      .catch(_ => {})
      .finally(() => {});
  };
  const [practitionerId, setPractitionerId] = useState<number | undefined>(
    user?.practitioner.role === UserRole.admin ||
      user?.practitioner.role === UserRole.owner
      ? 0
      : user?.practitioner.id
  );
  return (
    <>
      <Column size="standard" style={{ marginTop: -50 }} key="col">
        <MyPageHeader
          key="ph"
          // onBack={() => null}

          title={t("menu.stats")}
          // subTitle="This is a subtitle"
          extra={[]}
        />
        {user?.practitioner.id !== undefined && (
          <div className="box" style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 32 }}>
              <PractitionerLevelDisplay practitionerId={user.practitioner.id} />
            </div>
          </div>
        )}
        <div className="box" style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 32 }}>
            <AccountTotalStats />
          </div>
        </div>

        <div className="box">
          <div style={{ marginBottom: 32 }}>
            <TopPrescribersMemo />
          </div>
        </div>
        <h2 style={{ marginTop: 16 }}>{t("pract.stats.ppandstats")}</h2>
        {user && Object.entries(practitioners).length > 0 && (
          <Select
            key="pract"
            value={practitionerId}
            showSearch={Object.entries(practitioners).length > 15}
            optionFilterProp="children"
            filterOption={true}
            style={{ width: "100%" }}
            disabled={user.practitioner.role === UserRole.practitioner}
            onChange={e => {
              setPractitionerId(e);
            }}
            onClear={() => {
              // e.preventDefault();
              setPractitionerId(user.practitioner.id);
            }}
          >
            <Option value={0}>{t("allPractitioners")}</Option>
            {Object.entries(practitioners)
              .filter(([key, p]) => !p.archived) // Filter out archived practitioners
              .sort(([k1, a], [k2, b]) =>
                a.first_name.localeCompare(b.first_name)
              )
              .map(([key, p]) => (
                <Option key={p.id.toString()} value={p.id}>
                  {p.first_name + " " + p.last_name}
                </Option>
              ))}
          </Select>
        )}
        <div className="box" style={{ marginTop: 16 }}>
          {practitionerId !== undefined && (
            <>
              {/* <MyDivider title={t("account.planChart")} /> */}

              <PractitionerPlanChart practitionerId={practitionerId} />
              {practitionerId !== 0 && practitionerId !== undefined && (
                <>
                  {/* <div className="box" style={{ marginTop: 16 }}> */}
                  <MyDivider title={t("pract.stats.totalStats")} />
                  <PractitionerTotalStats practitionerId={practitionerId} />
                  {/* </div> */}
                </>
              )}
            </>
          )}
        </div>
      </Column>
    </>
  );
};

function areEqual(prevProps, nextProps) {
  // console.log("prev, next preview", prevProps, nextProps);

  return true;
  // return (
  //   prevProps.key === nextProps.key &&
  //   prevProps.imageType === nextProps.imageType &&
  //   prevProps.added === nextProps.added
  // );
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

const TemplatesMemo = React.memo(Stats, areEqual);
export default TemplatesMemo;
// export default React.memo(Templates, areEqual);
// export default Templates;
