import React from "react";
import { UserObject } from "../types/UserData";
import { useLocation } from "react-router-dom";
import { MessageStatus, Patient2 } from "../types/Patient";

interface PlanEditLink {
  id: number;
  patient_id: number;
  lang: string;
}
export const getPractitionerName = (id?: number, user?: UserObject | null) => {
  if (user === null) return "";
  if (id && user)
    return user.practitioners[id]
      ? user.practitioners[id].first_name +
          " " +
          user.practitioners[id].last_name
      : "";
  return "";
};

export const getMessageStatusColor = (status: MessageStatus) => {
  // console.log(status, "status");
  if (
    [
      MessageStatus.MESSAGE_STATUS_DELIVERED,
      MessageStatus.MESSAGE_STATUS_OPENED
    ].includes(status)
  )
    return "green";
  if (
    [
      MessageStatus.MESSAGE_STATUS_BOUNCED,
      MessageStatus.MESSAGE_STATUS_COMPLAINT,
      MessageStatus.MESSAGE_STATUS_REJECTED
    ].includes(status)
  )
    return "red";
  return "grey";
};

export const useUrlQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const getPlanEditLink = (plan: PlanEditLink) => {
  return (
    "/exercise-plan?id=" +
    plan.id +
    "&mode=plan&patient=" +
    plan.patient_id +
    "&lang=" +
    plan.lang
  );
};

export const getPatientSalutation = (patient: Patient2) => {
  const name = patient.first_name + " " + patient.last_name;
  return patient.title !== "" ? patient.title + " " + name : name;
};

export const downloadFile = filePath => {
  var link = document.createElement("a");
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
  link.click();
};

export const parseGaPathname = pathname => {
  const pathSegments = pathname.split("/");

  if (pathSegments.length === 2 && pathSegments[1] === "exercise-plan") {
    return `/plan_upsert/`;
  }
  if (pathSegments.length === 3 && pathSegments[1] === "patient") {
    return `/patient/view_profile/`;
  }
  if (pathSegments.length === 4 && pathSegments[2] === "plan") {
    return `/patient/plan_view/`;
  }
  if (pathSegments.length === 3 && pathSegments[1] === "template") {
    return `/template/view/`;
  }

  return pathname;
};

export const getChartColor = (value, inverted) => {
  let v = parseInt(value);
  if (inverted === 1) {
    v = 10 - v;
  }
  let color = "#017ac4";
  let face = '<i class="fas fa-smile"></i>';
  if (v === 0) {
    color = "#57bb8a";
    face = '<i class="fas fa-smile"></i>';
  } else if (v === 1) {
    color = "#73b87e";
    face = '<i class="fas fa-smile"></i>';
  } else if (v === 2) {
    color = "#94bd77";
    face = '<i class="fas fa-meh"></i>';
  } else if (v === 3) {
    color = "#b0be6e";
    face = '<i class="fas fa-meh"></i>';
  } else if (v === 4) {
    color = "#d4c86a";
    face = '<i class="fas fa-meh"></i>';
  } else if (v === 5) {
    color = "#f5ce62";
    face = '<i class="fas fa-meh"></i>';
  } else if (v === 6) {
    color = "#e9b861";
    face = '<i class="fas fa-meh"></i>';
  } else if (v === 7) {
    color = "#ecac67";
    face = '<i class="fas fa-frown"></i>';
  } else if (v === 8) {
    color = "#e79a69";
    face = '<i class="fas fa-frown"></i>';
  } else if (v === 9) {
    color = "#e2886c";
    face = '<i class="fas fa-frown"></i>';
  } else if (v === 10) {
    color = "#dd776e";
    face = '<i class="fas fa-frown"></i>';
  }

  return { color: color, face: face };
};
