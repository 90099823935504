import React from "react";
import { useAuth } from "../../context/AuthContext";
import { getPractitionerName } from "../../helpers/helper";

interface Props {
  id?: number;
}
const Pract: React.FC<Props> = ({ id }) => {
  const { user } = useAuth();

  if (id !== undefined) {
    const name = getPractitionerName(id, user);
    if (name !== "")
      return <div className="pract-table">{getPractitionerName(id, user)}</div>;
  }
  return <></>;
};

export default Pract;
