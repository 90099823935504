import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";
import AuthLayout from "./AuthLayout";
import MyPageHeader from "../../components/layout/MyPageHeader";
interface AuthFormDTO {
  email: string;
}
const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { Text } = Typography;
  const onFinish = (values: AuthFormDTO) => {
    setLoading(true);
    post("auth/resetPassword", {
      email: values.email
    })
      .then(_ => {})
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (user?.email) navigate("/patients", { replace: true });
  }, [user, navigate]);

  return (
    <AuthLayout
      title={<MyPageHeader title={t("auth.resetPassword")}></MyPageHeader>}
    >
      <div className="">
        <Text>{t("auth.resetPasswordText")}</Text>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="on"
        >
          <Form.Item
            label={t("email")}
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input required type="email" />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" block>
              {t("auth.resetPassword")}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              block
              onClick={() => navigate("/login/", { replace: true })}
            >
              {t("auth.backToLogin")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
