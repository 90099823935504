import React from "react";
import { Card, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";

const Integrations: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  // Define integrations data
  const integrations = [
    {
      name: "Cliniko",
      sysName: "cliniko",
      image: "cliniko.jpg",
      link: "/settings/integrations-cliniko"
    },
    {
      name: "Nookal",
      sysName: "nookal",
      image: "nookal.png",
      link: "/settings/integrations-nookal"
    },
    {
      name: "Plato Medical",
      sysName: "plato",
      image: "plato.png",
      link: "/settings/integrations-platomedical"
    },
    {
      name: "PPS",
      sysName: "pps",
      image: "pps.png",
      link: "/settings/integrations-pps"
    },
    {
      name: "Physiodiary",
      sysName: "Physiodiary",
      image: "physiodiary.jpg",
      link: "/settings/integrations-physiodiary"
    },
    {
      name: "SIF",
      sysName: "sif",
      image: "sif.png",
      link: "/settings/integrations-sif"
    },
    {
      name: "Zapier",
      sysName: "zapier",
      image: "zapier.png",
      link: "/settings/integrations-zapier"
    },
    {
      name: "My Osteopathy",
      sysName: "myosteopathy",
      image: "myosteopathy.png",
      link: "/settings/integrations-myosteopathy"
    }
  ];

  return (
    <Row gutter={[16, 16]}>
      {integrations.map(integration => (
        <Col key={integration.name} xs={24} sm={12} md={8} lg={6}>
          <Link to={integration.link}>
            <Card
              hoverable
              cover={
                <img
                  alt={integration.name}
                  src={
                    "https://www.rehabmypatient.com/assets/rmp/img/integrations/" +
                    integration.image
                  }
                  style={{
                    minHeight: 100,
                    maxHeight: 100,
                    objectFit: "contain"
                  }}
                />
              }
            >
              {user?.clinic.integration_enabled === integration.sysName ? (
                <Card.Meta title="Enabled" />
              ) : (
                <></>
              )}
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default Integrations;
