import React, { useState } from "react";
import { Form, Button, Upload, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

import { post } from "../../api/Request";
import { ApiResWithUser } from "../../types/General";
import MyDivider from "../../components/helper/MyDivider";
import { t } from "i18next";
import TextContent from "../TextContent";
import { config } from "../../data/Constants";

const ImportExport: React.FC = () => {
  const [form] = Form.useForm();
  const [file, setFile] = useState<any>();

  const dummyRequest = (file, onSuccess) => {
    if (file) {
      setFile(file);
    }
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // add test/import to the form

  const submit = () => {
    const formData = new FormData();

    if (file) formData.append("userfile", file);
    else return;
    // if (type === "test") formData.append("test", "1");
    // if (type === "import") formData.append("import", "1");
    formData.append("import", "1");

    post<ApiResWithUser>("account/importPatients/", formData, {
      headers: { "content-type": "multipart/form-data" }
    })
      .then(_ => {
        // message.success("Import successful");
        // setUser(response.data.user);
      })
      .catch(_ => {});
  };

  return (
    <>
      <MyDivider title={t("settings.importExport.importFromCsv")} />
      <TextContent id={534} />
      <Form form={form} layout="horizontal" autoComplete="on">
        <Form.Item
          // getValueFromEvent={normFile}
          name="userfile"
          valuePropName="userfile"
          noStyle
          rules={[
            {
              required: true
            }
          ]}
        >
          <Upload.Dragger
            accept=".csv"
            multiple={false}
            maxCount={1}
            listType="picture"
            customRequest={({ file, onSuccess }) =>
              dummyRequest(file, onSuccess)
            }
          >
            <p className="ant-upload-drag-icon">
              <FontAwesomeIcon icon={faFileExcel} size="4x" />{" "}
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint hidden">
              Support for a single or bulk upload.
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item name="import">
          <Button
            type="primary"
            block
            onClick={() => submit()}
            disabled={file === undefined}
            htmlType="submit"
            name="import"
            value={1}
          >
            {t("settings.importExport.importFromCsv")}
          </Button>
        </Form.Item>
      </Form>
      <MyDivider title={t("settings.importExport.export")} />
      <a type="primary" href={config.OLD_SITE_URL + "settings/export_patients"}>
        {t("settings.importExport.exportToCsv")}
      </a>
    </>
  );
};

export default ImportExport;
