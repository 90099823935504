import { Language, RepsFormat } from "./General";

export interface Practitioner {
  id: number;
  role: number;
  access_to_all_clinics: boolean;
  can_view_other_patients: boolean;
  share_templates: boolean;
  send_newsletter: boolean;
  timezone: string;
  first_name: string;
  last_name: string;
  email: string;
  mfa_enabled: boolean;
  lang: Language;
  require_password_change: boolean;
  banned: boolean;
  level: number;
}

export interface Clinic {
  id: number;
  clinic_name: string;
  lang: Language;
  address: string;
  address2: string;
  city: string;
  post_code: string;
  website: string;
  email: string;
  phone: string;
  mobile: string;
  fax: string;
  integration_enabled: string;
  integration_subdomain: string;
  integration_api_key: string;
  logo: string;
  logo_width: number;
  logo_position: "left" | "middle" | "right" | "hide";
}

export interface Clinic2 {
  id: number;
  clinic_name: string;
  // clinic_id: number;
  member_id: number;
}

export interface Settings {
  forward_plan_email_to_clinic: boolean;
  use_patient_email_cc_and_bcc_fields: boolean;
  plan_reply_to_recipient_clinic: string;
  exercise_notes: string;
  custom_outgoing_msg: string;
  clinical_note_msg: string;
  sms_msg: string;
  display_whatsapp_sms_button: number;
  default_reply_to_phone?: any;
  default_phone_dial_code: number;
  meeting_invite_msg: string;
  reply_to: string;
  forward_cc: string;
  forward_bcc: string;
  patient_consent_checked: boolean;
  email_sys: number;
  attach_diary_default: number;
  reps_format: RepsFormat;
  ex_desc_font_size: number;
  plan_reply_to_recipient: string;
  add_practitioner_on_plan: boolean;
  additional_notes_on_top: boolean;
  hide_videos: boolean;
  hide_date: boolean;
  integration_api_key: string;
  webhook_url?: string;
  disable_notes_on_cliniko?: boolean;
}

export interface Practitioner2 {
  id: number;
  email: string;
  role: number;
  first_name: string;
  last_name: string;
}

export enum Licence {
  notSet = 0,
  individual = 1,
  clinic = 2,
  multiClinic = 3
}
export enum EmailSys {
  smtp = 1,
  rmp = 2
}

export enum Status {
  STATUS_TRIAL_NOT_ACTIVATED = 0,
  STATUS_TRIAL = 1,
  STATUS_PAYPAL_REC = 2,
  STATUS_FREE = 3,
  STATUS_SMA_FREE = 5,
  STATUS_TIM_FRIEND_FREE = 6,
  STATUS_COMPETITION_WINNERS = 7,
  STATUS_BANK_MONTHLY = 8,
  STATUS_BANK_MONTHLY_EXPIRED = 9,
  STATUS_SINGLE_PAYMENT = 10,
  STATUS_STRIPE_REC = 21
}
export interface SysNotifications {
  notifications: SysNotification[];
}

export interface SysNotification {
  id: number;
  type: string;
  msg: string;
  public: number;
  practitioner: number;
  admin: number;
  owner: number;
  created: string;
}
export interface UserObject {
  id: number;
  email: string;
  lang: Language;
  sys_notifications: SysNotifications;
  banned: boolean;
  licence: Licence;
  status: Status;
  allowed_practitioners_num: number;
  credit_balance: number;
  clinics_allowed: number;
  enable_template_categories: boolean;
  expire_date: string;
  days_left: number;
  created: string;
  payment_required: boolean;
  should_pay: boolean;
  notify_when_credit_run_low: boolean;
  practitioner: Practitioner;
  clinic: Clinic;
  clinics: Clinic2[];
  current_clinics: Clinic2[];
  settings: Settings;
  practitioners: Practitioner2[];
  adminl: boolean;
  missing_translations: number;
}

export interface ApiKey {
  id: number;
  key: string;
  name: string;
  level: number;
  ignore_limits: number;
  date_created: number;
}

export interface SmtpSettings {
  host: string;
  port: string;
  email: string;
  password: string;
  ssl_tls: string;
  require_auth: string;
}
