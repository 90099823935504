import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { ApiResWithUser } from "../../types/General";
interface Props {
  save: number;
}
const ChangePassword: React.FC<Props> = ({ save }) => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = () => {
    setLoading(true);
    post<ApiResWithUser>("auth/changePassword", form.getFieldsValue())
      .then(r => {
        if (r.data.user) setUser(r.data.user);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // console.log(user);
  }, [user, navigate]);
  useEffect(() => {
    if (save > 0) onFinish();
  }, [save]);

  return (
    <Form
      name="basic"
      form={form}
      layout="vertical"
      initialValues={{
        email: user?.practitioner.email,
        first_name: user?.practitioner.first_name,
        last_name: user?.practitioner.last_name
      }}
      onFinish={onFinish}
      autoComplete="on"
    >
      <Form.Item
        label={t("auth.currentPassword")}
        name="current_password"
        rules={[{ required: true }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={t("auth.newPassword")}
        name="password"
        rules={[{ required: true }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={t("auth.repeatNewPassword")}
        name="password_c"
        rules={[{ required: true }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" block>
          {t("save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePassword;
