import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import MyModal from "../../components/MyModal";

interface Props {
  url: string;
  showModal: boolean;
  onClose: () => void;
}
const VideoModal: React.FC<Props> = ({ url, showModal, onClose }) => {
  const [visible, setVisible] = useState(showModal);
  useEffect(() => {
    setVisible(showModal);
  }, [showModal]);
  const { t } = useTranslation();
  return (
    <MyModal
      className="video-modal"
      centered
      closable={false}
      destroyOnClose
      open={visible}
      title={t("video")}
      onCancel={() => onClose()}
      okButtonProps={{ style: { display: "none" } }}
      cancelText={t("close")}
      footer={[
        <Button key="back" onClick={() => onClose()}>
          {t("close")}
        </Button>
      ]}
      width="940px"
    >
      <div className="player-wrapper">
        <ReactPlayer
          playing={visible}
          url={url}
          controls={true}
          className="react-player"
          width="100%"
          height="100%"
        />
      </div>
    </MyModal>
  );
};

export default React.memo(VideoModal);
