import React from "react";

const currencySymbols = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
  AUD: "A$",
  CAD: "C$",
  CHF: "CHF",
  CNY: "¥",
  SEK: "kr",
  NZD: "NZ$",
  INR: "₹",
  BRL: "R$",
  RUB: "₽",
  ZAR: "R",
  KRW: "₩",
  MXN: "$",
  IDR: "Rp",
  TRY: "₺",
  SAR: "﷼",
  SGD: "S$",
  MYR: "RM",
  PLN: "zł"
};

const getCurrencySymbol = currencyCode => {
  if (!currencyCode) return "";
  const upperCaseCode = currencyCode.toUpperCase();
  return currencySymbols[upperCaseCode] || upperCaseCode;
};

const CurrencyCode = ({ currencyCode }) => {
  const symbol = getCurrencySymbol(currencyCode);

  return <span>{symbol}</span>;
};

export default CurrencyCode;
