import { Result } from "antd";
import { Button } from "antd/es/radio";
import React, { Component, ErrorInfo } from "react";
import { post } from "../api/Request";
import Column from "./layout/Col";
import MyPageHeader from "./layout/MyPageHeader";
import { config } from "../data/Constants";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ErrorBoundary caught an error: ", error, errorInfo);
    post("account/errorLog", {
      name: error.message,
      message:
        "Href: " +
        window.location.href +
        " <br /> ------------------ <br /> " +
        error.toString() +
        " <br /> ------------------ <br /> " +
        errorInfo.componentStack
    })
      .then(r => {})
      .catch(_ => {})
      .finally(() => {});
    this.setState({ hasError: true });
  }

  clearCacheData = async () => {
    const cacheNames = await caches.keys();
    const deletePromises = cacheNames.map(cacheName =>
      caches.delete(cacheName)
    );
    await Promise.all(deletePromises);
    // alert("Complete Cache Cleared");
    window.location.replace(config.BASE_URL);
    // window.location.reload();
  };
  render() {
    if (this.state.hasError) {
      return (
        <Column size="standard" className="box">
          <MyPageHeader key="ph" title="Error" />
          <Result
            status="500"
            title="Oops! Something went wrong. Clear Cache and Reload Page."
            subTitle="If you're experiencing any issues with the website or if things seem a bit off, it might be time to clear your browser's cache. On Windows, press Ctrl + Shift + R. If you're on MacOS, use Cmd + Shift + R. This will reload the page and might solve the problem you're encountering. "
            extra={
              <Button type="primary" onClick={() => this.clearCacheData()}>
                {/* <Link to="/">Back Home</Link> */}
                Reload Page
              </Button>
            }
          />
        </Column>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
