import React from "react";
import { Link } from "react-router-dom";
import { Plan } from "../../types/Plan";
import { Typography, Col, Row, Image } from "antd";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import ValueLabel from "../../components/layout/ValueLabel";
import { getPractitionerName } from "../../helpers/helper";
import { Language } from "../../types/General";

interface Props {
  patientName: string;
  plan: Plan;
  lang: Language;
}
const PlanHeaderView: React.FC<Props> = ({ patientName, plan, lang }) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <>
      {user && (
        <>
          <Row
            align="middle"
            style={{ textAlign: "center", marginBottom: 24 }}
            className="hidden-sm"
          >
            <Col
              flex="2"
              hidden={user.clinic.logo_position === "hide"}
              order={
                user.clinic.logo_position === "middle"
                  ? 2
                  : user.clinic.logo_position === "right"
                  ? 4
                  : 0
              }
            >
              {user && user.clinic.logo !== "" ? (
                <div
                  style={{
                    margin: "0 auto",
                    textAlign: "center",
                    maxWidth: 400
                  }}
                >
                  <Image
                    style={{ textAlign: "center" }}
                    src={user.clinic.logo}
                    width={user.clinic.logo_width}
                    preview={false}
                  />
                </div>
              ) : (
                <img
                  // style={{ width: "300px", height: auto }}
                  src="/assets/rmp_white.png"
                  alt=""
                />
              )}
            </Col>
            <Col flex="2" order={1}>
              <h2>{user.clinic.clinic_name}</h2>
              <Text type="secondary">
                <div hidden={user.clinic.address.length === 0}>
                  {user.clinic.address}
                </div>
                <div hidden={user.clinic.address2.length === 0}>
                  {user.clinic.address2}
                </div>
                <div hidden={user.clinic.city.length === 0}>
                  {user.clinic.city}
                </div>
                <div hidden={user.clinic.post_code.length === 0}>
                  {user.clinic.post_code}
                </div>
              </Text>
            </Col>
            <Col flex="2" order={3}>
              {user.clinic.phone.length > 0 && (
                <>
                  <Text type="secondary">
                    {t("clinic.phone", { lng: lang })}:{" "}
                  </Text>
                  <Text type="secondary">{user.clinic.phone}</Text>
                  <br />
                </>
              )}
              {user.clinic.mobile.length > 0 && (
                <>
                  <Text type="secondary">
                    {t("clinic.mobile", { lng: lang })}:{" "}
                  </Text>
                  <Text type="secondary">{user.clinic.mobile}</Text>
                  <br />
                </>
              )}
              {user.clinic.fax.length > 0 && (
                <>
                  <Text type="secondary">
                    {t("clinic.fax", { lng: lang })}:{" "}
                  </Text>
                  <Text type="secondary">{user.clinic.fax}</Text>
                  <br />
                </>
              )}
              {user.clinic.website.length > 0 && (
                <>
                  <Text type="secondary">
                    {t("clinic.website", { lng: lang })}:{" "}
                  </Text>
                  <Text type="secondary">{user.clinic.website}</Text>
                  <br />
                </>
              )}
              {user.clinic.email.length > 0 && (
                <>
                  <Text type="secondary">{t("email", { lng: lang })}: </Text>
                  <Text type="secondary">{user.clinic.email}</Text>
                </>
              )}
            </Col>
          </Row>
          <Row
            justify="center"
            align="middle"
            className="grey-box"
            style={{ textAlign: "center" }}
          >
            <Col flex="1">
              <ValueLabel
                label={t("patient.title", { lng: lang })}
                noMargin={true}
              >
                <Link to={"/patient/" + plan.patient_id}> {patientName}</Link>
              </ValueLabel>
            </Col>
            <Col flex="1">
              <ValueLabel
                label={t("planUpsert.name", { lng: lang })}
                noMargin={true}
              >
                {plan.name}
              </ValueLabel>
            </Col>
            <Col flex="1">
              <ValueLabel
                label={t("practitioner", { lng: lang })}
                noMargin={true}
              >
                {getPractitionerName(plan.practitioner_id, user)}
              </ValueLabel>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default PlanHeaderView;
