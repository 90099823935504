import { Form, Button, Space, Dropdown, Result } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { get, post } from "../../api/Request";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

import MyModal from "../../components/MyModal";
import ReactQuill from "react-quill"; // Import ReactQuill
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor

import { ClinicalNoteTemplate } from "../../types/Patient";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { config } from "../../data/Constants";
import { formatDate, formatTime } from "../../helpers/dateHelper";
import CustomNotesEmailModal from "./CustomNotesEmailModal";

interface Props {
  id: number; // Note ID, if > 0 it's editing, otherwise it's adding a new note
  patientId: number; // The ID of the patient related to the note
  patientName: string; // The name of the patient
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

const NoteUpsertModal: React.FC<Props> = ({
  id,
  patientId,
  patientName,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [localId, setLocalId] = useState(0); // ID of the note
  const [contentChanged, setContentChanged] = useState(false);
  const [showCustomEmailModal, setShowCustomEmailModal] = useState(false);

  const [templates, setTemplates] = useState<ClinicalNoteTemplate[]>([]);
  const quillRef = useRef<ReactQuill | null>(null); // Reference to ReactQuill
  const [quillRange, setQuillRange] = useState<any>(null); // Store the cursor position

  const navigate = useNavigate();
  const getTemplates = () => {
    setLoading(true);
    get<ClinicalNoteTemplate[]>("note/getTemplates/")
      .then(r => {
        setTemplates(r.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!showModal) return;

    setLocalId(id);

    if (templates.length === 0) {
      getTemplates();
    }
    if (id > 0) {
      // Fetch existing note data if editing
      get<{ note: string }>(`/note/note/${id}`)
        .then(response => {
          form.resetFields();
          form.setFieldsValue({
            note: response.data.note
          });
        })
        .catch(() => {})
        .finally(() => setContentChanged(false));
    } else {
      // If adding a new note, reset form
      form.resetFields();
      setContentChanged(false);
    }
  }, [id, showModal]);

  const submit = () => {
    //
    form
      .validateFields()
      .then(v => {
        console.log(v, "v");
        const data = {
          note: v.note, // Use the current content of the editor
          patient_id: patientId,
          practitioner_id: user?.practitioner?.id
        };
        setLoading(true);

        const apiCall =
          localId > 0
            ? post(`/note/edit/${localId}`, data) // Edit existing note
            : post(`/note/add`, data); // Add new note

        apiCall
          .then(r => {
            setLocalId(r.data.id);
            setContentChanged(false);
            onDone();
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const insertHTML = (html: string) => {
    // Replace [!PATIENT_NAME!] with the patient's name
    html = html.replace("[!PATIENT_NAME!]", patientName);

    if (user) {
      html = html.replace(
        "[!DATE!]",
        formatDate("", user.practitioner["lang"])
      );
      html = html.replace("[!TIME!]", formatTime(""));
      html = html.replace(
        "[!PRACTITIONER_NAME!]",
        user.practitioner["first_name"] + " " + user.practitioner["last_name"]
      );
    }

    const editor = quillRef.current?.getEditor(); // Access the Quill editor instance

    if (editor) {
      // Check if the editor content is empty (only contains <p><br></p>)
      if (quillRange) {
        const { index, length } = quillRange; // Get the selection range
        if (length > 0) {
          // If there's a selection, remove the selected text
          editor.deleteText(index, length);
        }
      }

      if (editor.getText().trim() === "") {
        // If the editor is empty, insert the HTML at the beginning
        editor.clipboard.dangerouslyPasteHTML(0, html);
      } else {
        if (quillRange && quillRange.index !== null) {
          // If the cursor position is stored, insert HTML at that position
          editor.clipboard.dangerouslyPasteHTML(quillRange.index, html);
        } else {
          editor.clipboard.dangerouslyPasteHTML(
            editor.getLength(),
            "<br />" + html
          );
        }
      }
    }
  };

  const handleMenuClick = ({ key }: { key: string }) => {
    const template = templates.find(t => t.id.toString() === key);
    if (template) {
      insertHTML(template.template);
    }
  };

  const createMenuItems = (category: string) =>
    templates
      .filter(template => template.category === category)
      .map(template => ({
        label: template.name,
        key: template.id.toString()
      }));

  const rfMenuProps = {
    items: createMenuItems("rf"),
    onClick: handleMenuClick
  };

  const templateMenuProps = {
    items: createMenuItems("template"),
    onClick: handleMenuClick
  };

  const printDownload = action => {
    const data = {
      id: localId.toString(),
      action: action
    };
    window.open(
      config.API_URL +
        "note/printDownload?" +
        new URLSearchParams(data).toString(),
      "_blank"
    );
  };

  return (
    <MyModal
      title={localId > 0 ? t("cn.edit") : t("cn.add")}
      open={showModal}
      width={1000}
      onCancel={onCancel}
      okText={t("save")}
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelText={contentChanged ? t("cancel") : t("close")}
      onOk={() => {
        form
          .validateFields()
          .then(v => {
            console.log(v, "v");
            submit();
          })
          .catch(() => {});
      }}
    >
      <Space.Compact block style={{ marginBottom: 16 }}>
        <Button
          block
          key="save"
          type="primary"
          onClick={() => submit()}
          loading={loading}
        >
          {t("save")} {contentChanged && "*"}
        </Button>
        <Button
          disabled={localId === 0}
          block
          key="0"
          type="primary"
          onClick={() => setShowCustomEmailModal(true)}
        >
          <FontAwesomeIcon icon={faEnvelope} />
          {t("plan.email")}
        </Button>
        <Button
          disabled={localId === 0}
          block
          key="1"
          type="primary"
          onClick={() => printDownload("print")}
        >
          <FontAwesomeIcon icon={faPrint} />
          {t("print")}
        </Button>
        <Button
          disabled={localId === 0}
          block
          key="2"
          type="primary"
          onClick={() => printDownload("download")}
        >
          <FontAwesomeIcon icon={faDownload} />
          {t("download")}
        </Button>
      </Space.Compact>

      {!loading && templates.length === 0 && (
        <Result
          icon={<></>}
          title={t("template.nothingFound")}
          subTitle={t("cn.importTemplatesQ")}
          extra={[
            <Button
              type="primary"
              key="import"
              onClick={() => {
                post("note/importDefaultTemplates", {})
                  .then(() => {
                    getTemplates();
                  })
                  .catch(() => {});
              }}
            >
              {t("import")}
            </Button>
          ]}
        />
      )}
      <Space size={[4, 4]} wrap style={{ marginBottom: 8 }}>
        {templates
          .filter(template => template.category === "quick")
          .map(template => (
            <Button
              key={template.id}
              onClick={() => insertHTML(template.template)}
            >
              {template.name}
            </Button>
          ))}

        {rfMenuProps.items.length > 0 && (
          <Dropdown menu={rfMenuProps}>
            <Button type="primary">
              <Space>
                {t("cn.rf")}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
        {templateMenuProps.items.length > 0 && (
          <Dropdown menu={templateMenuProps}>
            <Button type="primary">
              <Space>
                {t("cn.tmplts")}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
        <Button
          type="link"
          size="small"
          onClick={_ => navigate("/settings/clinical-note-templates")}
        >
          {t("cn.editTemplates")}
        </Button>
      </Space>
      <Form form={form} layout="vertical" autoComplete="off">
        {/* ReactQuill text editor */}
        <Form.Item name="note" style={{ marginBottom: 16 }}>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            onKeyUp={() => {
              setContentChanged(true);
            }}
            onChangeSelection={e => {
              // console.log(e, "cs");
              if (e !== null) setQuillRange(e);
            }}
            style={{ height: "400px", marginBottom: 16 }}
          />
        </Form.Item>
      </Form>
      <CustomNotesEmailModal
        id={localId}
        showModal={showCustomEmailModal}
        onCancel={() => {
          setShowCustomEmailModal(false);
        }}
        onDone={() => {
          setShowCustomEmailModal(false);
        }}
      />
    </MyModal>
  );
};

export default NoteUpsertModal;
