import React, { useRef, useState } from "react";
import { Col, Row } from "antd";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

import { post } from "../../api/Request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import LanguageSelect from "../LanguageSelect";
import { ApiResWithUser } from "../../types/General";
import ChooseClinicSelect from "../helper/ChooseClinicSelect";
import LevelIcon from "../helper/LevelIcon";

const TopMenu: React.FC = () => {
  const { t } = useTranslation();
  const { user, setUser } = useAuth();
  const [langLoading, setLangLoading] = useState(false);
  const location = useLocation();

  const { pathname } = location;

  const [searchParams, _] = useSearchParams();

  const myPatientsLink = useRef<HTMLAnchorElement>(null);

  const handleLangChange = value => {
    // dont allow lang change on existing plan
    if (
      pathname === "/exercise-plan" &&
      parseInt(searchParams.get("id") ?? "0") > 0
    )
      return;

    if (user?.practitioner.lang !== value) {
      setLangLoading(true);
      post<ApiResWithUser>("practitioner/changeLang", {
        lang: value
      })
        .then(r => {
          // remove saved plans in cache to avoid conflicts
          localStorage.removeItem("plan");
          localStorage.removeItem("old_plan");
          if (r.data.user) setUser(r.data.user);
        })
        .catch(e => {})
        .finally(() => setLangLoading(false));
    }
  };

  return (
    <div className="top-menu">
      <div className="wrapper">
        {user && (
          <Row gutter={16}>
            <Col className="gutter-row" key="1">
              {t("hello")} {user.practitioner.first_name}
              {"! "}
              <LevelIcon level={user.practitioner.level} />
              {/* <LevelIcon level={1} />
              <LevelIcon level={2} />
              <LevelIcon level={3} />
              <LevelIcon level={4} />
              <LevelIcon level={5} />
              <LevelIcon level={6} />
              <LevelIcon level={7} />
              <LevelIcon level={8} />
              <LevelIcon level={9} /> 
              <LevelIcon level={10} />
              <LevelIcon level={11} />
              <LevelIcon level={12} /> */}
              {user.days_left > 0 &&
                t("account.daysLeft", { d: user.days_left })}
              {user.should_pay === true && (
                <Link to="/payment" key="payment" style={{ marginLeft: 32 }}>
                  <FontAwesomeIcon icon={faPaypal} />{" "}
                  {t("payment.startSubscription")}
                </Link>
              )}
            </Col>
            <Col flex="auto"></Col>
            <Col className="gutter-row" style={{ float: "right" }} key="3">
              <LanguageSelect
                disabled={
                  pathname === "/exercise-plan" &&
                  parseInt(searchParams.get("id") ?? "0") > 0
                }
                // disabled={true}
                loading={langLoading}
                size="small"
                className="t-select"
                onChange={handleLangChange}
                value={
                  user.practitioner.lang
                    ? user.practitioner.lang.toString()
                    : user.clinic.lang.toString()
                }
              />

              {user.clinics.length > 1 && (
                <ChooseClinicSelect
                  style={{ marginLeft: 8 }}
                  size="small"
                  optionFilterProp="children"
                  className="t-select"
                  filterOption={true}
                  key="1"
                />
              )}

              <Link to="/settings/my-account" ref={myPatientsLink} key="2">
                {t("settings.title")}
              </Link>
              <Link to="/logout" key="3">
                {t("logout")}
              </Link>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default TopMenu;
