import React from "react";
import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";

const Logout: React.FC = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  });
  return <></>;
};

export default Logout;
