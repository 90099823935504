import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { post } from "../../api/Request";
import { UserTemplateCat } from "../../types/Plan";
import MyModal from "../../components/MyModal";

interface Props {
  showModal: boolean;
  cats: UserTemplateCat[];
  onCancel: () => void;
  onDone: () => void;
}
const ManageTemplateCategories: React.FC<Props> = ({
  showModal,
  cats,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [deletedCats, setDeletedCats] = useState<number[]>([]);

  useEffect(() => {
    form.setFieldsValue({ cats: cats });
  }, [form, cats]);

  const submit = v => {
    setLoading(true);
    interface Res {
      successToast: string;
      credit_balance: number;
    }

    // return;

    post<Res>("template/saveUserCats", {
      ...v,
      toDelete: deletedCats
    })
      .then(r => {
        onDone();
      })
      .catch(_ => {});
    // let data = {
    //   dial_code: v.phone2.code,
    //   phone: v.phone2.phone,
    //   message: v.message,
    //   save_phone: v.save_phone ?? false,
    //   plan_id: templateId,
    //   patient_id: patientId
    // };
    // // return;
  };

  return (
    <MyModal
      title={t("template.manageCats")}
      open={showModal}
      onCancel={onCancel}
      okText={t("save")}
      okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            submit(values);
          })
          .catch(info => {
            // console.log("Validate Failed:", info);
          })
          .finally(() => {});
      }}
    >
      <Form
        form={form}
        name="dynamic_form_nest_item"
        // onFinish={onFinish}
        autoComplete="off"
        initialValues={{ cats: cats }}
        // initialValues={cats}
      >
        <Form.List name="cats">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space
                  key={index}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item hidden name={[field.name, "id"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name={[field.name, "member_id"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "name"]}
                    rules={[{ required: true, message: "Please enter name" }]}
                  >
                    <Input
                      placeholder="Category Name"
                      disabled={cats[index] && cats[index].id === 1}
                    />
                  </Form.Item>
                  {((cats[index] && cats[index].id !== 1) ||
                    cats[index] === undefined) && (
                    <MinusCircleOutlined
                      onClick={() => {
                        // console.log(cats[index], field, "index removed");
                        if (
                          cats[index] &&
                          !deletedCats.includes(cats[index].id)
                        )
                          setDeletedCats([...deletedCats, cats[index].id]);
                        remove(field.name);
                      }}
                    />
                  )}
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t("template.addCat")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </MyModal>
  );
};

export default ManageTemplateCategories;
