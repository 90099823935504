import { Col, Row, Tabs, Segmented } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { get } from "../../api/Request";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { useGlobalContext } from "../../context/GlobalContext";
import TemplateInfo from "./TemplateInfo";
import { PlanViewDTO } from "../../types/Plan";
import PlanExercisesView from "../plan/PlanExercisesView";
import { useAuth } from "../../context/AuthContext";
const { TabPane } = Tabs;

interface Props {}

const TemplateView: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAuth();
  const { isTabletOrMobile } = useGlobalContext();

  const [template, setTemplate] = useState<PlanViewDTO>();
  const [, setLoading] = useState(false);
  const [tab, setTab] = useState("0");

  const getTemplate = () => {
    setLoading(true);
    get<PlanViewDTO>("template/template/" + id)
      .then(r => {
        // console.log(r.data, "profile");
        setTemplate(r.data);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getTemplate();
  }, [id, user]);

  return (
    <>
      {template && (
        <>
          {/* <Col span={24}> */}
          <MyPageHeader
            key="1"
            title={template?.plan.name}
            subTitle={t("template.preview")}
          />
          {/* </Col> */}
          {!isTabletOrMobile ? (
            <Row
              key="2"
              className="wrapper123"
              gutter={!isTabletOrMobile ? [48, 16] : undefined}
            >
              <Col span={7}>
                <div className="box">
                  <TemplateInfo template={template} />
                </div>
              </Col>
              <Col span={!isTabletOrMobile ? 17 : 24} className="box">
                <PlanExercisesView
                  exercises={template.exercises}
                  notes={template.plan.notes}
                  imageType={template.plan.image_type}
                  lang={template.plan.lang}
                />
              </Col>
            </Row>
          ) : (
            <>
              {isTabletOrMobile && (
                <Segmented
                  options={[
                    {
                      label: t("info"),
                      value: "0"
                    },
                    {
                      label: t("preview"),
                      value: "1"
                    }
                  ]}
                  onChange={e => setTab(e.toString())}
                  block
                  defaultValue={tab}
                  size="large"
                />
              )}
              <div
                className="box"
                style={isTabletOrMobile ? { margin: 0 } : {}}
              >
                <Tabs
                  centered={true}
                  activeKey={tab}
                  className="patient-profile-tabs"
                >
                  <TabPane tab={t("info")} key="0">
                    <TemplateInfo template={template} />
                  </TabPane>
                  <TabPane tab={t("preview")} key="1">
                    <PlanExercisesView
                      exercises={template.exercises}
                      notes={template.plan.notes}
                      imageType={template.plan.image_type}
                      lang={template.plan.lang}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TemplateView;
