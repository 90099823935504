import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";

import {
  faStar,
  faCircle,
  faTrophy,
  faMedal,
  faBookmark,
  faBook
} from "@fortawesome/free-solid-svg-icons";
import PlanChartModal from "../../pages/account/PlanChartModal";

const LevelIcon = ({ level, bigIcon = false }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [showChart, setShowChart] = React.useState(false);

  let icon;
  let color;
  let text;
  let title;

  switch (level) {
    case 1:
      icon = faBookmark;
      color = "#b4b4b4"; // Dark Grey
      text = "Level 1";
      title = "For prescribing less than 5 plans";
      break;
    case 2:
      icon = faBookmark;
      color = "#b4b4b4"; // Grey
      text = "Level 2";
      title = t("pract.forPrescribingPlans", {
        x: "5"
      });
      break;
    case 3:
      icon = faBookmark;
      color = "#b4b4b4"; // Dim Grey
      text = "Level 3";
      title = t("pract.forPrescribingPlans", {
        x: "10"
      });
      break;
    case 4:
      icon = faMedal;
      color = "#9fdafa"; // Dark Olive Green
      text = "Level 4";
      title = t("pract.forPrescribingPlans", {
        x: "25"
      });
      break;
    case 5:
      icon = faMedal;
      color = "#9fdafa"; // Forest Green
      text = "Level 5";
      title = t("pract.forPrescribingPlans", {
        x: "50"
      });
      break;
    case 6:
      icon = faMedal;
      color = "#9fdafa"; // Lime Green
      text = "Level 6";
      title = t("pract.forPrescribingPlans", {
        x: "100"
      });
      break;
    case 7:
      icon = faTrophy;
      color = "#79ab60"; // Medium Sea Green
      text = "Level 7";
      title = t("pract.forPrescribingPlans", {
        x: "250"
      });
      break;
    case 8:
      icon = faTrophy;
      color = "#79ab60"; // Lime Green
      text = "Level 8";
      title = t("pract.forPrescribingPlans", {
        x: "500"
      });
      break;
    case 9:
      icon = faTrophy;
      color = "#79ab60"; // Lime
      text = "Level 9";
      title = t("pract.forPrescribingPlans", {
        x: "750"
      });
      break;
    case 10:
      icon = faStar;
      color = "#d4af37 "; // Goldenrod
      text = "Level 10";
      title = t("pract.forPrescribingPlans", {
        x: "1000"
      });
      break;
    case 11:
      icon = faStar;
      color = "#d4af37 "; // Gold
      text = "Level 11";
      title = t("pract.forPrescribingPlans", {
        x: "2500"
      });
      break;
    case 12:
      icon = faStar;
      color = "#d4af37 "; // Gold
      text = "Level 12";
      title = t("pract.forPrescribingPlans", {
        x: "5000"
      });
      break;
    default:
      icon = faCircle;
      color = "grey";
      text = "Unknown Level";
      title = "Unknown level description";
  }

  if (level === 0) {
    return <></>;
  } else {
    return (
      <>
        <span
          title={title}
          style={{
            margin: "0 8px",
            cursor: "pointer",
            fontSize: bigIcon ? "40px" : "13px"
          }}
          onClick={_ => setShowChart(true)}
        >
          <FontAwesomeIcon
            icon={icon}
            style={{ color }}
            onClick={_ => setShowChart(true)}
          />{" "}
          <span style={{ color }} onClick={_ => setShowChart(true)}>
            {text}
          </span>
        </span>
        {user && (
          <PlanChartModal
            practitionerId={user.practitioner.id}
            showModal={showChart}
            onCancel={_ => setShowChart(false)}
          />
        )}
      </>
    );
  }
};

export default LevelIcon;
