import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import MyModal from "../components/MyModal";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  pdf: string;
  title: string;
  visible: boolean;
  onCancel: () => void;
}
const PdfViewer: React.FC<Props> = ({ pdf, title, onCancel, visible }) => {
  const [numPages, setNumPages] = useState(null);
  const { t } = useTranslation();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // Create styles

  return (
    <MyModal
      title={title}
      open={visible}
      onCancel={onCancel}
      okText={t("close")}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      // maskClosable={false}
      width={"1150px"}
    >
      <Document
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map(page => (
            <Page key={page} pageNumber={page} scale={1.8} />
          ))}
      </Document>
    </MyModal>
  );
};

export default PdfViewer;
