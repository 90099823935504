import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Tooltip } from "antd";
import React from "react";

interface Props {
  title: string;
  tooltip?: string;
  className?: string;
}
const MyDivider: React.FC<Props> = ({ title, tooltip, className }) => {
  return (
    <Divider orientation="left" className={className}>
      {title}
      {tooltip && (
        <Tooltip title={tooltip}>
          <FontAwesomeIcon
            style={{ marginLeft: 10, marginTop: 5, cursor: "pointer" }}
            icon={faQuestionCircle}
          />
        </Tooltip>
      )}
    </Divider>
  );
};

export default MyDivider;
