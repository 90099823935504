import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";
import { get } from "../api/Request";
import { useGlobalContext } from "../context/GlobalContext";
import { Skeleton } from "antd";

interface Props {
  url: string;
}
const HtmlContent: React.FC<Props> = ({ url }) => {
  const { language } = useGlobalContext();
  const [loading, setLoading] = useState(true);

  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    if (htmlContent === "") {
      setLoading(true);
      get(url, {
        lang: language
      })
        .then(r => {
          setHtmlContent(r.data);
        })
        .catch(_ => {})
        .finally(() => setLoading(false));
    }
  }, [url, language]);

  return loading ? <Skeleton active /> : <div>{Parser(htmlContent)}</div>;
};

export default HtmlContent;
