import { Select, Form, Checkbox, Tooltip, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import React from "react";
import { useTranslation } from "react-i18next";
import { PlanLayout } from "../../types/Plan";
import "react-quill/dist/quill.snow.css";
import { config } from "../../data/Constants";
import MyModal from "../../components/MyModal";
import { useGlobalContext } from "../../context/GlobalContext";
interface Props {
  planId: number;
  token: string;
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

interface FormProps {
  plan_id: number;
  layout: PlanLayout;
  attach_diary: boolean;
  action: string;
}

const DownloadPlanModal: React.FC<Props> = ({
  planId,
  showModal,
  token,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const { isIos } = useGlobalContext();

  const { Option } = Select;
  const [form] = Form.useForm();

  const submit = (v: FormProps) => {
    let data = {
      attach_diary: v.attach_diary ? "1" : "0",
      layout: v.layout,
      plan_id: planId.toString(),
      action: v.action,
      token: token
    };
    window.open(
      config.API_URL +
        "plan/planPrintDownload?" +
        new URLSearchParams(data).toString(),
      "_blank"
    );
    return;
  };

  return (
    <MyModal
      title={t("plan.printDownload2")}
      open={showModal}
      okButtonProps={{ hidden: true }}
      onCancel={onCancel}
      cancelText={t("cancel")}
    >
      <Form
        form={form}
        id="pdform"
        // initialValues={initialData}
        layout="vertical"
        initialValues={{ layout: PlanLayout.default }}
      >
        <Form.Item
          name="layout"
          label={t("plan.pdfLayout")}
          rules={[{ required: true }]}
        >
          <Select style={{ width: "100%" }}>
            <Option value={PlanLayout.default}>
              {t("planLayout." + PlanLayout.default)}
            </Option>
            <Option value={PlanLayout.largeImage}>
              {t("planLayout." + PlanLayout.largeImage)}
            </Option>
            <Option value={PlanLayout.superLarge}>
              {t("planLayout." + PlanLayout.superLarge)}
            </Option>
            <Option value={PlanLayout.compressed}>
              {t("planLayout." + PlanLayout.compressed)}
            </Option>
            <Option value={PlanLayout.summary}>
              {t("planLayout." + PlanLayout.summary)}
            </Option>
            <Option value={PlanLayout.twoCol}>
              {t("planLayout." + PlanLayout.twoCol)}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item name="attach_diary" valuePropName="checked">
          <Checkbox>
            {t("form.attachDiary")}{" "}
            <Tooltip title={t("form.attachDiaryHint")}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Checkbox>
        </Form.Item>
        <Form.Item name="print">
          <Button
            type="primary"
            onClick={() => {
              submit({ ...form.getFieldsValue(), action: "download" });
            }}
          >
            {t("download")} {isIos ? "/" + t("print") : ""}
          </Button>{" "}
          <Button
            hidden={isIos}
            type="primary"
            onClick={() => {
              submit({
                ...form.getFieldsValue(),
                action: "print"
              });
            }}
          >
            {t("print")}
          </Button>
        </Form.Item>
      </Form>
    </MyModal>
  );
};

export default DownloadPlanModal;
