import React, { useState } from "react";
import { PlanViewDTO } from "../../types/Plan";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { Button, List, Result, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListOl } from "@fortawesome/free-solid-svg-icons";
import { getPatientSalutation } from "../../helpers/helper";
import ValueLabel from "../../components/layout/ValueLabel";
import Paragraph from "antd/lib/typography/Paragraph";
import { Patient2 } from "../../types/Patient";
import { formatDate } from "../../helpers/dateHelper";
import { useGlobalContext } from "../../context/GlobalContext";
import MyModal from "../../components/MyModal";
import HtmlContent from "../HtmlContent";

interface Props {
  plan: PlanViewDTO;
  patient: Patient2;
}
const PlanPromResult: React.FC<Props> = ({ plan, patient }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { language } = useGlobalContext();
  const [showResultModal, setShowResultModal] = useState(false);
  const [resultId, setResultId] = useState<number | null>(null);

  return (
    <>
      {user && (
        <div style={{ textAlign: "center" }}>
          {plan.prom.map(p => (
            <div key={p.prom_name}>
              <h2>{p.prom_name}</h2>
              <ValueLabel label={t("plan.schedule")}>
                {t("plan." + p.schedule)}
              </ValueLabel>
              {p.results && p.results.length > 0 && (
                <List
                  style={{ maxWidth: 400, margin: "auto" }}
                  className="margin-top-16"
                  header={<div>{t("plan.promResults")}</div>}
                  // footer={<div>Footer</div>}
                  bordered
                  dataSource={p.results}
                  renderItem={item => (
                    <List.Item
                      actions={[
                        <Button
                          onClick={_ => {
                            setResultId(item.id);
                            setShowResultModal(true);
                          }}
                        >
                          {t("view")}
                        </Button>
                      ]}
                    >
                      <Typography.Text>
                        {formatDate(item.created, language)}
                      </Typography.Text>
                    </List.Item>
                  )}
                />
              )}
              {p.results && p.results.length === 0 && (
                <Result
                  style={{
                    background: "#f0f0f0",
                    borderRadius: 8,
                    margin: "16px"
                  }}
                  icon={<FontAwesomeIcon icon={faListOl} size="4x" />}
                  title={t("plan.noPromRes")}
                  subTitle={t("plan.noPromRes2")}
                  extra={
                    <>
                      <h3>{getPatientSalutation(patient)}</h3>
                      <ValueLabel label={t("patient.trAccessCodeLabel")}>
                        <Paragraph
                          copyable={{
                            text:
                              "https://trackrehab.com/c/" + patient.access_code,
                            tooltips: [t("copy"), t("copied")]
                          }}
                        >
                          {patient.access_code}
                        </Paragraph>
                      </ValueLabel>
                    </>
                  }
                />
              )}
            </div>
          ))}
        </div>
      )}
      {showResultModal && resultId && (
        <MyModal
          title={t("plan.promResults")}
          // style={{ maxWidth: 350 }}
          open={showResultModal}
          forceRender
          // centered
          onOk={e => {
            setShowResultModal(false);
          }}
          onCancel={() => {
            setShowResultModal(false);
            // e.stopPropagation();
          }}
          // okText={props.modalBtnText ?? props.btnText}
          cancelText={t("cancel")}
        >
          <HtmlContent url={"plan/promResult/" + resultId} />
        </MyModal>
      )}
    </>
  );
};

export default PlanPromResult;
