import { Button, Col, Row, Tabs, Segmented, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../api/Request";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { useGlobalContext } from "../../context/GlobalContext";
import { PatientProfile } from "../../types/Patient";
import PatientPlans from "./PatientPlans";
import { Link } from "react-router-dom";
import PatientInfo from "./PatientInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/free-regular-svg-icons";
import {
  faFileMedical,
  faNotesMedical,
  faVideoCamera
} from "@fortawesome/free-solid-svg-icons";
import TelerehabTable from "../telerehab/TelerehabTable";
import { useAuth } from "../../context/AuthContext";
import { UserObject } from "../../types/UserData";
import PatientNotes from "./PatientNotes";

interface Props {}

const Patient: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { idFromUrl } = useParams();
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const { isTabletOrMobile } = useGlobalContext();
  const [newMeeting, setNewMeeting] = useState<number>();
  const [newNote, setNewNote] = useState<number>();
  const [tab, setTab] = useState<string>(isTabletOrMobile ? "1" : "1");

  const [profile, setProfile] = useState<PatientProfile>();
  const [, setLoading] = useState(false);

  const [id, setId] = useState(0);
  const getPatient = () => {
    setLoading(true);
    get<PatientProfile>("patient/profile/" + id)
      .then(r => {
        setProfile(r.data);
      })
      .catch(_ => {
        navigate("/patients", { replace: true });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id > 0) getPatient();
  }, [id]);

  useEffect(() => {
    if (profile && user)
      if (profile.patient.clinic_id !== user.clinic.id)
        get<UserObject>("account/changeClinic/" + profile.patient.clinic_id)
          .then(r => {
            setUser(r.data);
          })
          .catch(_ => {
            navigate("/patients", { replace: true });
          });
  }, [profile, user]);

  useEffect(() => {
    // console.log("fetching id", idFromUrl);
    setId(parseInt(idFromUrl ?? "0"));
  }, [idFromUrl]);

  return (
    <>
      {profile && (
        <>
          <MyPageHeader
            key="1"
            title={
              profile?.patient.first_name + " " + profile?.patient.last_name
            }
            subTitle="patient profile"
            extra={[
              <Space.Compact key="btns">
                <Button
                  key="5"
                  className="phb"
                  onClick={() => {
                    setTab("3");
                    setNewNote(Math.random());
                  }}
                >
                  <FontAwesomeIcon icon={faNotesMedical} />

                  {t("cn.add")}
                </Button>
                <Button key="3" className="phb">
                  <Link to={"/exercise-plan?patient=" + profile.patient.id}>
                    <FontAwesomeIcon icon={faRectangleList} />

                    {t("plan.createNewPlan")}
                  </Link>
                </Button>
                <Button
                  key="4"
                  className="phb1"
                  onClick={() => {
                    setTab("2");
                    setNewMeeting(Math.random());
                  }}
                >
                  <FontAwesomeIcon icon={faVideoCamera} />

                  {t("telerehab.createMeeting")}
                </Button>
              </Space.Compact>
            ]}
          />
          <Row
            key="2"
            className="wrapper123"
            gutter={!isTabletOrMobile ? [48, 16] : undefined}
          >
            {!isTabletOrMobile && (
              <Col span={7}>
                <div className="box">
                  <PatientInfo id={id} />
                </div>
              </Col>
            )}
            <Col span={!isTabletOrMobile ? 17 : 24}>
              {isTabletOrMobile && (
                <Segmented
                  options={[
                    {
                      label: t("info"),
                      value: "0"
                    },
                    {
                      label: t("cn.titles"),
                      value: "3"
                    },
                    {
                      label: t("plans"),
                      value: "1"
                    },
                    {
                      label: t("menu.telerehab"),
                      value: "2"
                    }
                  ]}
                  onChange={e => setTab(e.toString())}
                  block
                  defaultValue={tab}
                  size="large"
                />
              )}
              <Tabs
                // type="card"
                centered={true}
                defaultActiveKey={tab}
                activeKey={tab}
                size="large"
                onChange={e => setTab(e)}
                className="patient-profile-tabs"
                items={[
                  {
                    label: t("info"),
                    key: "0",
                    disabled: !isTabletOrMobile,
                    children: (
                      <div className={isTabletOrMobile ? "" : "box"}>
                        <PatientInfo id={id} />
                      </div>
                    )
                  },
                  {
                    label: t("cn.titlep"),
                    key: "3",
                    children: (
                      <div className={isTabletOrMobile ? "" : "box"}>
                        <PatientNotes
                          patientId={profile.patient.id}
                          firstName={profile.patient.first_name}
                          lastName={profile.patient.last_name}
                          newNote={newNote}
                          onChange={() => {}}
                        ></PatientNotes>
                      </div>
                    )
                  },
                  {
                    label: t("plans"),
                    key: "1",
                    children: (
                      <div className={isTabletOrMobile ? "" : "box"}>
                        <PatientPlans
                          plans={profile.plans}
                          patientId={profile.patient.id}
                          firstName={profile.patient.first_name}
                          lastName={profile.patient.last_name}
                          onChange={() => getPatient()}
                        ></PatientPlans>
                      </div>
                    )
                  },
                  {
                    label: t("menu.telerehab"),
                    key: "2",
                    children: (
                      <div className={isTabletOrMobile ? "" : "box"}>
                        <TelerehabTable
                          patientId={profile.patient.id}
                          newMeeting={newMeeting}
                          preselectedPatient={[profile.patient.id]}
                        />
                      </div>
                    )
                  }
                ].filter(t => !t.disabled)}
              ></Tabs>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Patient;
