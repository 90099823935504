import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  UploadProps,
  Image,
  Slider,
  Radio,
  Space
} from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { ApiResWithUser } from "../../types/General";
import MyDivider from "../../components/helper/MyDivider";
import Dragger from "antd/lib/upload/Dragger";
import TextTemplateEdit from "../../components/helper/TextTemplateEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { config } from "../../data/Constants";
const LetterheadSettings: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logoWidth, setLogoWidth] = useState(200);

  const onFinish = values => {
    setLoading(true);
    post<ApiResWithUser>("account/editLetterhead", values)
      .then(r => {
        // console.log("res data", r.data);
        if (r.data.user) {
          setUser(r.data.user);
        }
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // console.log(user);
    if (user) {
      setLogoWidth(user.clinic.logo_width);
    }
  }, [user, navigate]);
  const logoPositions = [
    {
      key: 1,
      label: t("settings.letterhead.logoHide"),
      value: "hide"
    },
    {
      key: 2,
      label: t("settings.letterhead.logoLeft"),
      value: "left"
    },
    {
      key: 3,
      label: t("settings.letterhead.logoMiddle"),
      value: "middle"
    },
    {
      key: 2,
      label: t("settings.letterhead.logoRight"),
      value: "right"
    }
  ];
  const props: UploadProps = {
    name: "user_logo",
    multiple: false,
    withCredentials: true,
    action: config.API_URL + "account/uploadLogo/" + user?.clinic.id,
    onChange(info) {
      const { status } = info.file;
      // console.log(info, "info");

      if (status !== "uploading") {
      }
      if (status === "done") {
        setUser(info.file.response.user);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    }
  };

  return (
    <>
      <Form
        name="basic"
        layout="horizontal"
        onFinish={onFinish}
        autoComplete="on"
        initialValues={{
          logo_position: user?.clinic.logo_position,
          logo_width: user?.clinic.logo_width,
          exercise_notes: user?.settings.exercise_notes
        }}
        form={form}
      >
        <MyDivider title={t("settings.letterhead.logoSettings")} />

        <div style={{ margin: "0 auto", textAlign: "center", maxWidth: 400 }}>
          {user && user.clinic.logo !== "" && (
            <>
              <Image
                style={{ textAlign: "center" }}
                src={user.clinic.logo}
                width={logoWidth}
                preview={false}
              />
              {/* <MyDivider title={} /> */}

              <Form.Item
                name="logo_width"
                label={t("settings.letterhead.logoSize")}
              >
                <Slider
                  defaultValue={30}
                  min={60}
                  max={400}
                  onChange={e => setLogoWidth(e)}
                />
              </Form.Item>
              <Form.Item
                name="logo_position"
                label={t("settings.letterhead.logoPosition")}
              >
                <Radio.Group
                  style={{ width: "100%" }}
                  options={logoPositions}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </>
          )}

          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <FontAwesomeIcon icon={faImage} size="4x" />
            </p>
            <p className="ant-upload-text">
              {t("settings.letterhead.logoUploadMsg")}
            </p>
          </Dragger>
        </div>

        {/* <p>Current selectionStart: {textarea}</p> */}
        <MyDivider title={t("settings.letterhead.defaultNotes")} />

        <Form.Item name="exercise_notes" hidden>
          <Input />
        </Form.Item>
        <div>
          <TextTemplateEdit
            type="letterhead"
            rows={14}
            onChange={d => {
              form.setFieldsValue({
                ...form.getFieldsValue(),
                exercise_notes: d
              });
            }}
          />
        </div>

        <Form.Item>
          <Space.Compact block>
            <Button loading={loading} type="primary" htmlType="submit" block>
              {t("save")}
            </Button>
            <Button
              onClick={() =>
                window.open(config.API_URL + "plan/testLetterhead", "_blank")
              }
              block
            >
              {t("settings.letterhead.testLetterhead")}
            </Button>
          </Space.Compact>
        </Form.Item>
      </Form>
    </>
  );
};

export default LetterheadSettings;
