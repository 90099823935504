import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PromList } from "../../types/Plan";
import { SingleProm, SinglePromEdit } from "../../types/PlanUpsert";
import MyModal from "../../components/MyModal";
const { Option } = Select;

interface Props {
  showModal: boolean;
  all_proms: PromList[];
  plan_proms: SingleProm[];
  prom?: SinglePromEdit;
  onCancel: () => void;
  onDone: (p: SinglePromEdit) => void;
}
const UPUpsertPromModal: React.FC<Props> = ({
  showModal,
  all_proms,
  plan_proms,
  prom,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (prom === undefined)
      form.setFieldsValue({ id: null, schedule: "start_end", index: null });
    else form.setFieldsValue(prom);
  }, [prom, form]);

  return (
    <MyModal
      title={
        prom !== undefined ? t("planUpsert.editProm") : t("planUpsert.addProm")
      }
      open={showModal}
      onCancel={onCancel}
      okText={t("save")}
      forceRender
      destroyOnClose={true}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onDone(form.getFieldsValue());
          })
          .catch(info => {})
          .finally(() => {});

        // onDone(changedExercise);
      }}
    >
      <Form form={form} layout="vertical" onFinish={v => {}}>
        <Form.Item hidden name="index"></Form.Item>
        <Form.Item
          name="id"
          label={t("planUpsert.selectProm")}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select
            disabled={prom !== undefined}
            style={{ width: "100%" }}
            placeholder={t("planUpsert.addProm")}
          >
            {all_proms?.map(p => (
              <Option
                key={p.id}
                disabled={
                  plan_proms?.find(selectedProm => selectedProm.id === p.id) ??
                  false
                }
                value={p.id}
              >
                {p.prom_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="schedule"
          initialValue="start_end"
          label={t("planUpsert.promSchedule")}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select style={{ width: "100%" }}>
            <Option value="start_end">{t("plan.start_end")}</Option>
            <Option value="start">{t("plan.start")}</Option>
            <Option value="end">{t("plan.end")}</Option>
          </Select>
        </Form.Item>
      </Form>
    </MyModal>
  );
};

export default UPUpsertPromModal;
