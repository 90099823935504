import React, { useState } from "react";
import { Form, Button, Alert, Select } from "antd";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const MergeDuplicatePatients: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = () => {
    setLoading(true);
    post("account/mergeDuplicatePatients", form.getFieldsValue())
      .then(r => {})
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Alert
        type="warning"
        message={t("form.warning.warning")}
        description={t("form.warning.cantBeReversed")}
        closable
        style={{ marginBottom: 32 }}
      />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          // label={t("language")}
          name="merge_option"
          initialValue={"email_name"}
          rules={[{ required: true }]}
        >
          <Select>
            <Option value="email_name">{t("form.mergeEmailName")}</Option>
            <Option value="name">{t("form.mergeName")}</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit" block>
            {t("settings.mergeDuplicatePatients")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MergeDuplicatePatients;
