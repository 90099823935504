import { Document, Page } from "react-pdf";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"; // Import PDF styles
import MyModal from "../../components/MyModal";

interface Props {
  link: string | null;
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

const ViewPdfModal: React.FC<Props> = ({
  link,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return link !== null ? (
    <MyModal
      title="PDF"
      open={showModal}
      destroyOnClose={true}
      // width={modalWidth}
      width={1000}
      onCancel={() => {
        onCancel();
      }}
      okText={t("close")}
      cancelButtonProps={{ style: { display: "none" } }}
      forceRender
      onOk={() => {
        onDone();
      }}
    >
      <Document
        file={link}
        onLoadSuccess={onDocumentLoadSuccess}
        // width={900}
        // style={{ width: "100%" }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={900}
            // style={{ width: "900px" }}
          />
        ))}
      </Document>
    </MyModal>
  ) : (
    <></>
  );
};

export default ViewPdfModal;
