import React, { useEffect, useState } from "react";
import { get } from "../../api/Request";
import { useAuth } from "../../context/AuthContext";
import LevelIcon from "../../components/helper/LevelIcon";
import { Result, Skeleton } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  practitionerId: number;
}

const PractitionerLevelDisplay: React.FC<Props> = ({ practitionerId }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [planData, setPlanData] = useState<{
    plan_count: number;
    next_level: { exercises_needed: number; next_level: number };
  } | null>(null);

  useEffect(() => {
    get("practitioner/getPlansCount/" + practitionerId).then(r => {
      console.log(r.data, "pc data");
      setPlanData(r.data);
    });
  }, []);

  return planData !== null && user && planData.plan_count > 2 ? (
    <>
      {planData.plan_count < 5000 ? (
        <Result
          icon={<LevelIcon level={user.practitioner.level} bigIcon />}
          title={t("account.plansCountMessage", {
            pln_cnt: planData.plan_count
          })}
          subTitle={t("account.nextLevelMessage", {
            ex_ndn: planData.next_level.exercises_needed
          })}
        />
      ) : (
        <Result
          icon={<LevelIcon level={user.practitioner.level} bigIcon />}
          title={t("Congratulations!")}
          subTitle={t("account.highestLevelMessage")}
        />
      )}
    </>
  ) : (
    <Skeleton active />
  );
};

export default PractitionerLevelDisplay;
