import React, { useEffect, useState } from "react";
import {
  List,
  Button,
  Input,
  Checkbox,
  Tooltip,
  Form,
  DatePicker,
  Select,
  Space,
  notification
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faImage,
  faListOl,
  faPlus,
  faFloppyDisk,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {
  ExerciseInPlan,
  PlanMode,
  PlanUpsertDto,
  SingleProm,
  SinglePromEdit
} from "../../types/PlanUpsert";
import dayjs from "dayjs";

import {
  ImageType,
  MonitorOptionList,
  PlanViewDTO,
  PromList,
  UserTemplateCat
} from "../../types/Plan";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useLocationState } from "react-router-use-location-state";
import { useGlobalContext } from "../../context/GlobalContext";
import UPContinueModal from "./UPContinueModal";
import UPEditExerciseModal from "./UPEditExerciseModal";
import UPSortableExercises from "./UPSortableExercises";
import UPUpsertPromModal from "./UPUpsertPromModal";
import LoadFromTemplateModalMemo from "./LoadFromTemplateModal";
import MyDivider from "../../components/helper/MyDivider";
import TextArea from "antd/lib/input/TextArea";
import { get, post } from "../../api/Request";
import PatientSelect3 from "../../components/PatientSelect3";

const { Option } = Select;
interface PlanHelperRes {
  all_proms: PromList[];
  monitor_options: MonitorOptionList[];
}
interface Props {
  newExercise: ExerciseInPlan | null;
  removeExercise: ExerciseInPlan | null;
  imageType: ImageType;
  id: number;
  patient: number | null;
  mode: PlanMode;
  lang: string;
  showTemplates: boolean;
  setShowTemplates: (showTemplatesModal: boolean) => void;
  onUpdateExercises: (exercises: ExerciseInPlan[]) => void;
  onChangeImageType: (it: ImageType) => void;
}

const UPExercisesPreview: React.FC<Props> = ({
  newExercise,
  removeExercise,
  imageType,
  id,
  patient,
  mode,
  lang,
  showTemplates,
  setShowTemplates,
  onUpdateExercises,
  onChangeImageType,
  ...props
}) => {
  const [formRef] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useAuth();
  const { isTabletOrMobile, isMobile } = useGlobalContext();

  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showPromModal, setShowPromModal] = useState(false);
  const [exerciseInModal, setExerciseInModal] = useState<ExerciseInPlan>();
  const [showExerciseUpsertModal, setShowExerciseUpsertModal] = useState(false);

  const [exercisesCount, setExercisesCount] = useLocationState(
    "exercisesCount",
    0
  );

  const newPlan = {
    id: id,
    patient_id:
      patient === 0 || patient === null || patient === undefined
        ? undefined
        : patient,
    exercises: [],
    mode: mode,
    notes: mode === "template" ? user?.settings.exercise_notes : "",
    image_type: imageType ?? ImageType.photo,
    has_tracking: false,
    duration: 4,
    name: "",
    template_category_id: 1,
    start: dayjs().format("YYYY-MM-DD"),
    lang: lang
  };

  const [plan, setPlan] = useState<PlanUpsertDto>();
  const [forceSaveAsNew, setForceSaveAsNew] = useState(false);
  const [planProms, setPlanProms] = useState<SingleProm[]>([]);
  const [promForEdit, setPromForEdit] = useState<SinglePromEdit>();

  const [userTemplateCats, setUserTemplateCats] = useState<UserTemplateCat[]>();
  const [loading, setLoading] = useState(false);
  const [showTracking, setShowTracking] = useState(false);
  const [allMonitors, setAllMonitors] = useState<MonitorOptionList[]>();
  const [allProms, setAllProms] = useState<PromList[]>();

  const [searchParams, _] = useSearchParams();

  const getTemplateCats = () => {
    get<UserTemplateCat[]>("template/userTemplateCats")
      .then(r => {
        setUserTemplateCats(r.data);
        // console.log(r.data, "user cats");
      })
      .catch(_ => {})
      .finally(() => {});
  };

  const getTrackingHelperData = () => {
    get<PlanHelperRes>("plan/planHelper/")
      .then(r => {
        setAllMonitors(r.data.monitor_options);
        setAllProms(r.data.all_proms);
        // console.log(r.data, "plan helper");
      })
      .catch(_ => {});
  };

  const getPlan = (planId: number, loadFromTemplate: boolean) => {
    get<PlanViewDTO>("plan/patientPlan/" + planId)
      .then(r => {
        if (!loadFromTemplate) {
          // this is edit plan
          setPlan({
            id: planId,
            mode: mode,
            patient_id: r.data.plan.patient_id,
            name: r.data.plan.name,
            template_category_id: r.data.plan.template_category_id,
            desc: r.data.plan.desc,
            notes: r.data.plan.notes,
            image_type: r.data.plan.image_type,
            exercises: r.data.exercises,
            has_tracking: r.data.plan.has_tracking ? true : false,
            duration: r.data.plan.duration > 0 ? r.data.plan.duration : 4,
            start: r.data.plan.start ?? dayjs().format("YYYY-MM-DD"),
            end: r.data.plan.end ?? "",
            lang: r.data.plan.lang ?? lang,
            monitor: r.data.monitor_ids,
            prom: r.data.prom.map(p => ({ id: p.id, schedule: p.schedule }))
          });
        } else {
          // set only exercises, tracking etc...
          formRef.setFieldsValue({
            ...formRef.getFieldsValue,
            exercises: r.data.exercises,
            name: r.data.plan.name,
            lang: r.data.plan.lang ?? lang,
            has_tracking: r.data.plan.has_tracking ? true : false,
            duration: r.data.plan.duration > 0 ? r.data.plan.duration : 4,
            monitor: r.data.monitor_ids
          });
        }
        onChangeImageType(r.data.plan.image_type);
        setShowTracking(r.data.plan.has_tracking);
        setPlanProms(
          r.data.prom.map(p => ({ id: p.id, schedule: p.schedule }))
        );
      })
      .catch(_ => {});
  };

  const deleteExercise = (id: number) => {
    const fData = formRef.getFieldsValue() as PlanUpsertDto;
    const newE = fData.exercises.filter(e => e.id !== id);
    formRef.setFieldsValue({
      ...fData,
      exercises: newE
    });
    savePlanToLocalStorage();
  };

  const getPlanUpsertData = () => {
    const values = formRef.getFieldsValue();
    return {
      ...values,
      start: values.start ? dayjs(values.start).format("YYYY-MM-DD") : "",
      end: values.end ? dayjs(values.end).format("YYYY-MM-DD") : "",
      mode: mode,
      lang: lang,
      image_type: imageType,
      prom_from_app: planProms
    };
  };

  const generateNotes = (patient_id: number) => {
    // if it is edit plan and patient is same then dont generate notes (unles forceSaveAsNew is true because patient can be changed and then go back to same patient)
    if (
      patient_id > 0 &&
      plan?.patient_id === patient_id &&
      forceSaveAsNew !== true
    )
      return;

    // if patient is changed then force save as new plan
    if (plan && plan.mode === PlanMode.plan && plan.patient_id !== patient_id) {
      setForceSaveAsNew(true);
      formRef.setFieldsValue({
        ...formRef.getFieldsValue,
        save_as_new_plan: true
      });
    } else {
      setForceSaveAsNew(false);
    }

    // if (plan?.patient_id === id) setForceSaveAsNew(true);
    get("plan/getNotesAndImageType/" + patient_id + "/" + lang)
      .then(r => {
        formRef.setFieldsValue({
          ...formRef.getFieldsValue,
          notes: r.data.notes
        });
        const imageType = parseInt(r.data.image_type) as ImageType;
        if (imageType === ImageType.lineart || imageType === ImageType.photo)
          onChangeImageType(parseInt(r.data.image_type) as ImageType);
      })
      .catch(_ => {});
  };

  const openRestoreNotification = () => {
    const key = `load_from_session`;
    const btn = (
      <>
        <Button
          // size="small"
          onClick={() => {
            notification.destroy(key);
            localStorage.removeItem("old_plan");
          }}
        >
          {t("cancel")}
        </Button>{" "}
        <Button
          type="primary"
          // size="small"
          onClick={() => {
            notification.destroy(key);
            let planFromLocalStorage = JSON.parse(
              localStorage.getItem("old_plan") as string
            );
            localStorage.removeItem("old_plan");

            planFromLocalStorage = {
              ...planFromLocalStorage,
              start:
                planFromLocalStorage.start !== ""
                  ? dayjs(planFromLocalStorage.start)
                  : undefined,
              end:
                planFromLocalStorage.end !== ""
                  ? dayjs(planFromLocalStorage.end)
                  : undefined
            };

            formRef.setFieldsValue(planFromLocalStorage);
            if (planFromLocalStorage.has_tracking) setShowTracking(true);
          }}
        >
          {t("planUpsert.restore")}
        </Button>
      </>
    );
    notification.open({
      message: t("planUpsert.restoreSession"),
      description: t("planUpsert.restoreMsg"),
      btn,
      key,
      duration: 6
    });
  };
  // id = plan id from url

  useEffect(() => {
    if (plan && plan.id === 0) {
      let patient_selected = parseInt(formRef.getFieldValue("patient_id"));
      if (patient_selected) {
        // gnerate new notes
        get<string>("plan/generateNotes/" + patient_selected + "/" + lang)
          .then(r => {
            formRef.setFieldsValue({
              ...formRef.getFieldsValue,
              notes: r.data
            });
          })
          .catch(_ => {});
      }
      searchParams.delete("refresh");

      // remove exercises because new lang is set
      formRef.setFieldsValue({
        ...formRef.getFieldsValue,
        exercises: []
      });
    }
  }, [lang]);

  useEffect(() => {
    if (localStorage.getItem("plan") !== null) {
      localStorage.setItem("old_plan", localStorage.getItem("plan") as string);
    }
    // return;
    if (id > 0) getPlan(id, false);
    else {
      setPlan(newPlan);
      setShowTracking(false);
      //clear previous data
      setPlanProms([]);
      // formRef.setFieldsValue({
      //   ...formRef.getFieldsValue,
      //   patient_id: patient
      // });
      if (patient !== null && patient > 0) {
        generateNotes(patient);
      }
    }
    return () => {
      notification.destroy("load_from_session");
    };
  }, [id, patient]);

  // get monitors, proms etc...
  useEffect(() => {
    if (allMonitors === undefined) getTrackingHelperData();
  }, [allMonitors]);

  useEffect(() => {
    if (newExercise !== null) {
      formRef.setFieldsValue({
        ...formRef.getFieldsValue,
        exercises: [...formRef.getFieldValue("exercises"), newExercise]
      });
      savePlanToLocalStorage();
      // on small screen show edit exercise modal
      if (isMobile && newExercise.advice_sheet_pdf === "") {
        setExerciseInModal(newExercise);
        setShowExerciseUpsertModal(true);
      }
    }
  }, [newExercise]);

  useEffect(() => {
    if (removeExercise !== null) {
      deleteExercise(removeExercise.id);
      savePlanToLocalStorage();
    }
  }, [removeExercise]);

  // this will set form fields when plan is changed
  useEffect(() => {
    formRef.setFieldsValue({
      ...plan,
      start: dayjs(plan?.start),
      end: dayjs(plan?.end)
    });

    const planFromLocalStorage = JSON.parse(
      localStorage.getItem("old_plan") as string
    );
    if (
      plan &&
      planFromLocalStorage !== null &&
      planFromLocalStorage.id === plan.id &&
      planFromLocalStorage.exercises.length > 0 &&
      planFromLocalStorage.exercises !== undefined &&
      plan.exercises !== planFromLocalStorage.exercises &&
      planFromLocalStorage.mode === mode
    ) {
      openRestoreNotification();
      // formRef.setFieldsValue(planFromLocalStorage);
      // return;
    } else {
      // localStorage.removeItem("plan");
      // localStorage.removeItem("old_plan");
    }
    if (plan !== undefined && plan.patient_id) generateNotes(plan.patient_id!);
  }, [plan]);

  useEffect(() => {
    if (mode === "template" && user && plan !== undefined) {
      getTemplateCats();
    }
  }, [mode, user, plan]);

  const savePlanToLocalStorage = () => {
    localStorage.setItem("plan", JSON.stringify(getPlanUpsertData()));
  };

  const removePlanFromLocalStorage = () => {
    localStorage.removeItem("plan");
    localStorage.removeItem("old_plan");
  };

  const submit = () => {
    setLoading(true);
    formRef
      .validateFields()
      .then(v => {
        post("plan/upsert", getPlanUpsertData())
          .then(r => {
            removePlanFromLocalStorage();
            if (mode === "plan") navigate("/patient/plan/" + r.data.plan_id);
            else navigate("/template/" + r.data.plan_id, { replace: true });
          })
          .catch(e => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(_ => {})
      .finally(() => {});
  };

  return (
    <>
      {!isTabletOrMobile && <MyDivider title={t("planUpsert.planPreview")} />}

      <Form
        id="planForm"
        layout="vertical"
        form={formRef}
        scrollToFirstError={true}
        onFinish={v => {
          submit();
        }}
        onValuesChange={(changedValues, allValues) => {
          // wait 1 second before saving to local storage
          setTimeout(() => {
            savePlanToLocalStorage();
          }, 500);
          // savePlanToLocalStorage();
        }}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          name="exercises"
          rules={[
            {
              required: true,
              message: t("planUpsert.pleaseAddExercises")
            }
          ]}
        >
          <UPSortableExercises
            onChange={e => {}}
            onInputChange={exercises => {
              setExercisesCount(exercises.length);
              onUpdateExercises(exercises);
            }}
            imageType={imageType}
            onDelete={id => deleteExercise(id)}
            onEdit={e => {
              setExerciseInModal(e);
              setShowExerciseUpsertModal(true);
            }}
          />
        </Form.Item>
        <Button
          hidden={!isTabletOrMobile}
          onClick={_ => setShowTemplates(true)}
          type="link"
          style={{ marginBottom: 16 }}
          block
        >
          {t("template.loadFromTemplate")}
        </Button>
        {imageType === ImageType.photo ? (
          <Button
            // type="dashed"
            className="margin-bottom"
            block
            onClick={() => {
              onChangeImageType(ImageType.lineart);
            }}
          >
            <FontAwesomeIcon icon={faImage} /> {t("planUpsert.switchToLineart")}
          </Button>
        ) : (
          <Button
            // type="dashed"
            className="margin-bottom"
            block
            onClick={() => {
              onChangeImageType(ImageType.photo);
            }}
          >
            <FontAwesomeIcon icon={faImage} /> {t("planUpsert.switchToPhoto")}
          </Button>
        )}

        <Form.Item
          className="margin-top"
          name="name"
          label={
            mode === "plan" ? t("planUpsert.name") : t("template.templateName")
          }
          rules={[
            {
              required: mode === "template" ? true : false
              //   message: t("form.fieldRequired", { field: t("firstName") })
            }
          ]}
        >
          <Input placeholder={t("planUpsert.name")} />
        </Form.Item>
        <Form.Item
          hidden={mode === "plan"}
          className="margin-top"
          label={t("description")}
          name="desc"
        >
          <TextArea
            autoSize={{ minRows: 4, maxRows: 15 }}
            placeholder={t("template.descPlaceholder")}
          />
        </Form.Item>

        {mode === "template" &&
          userTemplateCats &&
          user?.enable_template_categories && (
            <Form.Item
              name="template_category_id"
              className="margin-top"
              label={t("template.category")}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                key="all_c"
                style={{ width: "100% " }}
                showSearch
                optionFilterProp="children"
                filterOption={true}
              >
                <Option value={0}>{t("template.allCats")}</Option>
                {userTemplateCats.map(uc => (
                  <Option key={uc.id.toString()} value={uc.id}>
                    {uc.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        {mode === "plan" && (
          <Form.Item
            name="patient_id"
            className="margin-top"
            label={
              <>
                {t("planUpsert.patientName")}
                <Button hidden type="link" style={{ float: "right" }}>
                  Add new patient
                </Button>
              </>
            }
            rules={[
              {
                required: true,
                message: t("planUpsert.pleaseSelectPatient")
              }
            ]}
          >
            <PatientSelect3
              onChange={newValue => {
                // console.log("On change patient", newValue);
                if (newValue !== undefined) {
                  generateNotes(newValue as number);
                }
              }}
              // onChangeMulti={() => {}}
            />
          </Form.Item>
        )}

        <Form.Item
          name="notes"
          label={t("planUpsert.notes")}
          hidden={!isMobile}
        >
          <TextArea autoSize={{ minRows: 4, maxRows: 15 }} showCount />
        </Form.Item>
        <Form.Item name="has_tracking" valuePropName="checked">
          <Checkbox
            // checked={plan.has_tracking}
            onChange={_ => setShowTracking(!showTracking)}
          >
            {t("plan.useTracking")}{" "}
            <Tooltip title={t("plan.useTrackingHint")}>
              <QuestionCircleOutlined rev={undefined} />
            </Tooltip>
          </Checkbox>
        </Form.Item>
        {showTracking && plan && (
          <>
            <Form.Item
              hidden={mode !== "plan"}
              label={t("plan.startDate")}
              name="start"
              rules={[
                {
                  type: "object",
                  required: true
                  //   message: t("form.fieldRequired", { field: t("firstName") })
                }
              ]}
            >
              <DatePicker
                inputReadOnly={plan.id > 0}
                style={{ width: "100%" }}
              />
            </Form.Item>
            {!(mode !== "plan" || plan.id === 0 || plan.end === "") && (
              <Form.Item
                hidden={mode !== "plan" || plan.id === 0 || plan.end === ""}
                label={t("plan.endDate")}
                name="end"
                rules={[
                  {
                    // type: "object",
                    required: true
                    //   message: t("form.fieldRequired", { field: t("firstName") })
                  }
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            )}

            {(mode === "template" || plan.id === 0 || plan.end === "") && (
              <Form.Item
                label={t("plan.duration")}
                name="duration"
                rules={[
                  {
                    required: true
                    //   message: t("form.fieldRequired", { field: t("firstName") })
                  }
                ]}
              >
                <Input type="number" min={1} step={1} max={50} />
              </Form.Item>
            )}

            <MyDivider title={t("plan.monitor")} className="margin-top-32" />

            <Form.Item name="monitor">
              <Checkbox.Group
                className="monitor-checkboxes"
                options={allMonitors?.map(m => {
                  return { label: m.name, value: m.id };
                })}
              />
            </Form.Item>
            <MyDivider title={t("plan.prom")} className="margin-top-32" />
            <List itemLayout="horizontal">
              {planProms.map((p, i) => (
                <List.Item
                  key={p.id}
                  actions={[
                    <Space.Compact
                      block
                      size={isTabletOrMobile ? "large" : "small"}
                    >
                      <Button
                        style={{ minWidth: "33%" }}
                        size={isTabletOrMobile ? "large" : "small"}
                        onClick={_ => {
                          setPromForEdit({ ...p, index: i });
                          setShowPromModal(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <Button
                        style={{ minWidth: "33%" }}
                        size={isTabletOrMobile ? "large" : "small"}
                        danger
                        onClick={() => {
                          setPlanProms(planProms.filter(pf => pf.id !== p.id));
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </Space.Compact>
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <FontAwesomeIcon
                        style={{ marginTop: 8 }}
                        size="2x"
                        icon={faListOl}
                      />
                    }
                    title={
                      <>{allProms?.find(prom => p.id == prom.id)?.prom_name}</>
                    }
                    description={t("plan." + p.schedule)}
                  />
                </List.Item>
              ))}
            </List>
            <Button
              className="margin-bottom"
              block
              onClick={() => {
                setPromForEdit(undefined);
                setShowPromModal(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> {t("planUpsert.addProm")}
            </Button>
          </>
        )}

        {!isMobile && plan && (
          <Button
            block
            type="primary"
            className="margin-bottom darker-blue-btn"
            onClick={e => {
              // e.preventDefault();
              formRef
                .validateFields()
                .then(v => {
                  setShowContinueModal(true);
                })
                .catch(_ => {})
                .finally(() => {});
            }}
          >
            {t("planUpsert.continue")}
          </Button>
        )}
        {isMobile && plan && (
          <>
            {plan.id > 0 && plan.mode === PlanMode.plan && (
              <Form.Item name="save_as_new_plan" valuePropName="checked">
                <Checkbox disabled={forceSaveAsNew}>
                  {t("planUpsert.saveAsNewPlan")}
                </Checkbox>
              </Form.Item>
            )}
            {plan.id > 0 && plan.mode === PlanMode.template && (
              <Form.Item name="save_as_new_plan" valuePropName="checked">
                <Checkbox>{t("planUpsert.saveAsNewTemplate")}</Checkbox>
              </Form.Item>
            )}
            <Button block type="primary" htmlType="submit" loading={loading}>
              <FontAwesomeIcon icon={faFloppyDisk} />
              {t("save")}
            </Button>
          </>
        )}
      </Form>

      {showContinueModal && (
        <UPContinueModal
          initialPlan={getPlanUpsertData()}
          forceSaveAsNew={forceSaveAsNew}
          showModal={showContinueModal}
          onDone={(id, mode) => {
            removePlanFromLocalStorage();

            if (mode === "plan")
              navigate("/patient/plan/" + id, { replace: true });
            else navigate("/template/" + id, { replace: true });
          }}
          onUpdate={updatedPlan => {
            formRef.setFieldsValue({
              ...formRef.getFieldsValue(),
              ...updatedPlan,
              start: updatedPlan.start ? dayjs(updatedPlan.start) : undefined,
              end: updatedPlan.end ? dayjs(updatedPlan.end) : undefined
            });
            savePlanToLocalStorage();
          }}
          onCancel={() => {
            setShowContinueModal(false);
          }}
        />
      )}

      {plan && allProms && (
        <UPUpsertPromModal
          prom={promForEdit}
          all_proms={allProms}
          plan_proms={planProms}
          showModal={showPromModal}
          onDone={prom => {
            if (prom.index === null)
              setPlanProms(pp => [
                ...pp,
                { id: prom.id, schedule: prom.schedule }
              ]);
            else {
              setPlanProms(pp => [
                ...pp.filter((p, i) => i !== prom.index),
                { id: prom.id, schedule: prom.schedule }
              ]);
            }
            setShowPromModal(false);
          }}
          onCancel={() => {
            setShowPromModal(false);
          }}
        />
      )}
      {showTemplates && (
        <LoadFromTemplateModalMemo
          showModal={showTemplates}
          onDone={id => {
            getPlan(id, true);
            // setTemplateId(id);
            setShowTemplates(false);
          }}
          onCancel={() => {
            setShowTemplates(false);
          }}
        />
      )}
      {exerciseInModal && (
        <UPEditExerciseModal
          exercise={exerciseInModal}
          showModal={showExerciseUpsertModal}
          imageType={imageType}
          onCancel={() => setShowExerciseUpsertModal(false)}
          onDone={editedEx => {
            setShowExerciseUpsertModal(false);
            const fData = formRef.getFieldsValue() as PlanUpsertDto;
            const newE = fData.exercises.map(oldEx => {
              return editedEx.id === oldEx.id ? editedEx : oldEx;
            });
            formRef.setFieldsValue({
              ...fData,
              exercises: newE
            });
            savePlanToLocalStorage();
          }}
        />
      )}
    </>
  );
};

const areEqual = (prev, next) => {
  return (
    prev.imageType === next.imageType &&
    prev.newExercise === next.newExercise &&
    prev.removeExercise === next.removeExercise &&
    prev.showTemplates === next.showTemplates &&
    prev.id === next.id &&
    prev.lang === next.lang &&
    prev.loadFromTemplateId === next.loadFromTemplateId &&
    prev.mode === next.mode &&
    prev.patient === next.patient &&
    prev.user === next.user
  );
};

const UPExercisesPreviewMemo = React.memo(UPExercisesPreview, areEqual);

export default UPExercisesPreviewMemo;
