import MainMenu from "./components/nav/MainMenu";
import { Button, Col, Layout, Row } from "antd";

import { useAuth } from "./context/AuthContext";
import TabMenu from "./components/nav/TabMenu";
import { useGlobalContext } from "./context/GlobalContext";
import TopMenu from "./components/nav/TopMenu";
import { FloatButton } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { config } from "./data/Constants";
import { useTranslation } from "react-i18next";
import NavRoutes from "./components/nav/Routes";
import SysNotifications from "./components/helper/SysNotifications";
import { useEffect, useState } from "react";
const { Header, Content } = Layout;

function AppContent() {
  const { isTabletOrMobile } = useGlobalContext();
  const { t } = useTranslation();
  const currentUrl = window.location.pathname;

  const [isRtl, setIsRtl] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      setIsRtl(config.RTL_LANGS.includes(user.practitioner.lang.toString()));
    }
  }, [user]);

  if (user) {
    if (user.licence === null) {
    }
  }

  if (isTabletOrMobile)
    return (
      <>
        <div
          className={
            user === null || currentUrl === "/payNow"
              ? "app auth-pages tablet-mobile"
              : "app tablet-mobile"
          }
          dir={isRtl ? "rtl" : "ltr"}
        >
          <div className="body">
            <NavRoutes />
            <FloatButton.BackTop />
          </div>
          {user !== null && user.id !== undefined && (
            <div className="bottom">
              <TabMenu />
            </div>
          )}
        </div>
      </>
    );
  return (
    <Layout
      className={
        user === null || currentUrl === "/payNow"
          ? "auth-pages desktop"
          : "desktop"
      }
    >
      {user !== null &&
        user.id !== undefined &&
        !isTabletOrMobile &&
        !currentUrl.includes("payNow") && (
          <>
            <TopMenu />
            <Header>
              <MainMenu />
            </Header>
          </>
        )}
      <Content className="wrapper">
        <SysNotifications />

        <NavRoutes />
        <FloatButton.BackTop style={{ marginBottom: 8 }} />
      </Content>
      <Footer>
        <div id="footer2"></div>
        <div className="wrapper">
          <Row>
            <Col>
              <div>
                {t("footer.rightsReserved")} | {config.VERSION}
              </div>
            </Col>
            <Col flex="auto"></Col>
            <Col
              className="gutter-row"
              style={{ textAlign: "right", float: "right" }}
            >
              <Button
                // type=""
                onClick={_ => {
                  window.open(config.OLD_SITE_URL + "patients", "_blank");
                }}
              >
                {t("footer.goToOldSite")}
              </Button>
            </Col>
          </Row>
        </div>
      </Footer>
    </Layout>
  );
}

export default AppContent;
