import { List, Avatar, Button, Result, Space, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageType } from "../../types/Plan";
import { arrayMoveImmutable } from "array-move";

import { ExerciseInPlan } from "../../types/PlanUpsert";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import {
  faArrowRightArrowLeft,
  faEdit,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalContext } from "../../context/GlobalContext";
import MyDivider from "../../components/helper/MyDivider";

const SortableItem = SortableElement<{ children: React.ReactNode }>(
  ({ children }) => children
);

const SortableContainerEl = SortableContainer<{ children: React.ReactNode }>(
  ({ children }) => {
    return <div>{children}</div>;
  }
);
interface Props {
  value?: ExerciseInPlan[];
  imageType: ImageType;
  onChange: (exercises: ExerciseInPlan[]) => void;
  onInputChange: (exercises: ExerciseInPlan[]) => void;
  onDelete: (id: number) => void;
  onEdit: (e: ExerciseInPlan) => void;
}

const UPSortableExercises: React.FC<Props> = ({
  value,
  imageType,
  onChange,
  onInputChange,
  onDelete,
  onEdit
}) => {
  const { t } = useTranslation();

  const [advices, setAdvice] = useState<ExerciseInPlan[]>([]);
  const [exercises, setExercises] = useState<ExerciseInPlan[]>([]);

  // const [e, setE] = useState<ExerciseInPlan[]>(value);
  useEffect(() => {
    setAdvice(value?.filter(e => e.advice_sheet_pdf !== "") ?? []);
    setExercises(value?.filter(e => e.advice_sheet_pdf === "") ?? []);
    // setPlan(initialPlan);
    // console.log(exercises, "ex loaded");
    // console.log("exercise sortable in modal", value);
  }, [value]);

  useEffect(() => {
    onInputChange(value ?? []);
  }, [value]);

  const DragableTitle = SortableHandle<{ title: string }>(({ title }) => (
    <div
      style={{ fontSize: 15, lineHeight: 1.6, marginBottom: 8 }}
      className="grabable"
    >
      {title}
    </div>
  ));
  const DragableButton = SortableHandle(() => (
    <Button
      style={{ minWidth: "33%" }}
      size={isTabletOrMobile ? "large" : "small"}
    >
      <div className="grabable">
        <FontAwesomeIcon icon={faArrowRightArrowLeft} rotation={90} />
      </div>
    </Button>
  ));

  const { isTabletOrMobile } = useGlobalContext();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange([
      ...arrayMoveImmutable(exercises, oldIndex, newIndex),
      ...advices
    ]);
    setExercises(exercises =>
      arrayMoveImmutable(exercises, oldIndex, newIndex)
    );
  };

  return (
    <>
      {exercises !== undefined && exercises.length > 0 ? (
        <SortableContainerEl onSortEnd={onSortEnd} useDragHandle>
          {exercises.map((e, index) => (
            <SortableItem key={`elist-${e.id}`} index={index}>
              <Row
                key={`exercisepreview-${e.id}`}
                // gutter={[16, 16]}
                wrap={false}
                style={{ marginBottom: 16 }}
              >
                {/* <Col flex={isTabletOrMobile ? "94px" : "64px"}> */}
                <Col flex="none">
                  <Avatar
                    className="exercise-list-img"
                    size={isTabletOrMobile ? 90 : 64}
                    shape="square"
                    src={
                      imageType === ImageType.photo
                        ? e.image_thumbnail
                        : e.lineart_thumbnail
                    }
                  />
                </Col>
                <Col flex="auto" style={{ marginLeft: 16 }}>
                  <DragableTitle title={e.title} />
                  <Space.Compact
                    block
                    size={isTabletOrMobile ? "large" : "small"}
                  >
                    <Button
                      style={{ minWidth: "33%" }}
                      size={isTabletOrMobile ? "large" : "small"}
                      draggable={false}
                      onClick={_ => {
                        onEdit(e);
                        // setShowExerciseUpsertModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                      style={{ minWidth: "33%" }}
                      size={isTabletOrMobile ? "large" : "small"}
                      danger
                      draggable={false}
                      onClick={_ => onDelete(e.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                    <DragableButton />
                  </Space.Compact>
                </Col>
              </Row>
            </SortableItem>
          ))}
        </SortableContainerEl>
      ) : (
        <Result title={t("planUpsert.noExercises")} icon={<></>} />
      )}

      {advices.length > 0 && <MyDivider title={t("planUpsert.adviceSheets")} />}
      <List>
        {advices.map((e, index) => (
          <Row
            justify="space-around"
            align="middle"
            key={e.id.toString()}
            // gutter={[16, 16]}
            wrap={false}
            style={{ marginBottom: 16 }}
          >
            {/* <Col flex={isTabletOrMobile ? "94px" : "64px"}> */}
            <Col flex="none">
              <Avatar
                src={
                  imageType === ImageType.photo
                    ? e.image_thumbnail
                    : e.lineart_thumbnail
                }
              />
            </Col>
            <Col flex="auto" style={{ marginLeft: 16 }}>
              <DragableTitle title={e.title} />
            </Col>
            <Col>
              {" "}
              <Button danger draggable={false} onClick={_ => onDelete(e.id)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </Col>
          </Row>
        ))}
      </List>
    </>
  );
};

export default UPSortableExercises;
