import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageType } from "../../types/Plan";
import { ExerciseInPlan } from "../../types/PlanUpsert";
import UPExerciseEdit from "./UPExerciseEdit";
import MyModal from "../../components/MyModal";

interface Props {
  showModal: boolean;
  exercise: ExerciseInPlan;
  imageType: ImageType;
  onCancel: () => void;
  onDone: (e: ExerciseInPlan) => void;
}
const UPEditExerciseModal: React.FC<Props> = ({
  showModal,
  exercise,
  imageType,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();

  const [changedExercise, setChangedExercise] = useState(exercise);

  useEffect(() => {
    setChangedExercise(exercise);
  }, [exercise]);

  return (
    <MyModal
      title={t("planUpsert.editExercise")}
      open={showModal}
      onCancel={onCancel}
      okText={t("save")}
      forceRender
      cancelText={t("cancel")}
      onOk={() => {
        onDone(changedExercise);
      }}
      width={1000}
    >
      <UPExerciseEdit
        exercises={[changedExercise]}
        imageType={imageType}
        singleExercise={true}
        onChange={e => {
          setChangedExercise(e as ExerciseInPlan);
        }}
      />
    </MyModal>
  );
};

export default UPEditExerciseModal;
