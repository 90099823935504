import React, { useEffect, useState } from "react";
import { Tabs, Collapse } from "antd";
import { get } from "../../api/Request";
import { AllCats } from "../../types/Plan";
import { useTranslation } from "react-i18next";
import UPCatsMuscle from "./UPCatsMuscle";
import UPCatsDiagnosis from "./UPCatsDiagnosis";
import { ExercisesGetParam } from "../../types/PlanUpsert";
import { defaultParam } from "./UpsertPlan";

const { Panel } = Collapse;
interface Props {
  lang: string;
  onSelect: (e: ExercisesGetParam) => void;
  onTitleChange: (title: string) => void;
}

const UPCats: React.FC<Props> = ({ onSelect, onTitleChange, lang }) => {
  // console.log("loading cats COMPONENT", lang);
  const { t } = useTranslation();

  const [cats, setCats] = useState<AllCats>();

  const getCats = () => {
    get<AllCats>("plan/exerciseCats", { lang: lang })
      .then(r => {
        // console.log(r.data);
        setCats(r.data);
      })
      .catch(_ => {})
      .finally(() => {});
  };

  useEffect(() => {
    // if (cats === undefined) {
    // console.log("getting categories123");
    getCats();
    // }
  }, [lang]);

  return cats ? (
    <Tabs
      defaultActiveKey="cat"
      size="small"
      className="upsert_plan_tabs"
      items={[
        {
          label: t("planUpsert.categories"),
          key: "cat",
          children: (
            <Collapse accordion>
              <Panel
                className="no-panel-content"
                showArrow={false}
                header={
                  <div
                    onClick={() => {
                      onTitleChange(t("exercise.myExercises"));

                      onSelect({
                        ...defaultParam,
                        my_exercises: true
                      });
                    }}
                  >
                    {t("exercise.myExercises")}
                  </div>
                }
                key="myex"
              ></Panel>
              <Panel
                className="no-panel-content"
                showArrow={false}
                header={
                  <div
                    onClick={() => {
                      onTitleChange(t("exercise.favExercises"));

                      onSelect({
                        ...defaultParam,
                        my_fav_exercises: true
                      });
                    }}
                  >
                    {t("exercise.favExercises")}
                  </div>
                }
                key="myfav"
              ></Panel>
              {cats &&
                cats.cats.map(cat => (
                  <Panel header={cat.name} key={"cat" + cat.id.toString()}>
                    {cat.childs &&
                      cat.childs.map(child => (
                        <p
                          className="pointer"
                          // hidden={!child.count}
                          onClick={() => {
                            onTitleChange(cat.name + "/" + child.name);
                            onSelect({
                              ...defaultParam,
                              category_id: child.id,
                              cat_prefix: child.cat_prefix
                            });
                          }}
                          key={"catchild" + child.id.toString()}
                        >
                          {child.name} ({child.count})
                        </p>
                      ))}
                  </Panel>
                ))}
            </Collapse>
          )
        },
        {
          label: t("planUpsert.advice"),
          key: "advice",
          children: (
            <Collapse accordion>
              {cats &&
                cats.advices.map(a => (
                  <Panel
                    className="no-panel-content"
                    showArrow={false}
                    header={
                      <div
                        style={{ width: "100%" }}
                        onClick={() => {
                          onTitleChange(t("planUpsert.advice") + ": " + a.name);

                          onSelect({
                            ...defaultParam,
                            category_id: a.id
                          });
                        }}
                      >
                        {a.name}
                      </div>
                    }
                    key={"advices" + a.id.toString()}
                  ></Panel>
                ))}
            </Collapse>
          )
        },
        {
          label: t("planUpsert.diagnosis"),
          key: "diag",
          children: (
            <UPCatsDiagnosis
              diagnosis={cats.diagnosis}
              onTitleChange={onTitleChange}
              onSelect={onSelect}
            />
          )
        },
        {
          label: t("planUpsert.muscles"),
          key: "musc",
          children: (
            <UPCatsMuscle
              muscles={cats.muscles}
              onTitleChange={onTitleChange}
              onSelect={onSelect}
            />
          )
        }
      ]}
    />
  ) : (
    <></>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.lang === nextProps.lang;
};
const UPCatsMemo = React.memo(UPCats, areEqual);

export default UPCatsMemo;
