import { useAuth } from "../../context/AuthContext";
import { Licence } from "../../types/UserData";

import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserRole } from "../../types/Practitioner";

const PaymentRequiredAllowed = [
  "/choose-licence",
  "/settings/my-account",
  "/payment"
];
const PractitionerDisabledRoutes = [
  "/settings/payments",
  "/settings/edit-clinic",
  "/settings/practitioners",
  "/settings/letterhead-settings",
  "/settings/whatsapp-sms-options",
  "/settings/email-settings",
  "/settings/integrations",
  "/settings/integrations-cliniko",
  "/settings/api",
  "/settings/buy-credit"
];
const PrivateRoute = ({ redirectPath = "/login" }) => {
  const location = useLocation();
  const { user } = useAuth();
  if (user) {
    if (
      user.licence === Licence.notSet &&
      location.pathname !== "/choose-licence"
    )
      return <Navigate to="/choose-licence" />;

    if (
      user.payment_required &&
      // location.pathname !== "/payment" &&
      !PaymentRequiredAllowed.includes(location.pathname)
    )
      return <Navigate to="/payment" />;

    if (
      (user.banned || user.practitioner.banned) &&
      location.pathname !== "/account-banned"
    )
      return <Navigate to="/account-banned" />;

    if (
      user.practitioner.role === UserRole.practitioner &&
      PractitionerDisabledRoutes.includes(location.pathname)
    )
      return <Navigate to="/settings/my-account" />;
  }
  if (user) return <Outlet />;

  const redirectParam =
    location.pathname === "/"
      ? ``
      : `?redirectTo=${encodeURIComponent(location.pathname)}`;

  return <Navigate to={`${redirectPath}${redirectParam}`} replace />;
};

export default PrivateRoute;
