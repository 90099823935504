import React, { useEffect, useState } from "react";
import { PlanViewDTO } from "../../types/Plan";
import { useAuth } from "../../context/AuthContext";
import { Column } from "@ant-design/plots";
import { get } from "../../api/Request";
import { TrackingResult } from "../../types/Tracking";
import { getPatientSalutation } from "../../helpers/helper";
import { useTranslation } from "react-i18next";
import { Result } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Patient2 } from "../../types/Patient";
import ValueLabel from "../../components/layout/ValueLabel";
import Paragraph from "antd/lib/typography/Paragraph";

interface Props {
  plan: PlanViewDTO;
  patient: Patient2;
}
const PlanTrackingResult: React.FC<Props> = ({ plan, patient }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>();
  const [done, setDone] = useState<any>();
  const [liked, setLiked] = useState<any>();

  const getData = () => {
    get<TrackingResult[]>("tracking/trackingChartData/" + plan.plan.id)
      .then(r => {
        // console.log(r.data, "tracking data");
        setData(r.data);
      })
      .catch(e => {});
  };
  useEffect(() => {
    getData();
  }, [plan]);
  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      const done = data.map(d => {
        return {
          type: t("plan.exercisesDone"),
          date: d.date,
          value: d.data !== false ? d.data.done : null
        };
      });
      const not_done = data.map(d => {
        return {
          type: t("plan.exercisesNotDone"),
          date: d.date,
          value: d.data !== false ? d.data.not_done : null
        };
      });
      var doneNotDone = {
        data: [...done, ...not_done],
        isStack: true,
        xField: "date",
        yField: "value",
        seriesField: "type",
        label: {
          position: "middle", // 'top', 'bottom', 'middle'

          layout: [
            { type: "interval-adjust-position" },

            { type: "interval-hide-overlap" },

            { type: "adjust-color" }
          ]
        },

        colorField: "type", // or seriesField in some cases
        color: ({ type }) => {
          if (type === t("plan.exercisesNotDone")) {
            return "red";
          }
          return "green";
        },
        slider: {
          start: 0,
          end: done.length > 10 ? 0.5 : 1
        }
      };
      setDone(doneNotDone);

      const liked = data.map(d => {
        return {
          type: t("plan.exercisesLiked"),
          date: d.date,
          value: d.data !== false ? d.data.like : null
        };
      });
      const not_liked = data.map(d => {
        return {
          type: t("plan.exercisesNotLiked"),
          date: d.date,
          value: d.data !== false ? d.data.dislike : null
        };
      });
      var likedNotLiked = {
        data: [...liked, ...not_liked],
        isStack: true,
        xField: "date",
        yField: "value",
        seriesField: "type",
        label: {
          position: "middle", // 'top', 'bottom', 'middle'

          layout: [
            { type: "interval-adjust-position" },

            { type: "interval-hide-overlap" },

            { type: "adjust-color" }
          ]
        },
        colorField: "type", // or seriesField in some cases
        color: ({ type }) => {
          if (type === t("plan.exercisesNotLiked")) {
            return "red";
          }
          return "green";
        },
        slider: {
          start: 0,
          end: done.length > 10 ? 0.5 : 1
        }
      };
      setLiked(likedNotLiked);
    }
  }, [data]);

  return (
    <>
      {user && data !== undefined && data.length > 0 && (
        <div style={{ textAlign: "center" }}>
          <h3>{t("plan.exercisesDone")}</h3>
          {done && <Column {...done} />}
          <h3>{t("plan.exercisesLiked")}</h3>
          {liked && (
            <Column
              {...liked}
              onReady={plot => {
                plot.on("plot:click", evt => {
                  const { x, y } = evt;
                  const { xField } = plot.options;
                  const tooltipData = plot.chart.getTooltipItems({ x, y });
                  // console.log(tooltipData);
                });
              }}
            />
          )}
        </div>
      )}

      {data !== undefined && data.length === 0 && (
        <Result
          style={{
            background: "#f0f0f0",
            borderRadius: 8,
            margin: "16px"
          }}
          icon={<FontAwesomeIcon icon={faChartLine} size="4x" />}
          title={t("plan.noTrackRehabRes")}
          subTitle={t("plan.noTrackRehabRes2")}
          extra={
            <>
              <h3>{getPatientSalutation(patient)}</h3>
              <ValueLabel label={t("patient.trAccessCodeLabel")}>
                <Paragraph
                  copyable={{
                    text: "https://trackrehab.com/c/" + patient.access_code,
                    tooltips: [t("copy"), t("copied")]
                  }}
                >
                  {patient.access_code}
                </Paragraph>
              </ValueLabel>
            </>
          }
        />
      )}
    </>
  );
};

export default PlanTrackingResult;
