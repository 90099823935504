import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";
import AuthLayout from "./AuthLayout";
import MyPageHeader from "../../components/layout/MyPageHeader";

const ActivateAccount: React.FC = () => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const { code } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    post("account/activate/", { code: code })
      .then(r => {
        // console.log("res", r);
        setUser(r.data.user);
        navigate("/patients", { replace: true });
      })
      .catch(() => {});
  }, [code, navigate, setUser]);

  useEffect(() => {
    if (user?.email) navigate("/patients", { replace: true });
  }, [user, navigate]);

  return (
    <AuthLayout
      title={<MyPageHeader title={t("auth.activateAccount")}></MyPageHeader>}
    >
      <div className=""></div>
    </AuthLayout>
  );
};

export default ActivateAccount;
