import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { post } from "../../../api/Request";
const Webhook: React.FC = () => {
  const { user, setUser } = useAuth();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loadingAk, setLoadingAk] = useState(false);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        webhook_url: user.settings.webhook_url ?? ""
      });
    }
  }, [user, form]);

  return (
    <>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={v => {
          // console.log(v, "upsertkey");
          setLoadingAk(true);
          post("integrations/upsertWebhook", v)
            .then(r => {
              if (r.data.user) setUser(r.data.user);
            })
            .catch(_ => {})
            .finally(() => setLoadingAk(false));
        }}
        autoComplete="on"
      >
        <Form.Item label={t("integrations.webhookUrl")} name="webhook_url">
          <Input type="url" />
        </Form.Item>

        <Form.Item>
          <Button loading={loadingAk} type="primary" htmlType="submit" block>
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Webhook;
