import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RmpTemplateCat, UserTemplateCat } from "../../types/Plan";
import TemplatesTable from "../templates/TemplatesTable";
import { get } from "../../api/Request";
import { useAuth } from "../../context/AuthContext";
import { UserRole } from "../../types/Practitioner";
import MyModal from "../../components/MyModal";
import { Skeleton } from "antd";

interface Props {
  showModal: boolean;
  onCancel: () => void;
  onDone: (id: number) => void;
}
const LoadFromTemplateModal: React.FC<Props> = ({
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [userCats, setUserCat] = useState<UserTemplateCat[]>();
  const [rmpCats, setRmpCats] = useState<RmpTemplateCat[]>();
  const getCats = () => {
    get<UserTemplateCat[]>("template/userTemplateCats")
      .then(r => {
        setUserCat(r.data);
      })
      .catch(_ => {})
      .finally(() => {});
  };
  const getRmpCats = () => {
    get<RmpTemplateCat[]>("template/templateCats")
      .then(r => {
        setRmpCats(r.data);
        // console.log(r.data, "rmp cats");
      })
      .catch(_ => {})
      .finally(() => {});
  };

  useEffect(() => {
    // if (userCats === []) {
    // console.log("loading template cats");
    getCats();
    getRmpCats();
    // }
  }, []);

  return (
    <MyModal
      title={t("template.loadFromTemplate")}
      open={showModal}
      onCancel={onCancel}
      okButtonProps={{ style: { display: "none" } }}
      // okText={t("save")}
      forceRender
      destroyOnClose={true}
      cancelText={t("cancel")}
      width={"1000"}
      className="load-from-template-modal"
    >
      {user && rmpCats && userCats ? (
        <TemplatesTable
          initialData={{
            page: 1,
            pageSize: 10,
            sort: "created",
            sortDirection: "descend",
            templateType: "user",
            search: "",
            catId: 0,
            practitionerId:
              user.practitioner.can_view_other_patients ||
              user.practitioner.role === UserRole.owner
                ? 0
                : user.practitioner.id
          }}
          userCats={userCats}
          rmpCats={rmpCats}
          isInModal={true}
          onSelectTemplate={id => {
            onDone(id);
          }}
        />
      ) : (
        <Skeleton active />
      )}
    </MyModal>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal;
}

// export default UPExercise;
const LoadFromTemplateModalMemo = React.memo(LoadFromTemplateModal, areEqual);
export default LoadFromTemplateModalMemo;
