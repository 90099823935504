import { Checkbox, Tooltip } from "antd";
import Form from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlanMode, PlanUpsertDto } from "../../types/PlanUpsert";
import TextArea from "antd/lib/input/TextArea";
import { post } from "../../api/Request";
import UPExerciseEdit from "./UPExerciseEdit";
import MyModal from "../../components/MyModal";
import { QuestionCircleOutlined } from "@ant-design/icons";
interface Props {
  initialPlan: PlanUpsertDto;
  showModal: boolean;
  forceSaveAsNew: boolean;
  onUpdate: (plan: PlanUpsertDto) => void;
  onDone: (id: number, mode: string) => void;
  onCancel: () => void;
}

const UPContinueModal: React.FC<Props> = ({
  initialPlan,
  showModal,
  forceSaveAsNew,
  onCancel,
  onUpdate,
  onDone
}) => {
  const { t } = useTranslation();

  const [formRef] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onClose = () => {
    formRef.validateFields().then(values => {
      onUpdate(values);
      onCancel();
    });
  };
  useEffect(() => {
    formRef.setFieldsValue({
      ...initialPlan,
      save_as_new_plan: forceSaveAsNew ? true : initialPlan.save_as_new_plan
    });
  }, [initialPlan, formRef]);

  const submit = v => {
    // console.log("submit", v);
    interface Res {
      id: number;
      message: string;
    }
    setLoading(true);

    post<Res>("plan/upsert", v)
      .then(r => onDone(r.data.id, v.mode))
      .catch(e => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MyModal
      title={t("planUpsert.previewTitle")}
      open={showModal}
      onCancel={onClose}
      okText={t("save")}
      okButtonProps={{ loading: loading }}
      destroyOnClose
      forceRender
      cancelText={t("cancel")}
      onOk={() => {
        formRef
          .validateFields()
          .then(values => {
            submit({ ...values });
          })
          .catch(e => {})
          .finally(() => {});
      }}
      width={1000}
    >
      <Form
        form={formRef}
        id="patientForm"
        preserve={false}
        layout="vertical"
        onFinish={v => submit(v)}
      >
        <span hidden>
          <Form.Item name="id"></Form.Item>
          <Form.Item name="patient_id"></Form.Item>
          <Form.Item name="template_category_id"></Form.Item>
          <Form.Item name="prom_from_app"></Form.Item>
          <Form.Item name="monitor"></Form.Item>
          <Form.Item name="has_tracking"></Form.Item>
          <Form.Item name="start"></Form.Item>
          <Form.Item name="end"></Form.Item>
          <Form.Item name="lang"></Form.Item>
          <Form.Item name="duration"></Form.Item>
          <Form.Item name="name"></Form.Item>
          <Form.Item name="desc"></Form.Item>
          <Form.Item name="image_type"></Form.Item>
          <Form.Item name="mode"></Form.Item>
        </span>
        <Form.Item name="exercises">
          <UPExerciseEdit
            exercises={initialPlan.exercises}
            imageType={initialPlan.image_type}
            singleExercise={false}
            onChange={e => {
              // console.log(e, "newExercise2332");
            }}
          />
        </Form.Item>
        <Form.Item
          name="notes"
          rules={[
            {
              required: true
            }
          ]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 25 }} />
        </Form.Item>
        {initialPlan.id > 0 && initialPlan.mode === PlanMode.plan && (
          <Form.Item name="save_as_new_plan" valuePropName="checked">
            <Checkbox disabled={forceSaveAsNew}>
              {t("planUpsert.saveAsNewPlan")}
            </Checkbox>
          </Form.Item>
        )}
        {initialPlan.id > 0 && initialPlan.mode === PlanMode.template && (
          <Form.Item name="save_as_new_plan" valuePropName="checked">
            <Checkbox>{t("planUpsert.saveAsNewTemplate")}</Checkbox>
          </Form.Item>
        )}
      </Form>
    </MyModal>
  );
};

export default UPContinueModal;
