import React, { useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";

import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { useGlobalContext } from "../../context/GlobalContext";
import { formatDate } from "../../helpers/dateHelper";
import { CustomExerciseDescription } from "../../types/ExerciseT";
import CustomExerciseDescriptionModal from "./CustomExerciseDescriptionModal";
import { Button, Result, Space, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChildReaching,
  faEdit,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import Search from "antd/lib/input/Search";
import ConfirmButton from "../../components/helper/ConfirmButton";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const CustomExerciseDescriptions: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { Text } = Typography;

  const { t } = useTranslation();
  const { language } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [descriptions, setDescriptions] =
    useState<CustomExerciseDescription[]>();
  const [exercise, setExercise] = useState<CustomExerciseDescription>();
  const [showEditDescriptionModal, setShowEditDescriptionModal] =
    useState(false);

  const getDescriptions = () => {
    setLoading(true);
    get<CustomExerciseDescription[]>("exercise/getCustomExercises/")
      .then(r => {
        setDescriptions(r.data);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDescriptions();
  }, []);

  const columns: ColumnsType<CustomExerciseDescription> = [
    {
      title: t("created"),
      dataIndex: "last_update",
      key: "created",
      className: "pointer",
      sorter: (a, b) => a.last_update.localeCompare(b.last_update),
      render: date => <Text type="secondary">{formatDate(date, language)}</Text>
    },
    {
      title: t("name"),
      key: "name",
      className: "pointer",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) =>
        record.title_edited.length ? record.title_edited : record.title
    },
    {
      key: "actions",
      className: "pointer",
      render: (text, record) => (
        <Space.Compact style={{float:"right"}}>
          <Button
            key="etr"
            // className=""
            // className="green-btn"
            // type="primary"
            title={t("edit")}
            onClick={e => {
              setExercise(record);
              setShowEditDescriptionModal(true);
            }}
            size="middle"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <ConfirmButton
            btnText={<FontAwesomeIcon icon={faTrashAlt} />}
            message={t("confirmations.delete")}
            btnProps={{ block: true, size: "middle", danger: true }}
            onConfirm={() => {
              post("exercise/deleteCustomDescription", {
                exercise_id: record.exercise_id,
                lang: record.lang
              })
                .then(() => {
                  getDescriptions();
                })
                .catch(_ => {});
            }}
          />
        </Space.Compact>
      )
    }
  ];

  useEffect(() => {
    // console.log(user);
  }, [user, navigate]);

  return (
    <>
      {descriptions && descriptions.length > 0 && (
        <>
          <Search
            key="search"
            // className="table-search vertical-margin"
            // style={{ width: "50% " }}
            // ref={searchRef}
            placeholder={t("search")}
            onChange={e => {
              setSearch(e.target.value);
            }}
            enterButton={<FontAwesomeIcon icon={faSearch} />}
            loading={loading}
            // value={search}
            allowClear
          />
          <Table
            pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
            dataSource={
              search === ""
                ? descriptions
                : descriptions.filter(
                    entry =>
                      String(entry.title_edited)
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      String(entry.desc_edited)
                        .toLowerCase()
                        .includes(search.toLowerCase())
                  )
            }
            columns={columns}
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  setExercise(record);
                  setShowEditDescriptionModal(true);
                },
                onDoubleClick: event => {}, // double click row
                onContextMenu: event => {}, // right button click row
                onMouseEnter: event => {}, // mouse enter row
                onMouseLeave: event => {} // mouse leave row
              };
            }}
          />
        </>
      )}
      {descriptions && descriptions.length === 0 && (
        <Result
          icon={<FontAwesomeIcon icon={faChildReaching} size="4x" />}
          title={t("settings.noCustomExerciseDescriptions")}
          subTitle={t("settings.noCustomExerciseDescriptionsMsg")}
        />
      )}

      {exercise && (
        <CustomExerciseDescriptionModal
          showModal={showEditDescriptionModal}
          exercise={exercise}
          onCancel={() => {
            setShowEditDescriptionModal(false);
          }}
          onDone={() => {
            setShowEditDescriptionModal(false);
            getDescriptions();
          }}
        />
      )}
    </>
  );
};

export default CustomExerciseDescriptions;
