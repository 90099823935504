import React, { useEffect, useState } from "react";
import { Form, Input, Button, QRCode, Skeleton, Alert } from "antd";
import { useAuth } from "../../context/AuthContext";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";

import TextContent from "../TextContent";
import { useGlobalContext } from "../../context/GlobalContext";

const MFA: React.FC = () => {
  const { user, setUser } = useAuth();
  const { isTabletOrMobile } = useGlobalContext();
  const [usr, setUsr] = useState(user);
  const [secret, setSecret] = useState();
  const [uri, setUri] = useState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUsr(user);
    // Any additional logic when user changes can be added here
  }, [user]);

  const onFinish = values => {
    setLoading(true);
    post("/auth/enableMfaStep2", {
      code: values.totp_code,
      secret: secret
    })
      .then(r => {
        setUser(r.data);
        setSecret(undefined);
        setUri(undefined);
      })
      .catch(error => {
        console.error("There was an error verifying the TOTP code!", error);
      })
      .finally(() => setLoading(false));
  };

  const handleEnableMFA = () => {
    setLoading(true);
    post("auth/enableMfaStep1")
      .then(r => {
        setSecret(r.data.secret);
        setUri(r.data.uri);
        window.scrollTo(0, 0);
        // window.location.reload();
      })
      .catch(error => {
        console.error("Error enabling MFA", error);
      })
      .finally(() => setLoading(false));
  };

  const handleDisableMFA = () => {
    setLoading(true);
    post("auth/disableMfa")
      .then(r => {
        setUser(r.data);
        // navigate("/settings/mfa");
      })
      .catch(error => {
        console.error("Error disabling MFA", error);
      })
      .finally(() => setLoading(false));
  };

  if (!usr) {
    return <Skeleton active />;
  }

  if (secret && uri) {
    return (
      <>
        <h2>{t("step")} 1</h2>
        {t("mfa.step1")}
        <br />
        <QRCode value={uri} bgColor="#fff" className="margin-top-16" />
        {isTabletOrMobile && (
          <>
            <Alert type="info" message={t("mfa.step12")} />
            <Paragraph
              className="margin-top-16"
              copyable={{
                text: secret,
                tooltips: [t("copy"), t("copied")]
              }}
            >
              {secret}
            </Paragraph>
          </>
        )}
        <h2 className="margin-top-16">{t("step")} 2</h2>

        {t("mfa.step2")}
        <Form className="margin-top-16" layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="totp_code"
            label={t("mfa.otp")}
            rules={[{ required: true, message: t("mfa.hint1") }]}
          >
            <Input
              type="number"
              step="1"
              placeholder={t("mfa.pleaseInputOtp")}
            />
          </Form.Item>
          <Form.Item name="secret" hidden>
            <Input value={secret} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              {t("mfa.enableMfa")}
            </Button>
          </Form.Item>
        </Form>
        <Button
          htmlType="button"
          loading={loading}
          block
          onClick={_ => {
            setSecret(undefined);
            setUri(undefined);
          }}
        >
          {t("cancel")}
        </Button>
      </>
    );
  }

  return (
    <>
      {usr && usr.practitioner.mfa_enabled === true ? (
        <>
          <Alert type="success" message={t("mfa.mfaEnabled")} />
          <Button
            className="margin-top-16"
            loading={loading}
            type="primary"
            block
            onClick={handleDisableMFA}
          >
            {t("mfa.disableMfa")}
          </Button>
        </>
      ) : (
        <>
          <TextContent id={539} />
          <Button
            loading={loading}
            type="primary"
            block
            onClick={handleEnableMFA}
          >
            {t("mfa.enableMfa")}
          </Button>
        </>
      )}
    </>
  );
};

export default MFA;
