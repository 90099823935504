import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { useAuth } from "../../context/AuthContext";
import { get } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { useGlobalContext } from "../../context/GlobalContext";
import { PaymentLogTable } from "../../types/Payment";
import Table, { ColumnsType } from "antd/lib/table";
import { formatDate } from "../../helpers/dateHelper";
import { config } from "../../data/Constants";
import { Status } from "../../types/UserData";
import { UserRole } from "../../types/Practitioner";
import { Link } from "react-router-dom";

const Payments: React.FC = () => {
  const { user } = useAuth();
  const { Text } = Typography;
  const { t } = useTranslation();
  const { language } = useGlobalContext();
  const [payments, setPayments] = useState<PaymentLogTable[]>([]);

  const getData = () => {
    get<PaymentLogTable[]>("payment/accountPayments")
      .then(r => {
        // console.log(r.data, "p data");

        setPayments(r.data);
        // setPagination({ ...pagination, total: r.data.total_items });
      })
      .catch(_ => {})
      .finally(() => {});
  };

  useEffect(() => {
    getData();
  }, [user]);

  const columns: ColumnsType<PaymentLogTable> = [
    {
      key: "date",
      title: t("date"),
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: text => <Text type="secondary">{formatDate(text, language)}</Text>
    },
    {
      key: "type",
      title: t("payments.type"),
      render: (_, r) => <span className={r.type_css_class}>{r.type}</span>
    },
    {
      key: "name",
      title: "",
      dataIndex: "product_name"
    },
    {
      key: "amount",
      title: t("payments.amount"),
      render: (_, r) => <>{r.amount ? r.amount + r.currency : ""}</>
    },
    {
      key: "refunded",
      title: t("payments.refunded"),
      render: (_, r) => (
        <>{r.amount_refunded ? r.amount_refunded + r.currency : ""}</>
      )
    },
    {
      key: "status",
      title: t("payments.status"),
      dataIndex: "status",
      render: (_, r) => <>{r.payment_status}</>
    },
    {
      key: "actions",
      title: "",
      render: (_, r) =>
        r.amount ? (
          <a
            href={config.API_URL + "payment/downloadReceipt/" + r.id}
            download
            style={{ float: "right" }}
          >
            <Button type="primary" size="middle">
              {t("payments.viewReceipt")}
            </Button>
          </a>
        ) : (
          <></>
        )
    }
  ];
  // getData();
  return (
    <>
      <Table
        scroll={{ x: 800 }}
        pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
        columns={columns}
        dataSource={payments}
      />
      {user &&
        user.status === Status.STATUS_STRIPE_REC &&
        user.practitioner.role !== UserRole.practitioner && (
          <Link to="/go-to-stripe" key="gts" style={{ display: "block" }}>
            {t("account.updateCard")}
          </Link>
        )}
    </>
  );
};

export default Payments;
