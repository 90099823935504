import React, { useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";

import { get } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { useGlobalContext } from "../../context/GlobalContext";
import { formatDate, formatTime } from "../../helpers/dateHelper";
import Paragraph from "antd/lib/typography/Paragraph";

import { Typography, Button, Input, Result, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChild,
  faRightToBracket,
  faSearch,
  faUser,
  faVideoCamera
} from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
import ConfirmButton from "../../components/helper/ConfirmButton";
import { Meeting, MeetingUpsert } from "../../types/Telerehab";
import ScheduleMeetingModal from "./ScheduleMeetingModal";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import Pract from "../../components/helper/Pract";
import { config } from "../../data/Constants";
import { Dialog, List, SwipeAction } from "antd-mobile";
import { useAuth } from "../../context/AuthContext";
interface Props {
  patientId?: number;
  preselectedPatient?: number[];
  newMeeting?: number;
  // onCancel: () => void;
}

const TelerehabTable: React.FC<Props> = ({
  patientId,
  newMeeting,
  preselectedPatient
}) => {
  const { Search } = Input;
  const { Text } = Typography;
  const { t } = useTranslation();
  const { language, isTabletOrMobile } = useGlobalContext();

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [search, setSearch] = useState("");
  const { user } = useAuth();

  const newMeetingData: MeetingUpsert = {
    id: 0,
    patients: preselectedPatient
  };
  const [formData, setFormData] = useState<MeetingUpsert>(newMeetingData);
  const [meetings, setMeetings] = useState<Meeting[]>();

  const [showScheduleMeeting, setShowScheduleMeeting] = useState(false);

  useEffect(() => {
    if (newMeeting !== undefined) {
      if (patientId) {
        setFormData(newMeetingData);
        setShowScheduleMeeting(true);
      }
    }
  }, [newMeeting]);

  useEffect(() => {
    // console.log(preselectedPatient, "pres pat");
  }, [preselectedPatient]);

  const getAll = () => {
    setLoading(true);
    const url =
      patientId === undefined ? "getAll" : "patientMeetings/" + patientId;
    get<Meeting[]>("telerehab/" + url)
      .then(r => {
        // console.log(r.data, "getting tr data");
        setMeetings(r.data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAll();
  }, [user]);

  useEffect(() => {
    // setSourceFiltered(
    //   source.filter(
    //     entry =>
    //       String(entry.title).toLowerCase().includes(search.toLowerCase()) ||
    //       String(entry.desc).toLowerCase().includes(search.toLowerCase())
    //   )
    // );
  }, [search]);

  const columns: ColumnsType<Meeting> = [
    {
      title: t("date"),
      dataIndex: "date_time",
      key: "date",
      className: "pointer",
      sorter: (a, b) => a.date_time.localeCompare(b.date_time),
      render: date => (
        <Text type="secondary">{formatDate(date, language)}</Text>
      ),
      responsive: ["md"]
    },
    {
      title: t("time"),
      dataIndex: "date_time",
      key: "time",
      className: "pointer",
      sorter: (a, b) => a.date_time.localeCompare(b.date_time),
      render: date => <Text type="secondary">{formatTime(date)}</Text>,
      responsive: ["md"]
    },

    {
      title: t("patient.patients"),
      key: "name",
      className: "pointer",
      // sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => (
        <>
          <Text className="tmain_col">
            {record.patients.map(p => (
              <div key={p.id}>
                {p.first_name} {p.last_name}
              </div>
            ))}
          </Text>
        </>
      )

      // {record.patients.map(p=>{(<>{p.first_name}</>)})}
    },
    {
      title: t("practitioner"),
      key: "desc",
      className: "pointer",
      sorter: (a, b) =>
        a.practitioner.first_name.localeCompare(b.practitioner.first_name),
      // render: (text, record) => (
      //   <div className="pract-table">
      //     {record.practitioner.first_name} {record.practitioner.last_name}
      //   </div>
      // )
      render: (_, record) => <Pract id={record.practitioner.id} />
    },
    {
      title: t("telerehab.meetingUrl"),
      key: "desc",
      className: "pointer",
      render: (text, record) => (
        <Paragraph
          copyable={{
            text: config.TELEREHAB_URL + record.code,
            tooltips: [t("copy"), t("copied")]
          }}
        >
          {record.code}
        </Paragraph>
      )
    },
    {
      key: "actions",
      className: "pointer",
      render: (text, record) => (
        <Space.Compact style={{ float: "right" }}>
          <Button
            key="vtr"
            // className=""
            className="green-btn"
            type="default"
            title={t("telerehab.join")}
            onClick={e => {
              window.open(config.TELEREHAB_URL + record.code, "_blank");
              // setMeeting(record);
              // if (tab === "advices") setShowUpsertAdviceModal(true);
              // else setShowUpsertExeriseModal(true);
              e.stopPropagation();
            }}
            size="middle"
          >
            <FontAwesomeIcon icon={faRightToBracket} />
          </Button>

          <Button
            key="etr"
            // className=""
            // className="green-btn"
            // type="primary"
            title={t("edit")}
            onClick={e => {
              setFormData({
                id: record.id,
                date_time: record.date_time,
                patients: record.patients.map(p => {
                  return p.id;
                }),
                reminder: record.reminder,
                code: record.code,
                record_id: record.record_id
              });

              setShowScheduleMeeting(true);
              // setMeeting(record);
              // if (tab === "advices") setShowUpsertAdviceModal(true);
              // else setShowUpsertExeriseModal(true);
              e.stopPropagation();
            }}
            size="middle"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <ConfirmButton
            btnText={t("delete")}
            message={t("confirmations.delete")}
            btnProps={{ block: true, size: "middle", danger: true }}
            onConfirm={() => {
              get("telerehab/deleteMeeting/" + record.id)
                .then(() => {
                  getAll();
                })
                .catch(_ => {});
            }}
          />
        </Space.Compact>
      )
    }
  ];

  return (
    <>
      {meetings && (
        <>
          {meetings.length > 0 && (
            <>
              <Search
                key="search"
                // className="table-search vertical-margin"
                // style={{ width: "50% " }}
                placeholder={t("search")}
                onChange={debounce(e => {
                  // console.log(e);
                  setSearch(e.target.value);
                }, 300)}
                enterButton={<FontAwesomeIcon icon={faSearch} />}
                loading={loading}
                // value={search}
                allowClear
              />
              <Table
                dataSource={meetings}
                columns={columns}
                pagination={{
                  position: ["bottomCenter"],
                  hideOnSinglePage: true
                }}
                rowKey="id"
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {
                      setFormData({
                        id: record.id,
                        date_time: record.date_time,
                        patients2: record.patients.map(p => {
                          return {
                            value: p.id,
                            label: p.first_name + " " + p.last_name
                          };
                        }),
                        reminder: record.reminder,
                        code: record.code,
                        record_id: record.record_id
                      });

                      setStep(2);
                      setShowScheduleMeeting(true);
                    },

                    onDoubleClick: event => {}, // double click row
                    onContextMenu: event => {}, // right button click row
                    onMouseEnter: event => {}, // mouse enter row
                    onMouseLeave: event => {} // mouse leave row
                  };
                }}
              />
            </>
          )}

          {isTabletOrMobile && false && meetings !== undefined && (
            <List>
              {meetings!.map(m => (
                <SwipeAction
                  // ref={ref}
                  key={m.id}
                  closeOnAction={true}
                  closeOnTouchOutside={false}
                  rightActions={[
                    {
                      key: "delete",
                      text: t("delete"),
                      color: "danger",
                      onClick: e => {
                        Dialog.confirm({
                          content: t("confirmations.delete"),
                          confirmText: t("delete"),
                          cancelText: t("cancel"),
                          onConfirm: () => {
                            // delete(m.id);
                          }
                        });
                      }
                    },
                    {
                      key: "edit",
                      text: t("edit"),
                      onClick: e => {}
                    }
                  ]}
                >
                  <List.Item
                    key={m.id}
                    clickable
                    onClick={() => {
                      window.open(config.TELEREHAB_URL + m.code, "_blank");
                    }}
                    arrow={
                      <>
                        <Text type="secondary">
                          {formatDate(m.date_time, language)}
                        </Text>{" "}
                        <FontAwesomeIcon icon={faChevronRight} />
                      </>
                    }
                    prefix={
                      <FontAwesomeIcon icon={faUser} className="prefix-icon" />
                    }
                    description={
                      <Text className="tmain_col">
                        {m.patients.map(p => (
                          <div key={p.id}>
                            {p.first_name} {p.last_name}
                          </div>
                        ))}
                      </Text>
                    }
                  >
                    {formatTime(m.date_time)}
                  </List.Item>
                </SwipeAction>
              ))}
            </List>
          )}

          {meetings && meetings.length === 0 && (
            <Result
              icon={<FontAwesomeIcon icon={faVideoCamera} size="4x" />}
              title={t("telerehab.noMeetings")}
              subTitle={t("telerehab.noMeetings2")}
              extra={
                <Button
                  className="green-btn"
                  type="primary"
                  onClick={() => {
                    setFormData(newMeetingData);
                    setShowScheduleMeeting(true);
                  }}
                >
                  <FontAwesomeIcon icon={faChild} />
                  {t("telerehab.scheduleNew")}
                </Button>
              }
            />
          )}
        </>
      )}
      {showScheduleMeeting && (
        <ScheduleMeetingModal
          upsertData={formData}
          showModal={showScheduleMeeting}
          initialStep={step}
          onCancel={() => {
            setShowScheduleMeeting(false);
            getAll();
          }}
          onDone={() => {
            setShowScheduleMeeting(false);
            getAll();
          }}
        />
      )}
    </>
  );
};

export default TelerehabTable;
