import React, { useState } from "react";
import { Button, Col, Row, Input, Form } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { post } from "../../api/Request";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { useAuth } from "../../context/AuthContext";
import { useMediaQuery } from "react-responsive";
import { ApiResWithUser } from "../../types/General";
const UpdatePassword: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const onFinish = values => {
    setLoading(true);
    post<ApiResWithUser>("auth/updatePassword", values)
      .then(r => {
        if (r.data.user) setUser(r.data.user);
        navigate("/patients");
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   // console.log(user, "Use auth context");
  // }, [user]);
  return (
    <div className="site-card-wrapper">
      {isTabletOrMobile && (
        <MyPageHeader title={t("settings.changePassword")}></MyPageHeader>
      )}
      <Row gutter={16}>
        <Col xs={{ span: 24, offset: 0 }} xl={{ span: 10, offset: 7 }}>
          {!isTabletOrMobile && (
            <MyPageHeader title={t("settings.changePassword")}></MyPageHeader>
          )}
          <div className="box">
            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                email: user?.practitioner.email,
                first_name: user?.practitioner.first_name,
                last_name: user?.practitioner.last_name
              }}
              onFinish={onFinish}
              autoComplete="on"
            >
              <Form.Item
                label={t("auth.newPassword")}
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t("auth.repeatNewPassword")}
                name="password_c"
                rules={[{ required: true }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  {t("save")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpdatePassword;
