import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, Typography } from "antd";
import { useAuth } from "../../context/AuthContext";
import { get, post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { useGlobalContext } from "../../context/GlobalContext";
import Table, { ColumnsType } from "antd/lib/table";
import { ApiKey } from "../../types/UserData";
import { formatDate } from "../../helpers/dateHelper";
import ConfirmButton from "../../components/helper/ConfirmButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paragraph from "antd/lib/typography/Paragraph";
import MyDivider from "../../components/helper/MyDivider";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const Api: React.FC = () => {
  const { user } = useAuth();
  const { Text } = Typography;
  const { t } = useTranslation();
  const { language } = useGlobalContext();
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [loading, setLoading] = useState(false);

  const generateApiKey = values => {
    setLoading(true);
    post("account/generateApiKey", values)
      .then(r => {
        // if (r.data.user) setUser(r.data.user);
        getData();
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };
  const getData = () => {
    get<ApiKey[]>("account/apiKeys")
      .then(r => {
        // console.log(r.data, "api data");

        setApiKeys(r.data);
      })
      .catch(_ => {})
      .finally(() => {});
  };

  useEffect(() => {
    getData();
  }, [user]);

  const columns: ColumnsType<ApiKey> = [
    {
      key: "date",
      title: t("date"),
      dataIndex: "date_created",
      render: text => (
        <Text type="secondary">
          {formatDate(new Date(text * 1000).toDateString(), language)}
        </Text>
      )
    },
    {
      key: "name",
      title: t("name"),
      dataIndex: "name"
    },
    {
      key: "type",
      title: t("settings.apiKey"),
      render: (_, r) => (
        <Paragraph
          style={{ overflowX: "hidden" }}
          type="secondary"
          copyable={{ text: r.key, tooltips: [t("copy"), t("copied")] }}
        >
          {r.key}
        </Paragraph>
      )
    },

    {
      key: "actions",
      render: (_, record) => (
        <>
          <ConfirmButton
            btnText={<FontAwesomeIcon icon={faTrashAlt} />}
            modalBtnText={t("delete")}
            message={t("confirmations.delete")}
            btnProps={{ block: true, size: "middle", danger: true }}
            onConfirm={() => {
              get("account/deleteApiKey/" + record.id)
                .then(() => {
                  getData();
                })
                .catch(_ => {});
            }}
          />
        </>
      )
    }
  ];
  // getData();
  return (
    <>
      <MyDivider title={t("settings.generateApiKey")} />

      <Form layout="vertical" onFinish={generateApiKey}>
        <Form.Item name="name" rules={[{ required: true }]} label={t("name")}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button block loading={loading} type="primary" htmlType="submit">
            {t("settings.generateApiKey")}
          </Button>
        </Form.Item>
      </Form>
      <Alert message={t("info")} description={t("settings.apiInfo")} />
      {apiKeys.length > 0 && (
        <>
          <MyDivider title={t("settings.api")} />

          <Table columns={columns} dataSource={apiKeys} />
        </>
      )}
    </>
  );
};

export default Api;
