import React from "react";
import { useAuth } from "../../context/AuthContext";
import { Language, LanguageNames } from "../../types/General";
import { useTranslation } from "react-i18next";

interface Props {
  lang: Language;
}
const LangPrint: React.FC<Props> = ({ lang }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  if (user?.lang !== lang) {
    return <span className="lang-badge">{LanguageNames[lang]}</span>;
  }
  return <></>;
};

export default LangPrint;
