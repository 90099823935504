import React, { useEffect, useState, useCallback } from "react";
import { get, post } from "../../api/Request";
import { useTranslation } from "react-i18next";
import { DragDropContext } from "react-beautiful-dnd";
import TemplateList from "./TemplateList";
import ClinicalNoteTemplateModal from "./ClinicalNoteTemplateModal";
import {
  ClinicalNoteTemplate,
  ClinicalNoteTemplateUpsert
} from "../../types/Patient";
import { Button, Result } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNotesMedical } from "@fortawesome/free-solid-svg-icons";

// Utility function to reorder items in the same list
const reorder = (
  list: ClinicalNoteTemplate[],
  startIndex: number,
  endIndex: number
): ClinicalNoteTemplate[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Utility function to move items between lists
const move = (
  source: ClinicalNoteTemplate[],
  destination: ClinicalNoteTemplate[],
  droppableSource,
  droppableDestination
): {
  [key: string]: ClinicalNoteTemplate[];
} => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  // Update category of the moved item (if necessary)
  removed.category = droppableDestination.droppableId;

  destClone.splice(droppableDestination.index, 0, removed);

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone
  };
};

const ClinicalNoteTemplates: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [quickTemplates, setQuickTemplates] = useState<ClinicalNoteTemplate[]>(
    []
  );
  const [rfTemplates, setRfTemplates] = useState<ClinicalNoteTemplate[]>([]);
  const [otherTemplates, setOtherTemplates] = useState<ClinicalNoteTemplate[]>(
    []
  );
  const [drag, setDrag] = useState(0);
  const [showImportDefault, setShowImportDefault] = useState(false);
  const [template, setTemplate] = useState<ClinicalNoteTemplateUpsert>();
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);

  const getTemplates = () => {
    setLoading(true);
    get<ClinicalNoteTemplate[]>("note/getTemplates/")
      .then(r => {
        if (r.data.length === 0) setShowImportDefault(true);
        setQuickTemplates(
          r.data.filter(template => template.category === "quick")
        );
        setRfTemplates(r.data.filter(template => template.category === "rf"));
        setOtherTemplates(
          r.data.filter(template => template.category === "template")
        );
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (drag === 0) return;
    handleSaveReorder();
  }, [drag]);

  const handleEditTemplate = (template: ClinicalNoteTemplateUpsert) => {
    setTemplate(template);
    setShowEditTemplateModal(true);
  };
  const handleAddTemplate = (category: "quick" | "rf" | "template") => {
    setTemplate({ id: 0, name: "", template: "", category: category, sort: 0 });
    setShowEditTemplateModal(true);
  };

  const handleDeleteTemplate = (id: number) => {
    // Call API to delete template
    post(`/note/deleteTemplate/${id}`, {}).then(() => getTemplates());
  };

  const handleSaveReorder = () => {
    const reorderedTemplates = [
      ...quickTemplates.map((template, index) => ({
        id: template.id,
        sort: index + 1,
        category: "quick"
      })),
      ...rfTemplates.map((template, index) => ({
        id: template.id,
        sort: index + 1,
        category: "rf"
      })),
      ...otherTemplates.map((template, index) => ({
        id: template.id,
        sort: index + 1,
        category: "template"
      }))
    ];

    post("note/reorderTemplates", reorderedTemplates)
      .then(() => {
        // Optionally fetch updated templates after saving order
        getTemplates();
      })
      .catch(err => {
        console.error("Failed to save template order", err);
      });
  };

  const handleDragEnd = useCallback(
    result => {
      const { source, destination } = result;

      if (!destination) {
        return; // If dropped outside the list, do nothing
      }

      if (source.droppableId === destination.droppableId) {
        // Reordering within the same list
        const reordered = reorder(
          source.droppableId === "quickTemplates"
            ? quickTemplates
            : source.droppableId === "rfTemplates"
            ? rfTemplates
            : otherTemplates,
          source.index,
          destination.index
        );
        source.droppableId === "quickTemplates"
          ? setQuickTemplates(reordered)
          : source.droppableId === "rfTemplates"
          ? setRfTemplates(reordered)
          : setOtherTemplates(reordered);
      } else {
        // Moving between lists
        const result = move(
          source.droppableId === "quickTemplates"
            ? quickTemplates
            : source.droppableId === "rfTemplates"
            ? rfTemplates
            : otherTemplates,
          destination.droppableId === "quickTemplates"
            ? quickTemplates
            : destination.droppableId === "rfTemplates"
            ? rfTemplates
            : otherTemplates,
          source,
          destination
        );

        setQuickTemplates(result.quickTemplates || quickTemplates);
        setRfTemplates(result.rfTemplates || rfTemplates);
        setOtherTemplates(result.otherTemplates || otherTemplates);
      }

      setDrag(drag + 1);
    },
    [quickTemplates, rfTemplates, otherTemplates]
  );

  return (
    <>
      {showImportDefault && (
        <Result
          icon={<FontAwesomeIcon icon={faNotesMedical} size="4x" />}
          title={t("template.nothingFound")}
          subTitle={t("cn.importTemplatesQ")}
          extra={[
            <Button
              type="primary"
              key="import"
              onClick={() => {
                post("note/importDefaultTemplates", {})
                  .then(() => {
                    setShowImportDefault(false);
                    getTemplates();
                  })
                  .catch(() => {});
              }}
            >
              {t("import")}
            </Button>
          ]}
        />
      )}
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="template-container">
          {/* Quick Templates Column */}
          <TemplateList
            templates={quickTemplates}
            title={t("cn.quick")}
            droppableId="quickTemplates"
            onEdit={handleEditTemplate}
            onDelete={handleDeleteTemplate}
          />

          {/* RF Templates Column */}
          <TemplateList
            templates={rfTemplates}
            title={t("cn.rf")}
            droppableId="rfTemplates"
            onEdit={handleEditTemplate}
            onDelete={handleDeleteTemplate}
          />

          {/* Other Templates Column */}
          <TemplateList
            templates={otherTemplates}
            title={t("cn.tmplts")}
            droppableId="otherTemplates"
            onEdit={handleEditTemplate}
            onDelete={handleDeleteTemplate}
          />
        </div>
      </DragDropContext>
      <Button
        type="primary"
        block
        onClick={() => {
          setTemplate({
            id: 0,
            name: "",
            template: "",
            // lang: user.practitioner.lang,
            category: "template",
            sort: 0
          });
          setShowEditTemplateModal(true);
        }}
        style={{ marginTop: 16 }}
      >
        {t("cn.addTemplate")}
      </Button>

      {template && (
        <ClinicalNoteTemplateModal
          showModal={showEditTemplateModal}
          template={template}
          onCancel={() => setShowEditTemplateModal(false)}
          onDone={() => {
            setShowEditTemplateModal(false);
            getTemplates();
          }}
        />
      )}
    </>
  );
};

export default ClinicalNoteTemplates;
