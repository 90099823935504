import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "../../api/Request";
import "react-quill/dist/quill.snow.css";
import { History } from "../../types/Patient";
import { formatDateTime } from "../../helpers/dateHelper";
import { useAuth } from "../../context/AuthContext";
import { useGlobalContext } from "../../context/GlobalContext";
import {
  getMessageStatusColor,
  getPractitionerName
} from "../../helpers/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import MyModal from "../../components/MyModal";
const { Text } = Typography;
interface Props {
  patientId: number;
  showModal: boolean;
  onCancel: () => void;
}

const PatientHistoryModal: React.FC<Props> = ({
  patientId,
  showModal,
  onCancel
}) => {
  const { t } = useTranslation();

  const { user } = useAuth();
  const { language } = useGlobalContext();

  const [history, setHistory] = useState<History[]>();

  useEffect(() => {
    showModal &&
      get<History[]>("patient/history/" + patientId)
        .then(r => {
          setHistory(r.data);
          // console.log(r.data, "history data");
        })
        .catch(_ => {});
  }, [patientId, showModal]);

  const columns: ColumnsType<History> = [
    {
      title: t("action"),
      key: "name",
      sorter: false,
      render: (text, record) => (
        <div>
          <Text type="success">
            {t("patientHistory." + record.action, {
              object: record.object ?? " -- "
            })}
          </Text>
          <br />
          <Text type="secondary">
            {getPractitionerName(record.practitioner_id, user)}
          </Text>
          {record.delivery_log && record.delivery_log.length > 0 && (
            <>
              {record.delivery_log.map(dl => (
                <div style={{ fontSize: "0.9em", marginLeft: 16 }}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    color={getMessageStatusColor(dl.status)}
                  />{" "}
                  {t("messageStatus.ms" + dl.status)}{" "}
                  <Text type="secondary">
                    {formatDateTime(dl.date, language)}
                  </Text>
                </div>
              ))}
            </>
          )}
        </div>
      )
    },

    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      className: "pointer",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: date => (
        <span className="tsec_col">{formatDateTime(date, language)}</span>
      )
    }
  ];

  return (
    <MyModal
      title={t("patient.patientHistory")}
      open={showModal}
      onCancel={onCancel}
      destroyOnClose
      okText={t("ok")}
      onOk={() => {
        onCancel();
      }}
      okButtonProps={{ style: { display: "none" } }}
      cancelText={t("close")}
    >
      <>
        <Table
          pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
          dataSource={history}
          columns={columns}
          rowKey="id"
          onRow={(record, rowIndex) => {
            return {
              // onClick: event => {
              //   navigate("/patient/plan/" + record.id);
              // },
              onDoubleClick: event => {}, // double click row
              onContextMenu: event => {}, // right button click row
              onMouseEnter: event => {}, // mouse enter row
              onMouseLeave: event => {} // mouse leave row
            };
          }}
        />
      </>
    </MyModal>
  );
};

export default PatientHistoryModal;
