import { Input, Select, Form, Upload } from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { get, post } from "../../api/Request";
import { ApiRes } from "../../types/General";
import { MyAdviceUpsert } from "../../types/MyExercises";
import { AllCats } from "../../types/Plan";
import { InboxOutlined } from "@ant-design/icons";
import MyModal from "../../components/MyModal";
const { TextArea } = Input;

interface Props {
  exercise: MyAdviceUpsert;
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

interface Cat {
  name: string;
  value: number;
}
const { Option } = Select;

const UpsertAdviceModal: React.FC<Props> = ({
  exercise,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [cats, setCats] = useState<AllCats>();
  const [subcats, setSubcats] = useState<Cat[]>([]);
  const [subcatValue, setSubcatValue] = useState<null | number>(null);

  const [file, setFile] = useState<any>();
  const [defaultFileList, setDefaultFileList] = useState([]);

  const [loading, setLoading] = useState(false);

  const getCats = () => {
    get<AllCats>("plan/exerciseCats")
      .then(r => {
        setCats(r.data);
      })
      .catch(_ => {})
      .finally(() => {});
  };

  const getSubcats = v => {
    let subcats = cats?.cats
      .find(c => c.id === v)
      ?.childs?.map(c => {
        return { name: c.name, value: c.id };
      });
    if (subcats?.length !== undefined) {
      setSubcats(subcats);
      if (exercise.parent_category_id === v && exercise.category_id !== null)
        setSubcatValue(exercise.category_id);
      else {
        form.setFieldsValue({
          ...form.getFieldsValue,
          category_id: null
        });
      }
    } else setSubcats([]);
  };

  useEffect(() => {
    form.setFieldsValue(exercise);
    getSubcats(exercise.parent_category_id);
    setSubcatValue(exercise.category_id);
    setDefaultFileList([]);
    setFile(false);
  }, [form, exercise]);

  useEffect(() => {
    getCats();
  }, []);
  useEffect(() => {
    // console.log("l", loading);
  }, [loading]);

  const handleOnChange = ({ fileList }) => {
    setDefaultFileList(fileList);
  };

  const dummyRequest = (file, onSuccess) => {
    if (file) {
      setFile(file);
    }
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const submit = v => {
    setLoading(true);
    const formData = new FormData();
    for (var key in v) {
      formData.append(key, v[key]);
    }
    if (file) formData.append("user_pdf", file);
    post<ApiRes>("exercise/upsertMyAdvice", formData, {
      headers: { "content-type": "multipart/form-data" }
    })
      .then(r => {
        onDone();
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  return (
    <MyModal
      title={exercise.id > 0 ? t("advice.edit") : t("advice.add")}
      open={showModal}
      destroyOnClose={true}
      confirmLoading={true}
      onCancel={() => {
        setDefaultFileList([]);
        onCancel();
      }}
      okText={t("save")}
      forceRender
      okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        // setLoading(true);
        form
          .validateFields()
          .then(values => {
            submit(values);
          })
          .catch(info => {})
          .finally(() => {});
      }}
    >
        {exercise && form && (
          <Form
            form={form}
            id="exerciseForm"
            layout="vertical"
            preserve={false}
            autoComplete="off"
            initialValues={exercise}
          >
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item hidden name="lang">
              <Input />
            </Form.Item>

            <Form.Item
              label={t("myExercises.title")}
              name="title"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="desc" label={t("myExercises.description")}>
              <TextArea
                maxLength={3500}
                autoSize={{ minRows: 3, maxRows: 25 }}
              />
            </Form.Item>

            <Form.Item
              label={t("myExercises.category")}
              name="parent_category_id"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={v => {
                  // console.log(v);
                  getSubcats(v);
                }}
              >
                {cats?.cats?.map(c => (
                  <Option key={c.id.toString()} value={c.id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              hidden={subcats.length === 0}
              label={t("myExercises.subcategory")}
              name="category_id"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                showSearch
                value={subcatValue}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {subcats.map(c => (
                  <Option key={c.value.toString()} value={c.value}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={t("advice.pdf")}>
              <Form.Item
                // getValueFromEvent={normFile}
                name="user_pdf"
                valuePropName="user_pdf"
                noStyle
                rules={[
                  {
                    required: exercise.id > 0 ? false : true
                  }
                ]}
              >
                <Upload.Dragger
                  accept=".pdf, .PDF"
                  multiple={false}
                  maxCount={1}
                  fileList={defaultFileList}
                  onChange={e => {
                    handleOnChange({ ...e });
                  }}
                  listType="picture"
                  customRequest={({ file, onSuccess }) =>
                    dummyRequest(file, onSuccess)
                  }
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined rev={undefined} />
                  </p>
                  <p className="ant-upload-text">
                    {t("myExercises.imageDraggerText")}
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          </Form>
        )}
    </MyModal>
  );
};

export default UpsertAdviceModal;
