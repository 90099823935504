import React from "react";
import { Button, Drawer, Modal, ModalProps, Space } from "antd";
import { useGlobalContext } from "../context/GlobalContext";
import { t } from "i18next";

interface CustomModalProps extends ModalProps {
  // Add any additional custom props if needed
  onCancel: () => void;
}

const MyModal: React.FC<CustomModalProps> = props => {
  const { isTabletOrMobile } = useGlobalContext();

  // const [visible, setVisible] = useState(props.open);
  // useEffect(() => {
  //   setVisible(props.open);
  // }, [props]);

  // isTabletOrMobile && (props.centered = true);
  return isTabletOrMobile ? (
    <Drawer
      title={props.title}
      style={{ overflowX: "hidden" } as any}
      open={props.open}
      className={props.className}
      destroyOnClose={true}
      width={500}
      onClose={() => {
        props.onCancel(); // Trigger callback if provided
      }} // Call the new function when drawer is closed
      footer={
        <Space>
          {props.onOk && (
            <Button
              onClick={props.onOk}
              type="primary"
              {...props.okButtonProps}
            >
              {props.okText}
            </Button>
          )}

          <Button onClick={props.onCancel} {...props.cancelButtonProps}>
            {props.cancelText ?? t("cancel")}
          </Button>
        </Space>
      }
    >
      {props.children}
    </Drawer>
  ) : (
    <Modal
      centered={true}
      {...props} // Spread all the props
    >
      {props.children}
    </Modal>
  );
};

export default MyModal;
