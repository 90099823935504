import React, { useState } from "react";
import { Collapse, Input } from "antd";
import { Diagnosis } from "../../types/Plan";
import { useTranslation } from "react-i18next";
import { defaultParam } from "./UpsertPlan";
import { ExercisesGetParam } from "../../types/PlanUpsert";

const { Panel } = Collapse;
const { Search } = Input;
interface Props {
  diagnosis: Diagnosis[];
  onSelect: (e: ExercisesGetParam) => void;
  onTitleChange: (title: string) => void;
}

const UPCatsDiagnosis: React.FC<Props> = ({
  diagnosis,
  onSelect,
  onTitleChange
}) => {
  const { t } = useTranslation();

  // console.log("UPC diagnosis rendering");

  const [diagnosisSearch, setDiagnosisSearch] = useState("");

  return (
    <>
      <Search
        allowClear
        onChange={e => {
          setDiagnosisSearch(e.target.value);
        }}
        style={{ width: "100%" }}
      />
      <Collapse accordion>
        {diagnosis
          .filter(diag =>
            diag.name
              .toLocaleLowerCase()
              .includes(diagnosisSearch.toLocaleLowerCase())
          )
          .map(d => (
            <Panel
              className="no-panel-content"
              showArrow={false}
              header={
                <div
                  style={{ width: "100%" }}
                  onClick={() => {
                    onTitleChange(t("planUpsert.diagnosis") + ": " + d.name);

                    onSelect({
                      ...defaultParam,
                      diagnosis_id: d.id
                    });
                  }}
                >
                  {d.name}
                </div>
              }
              key={"diag" + d.id.toString()}
            ></Panel>
          ))}
      </Collapse>
    </>
  );
};

export default React.memo(UPCatsDiagnosis);
