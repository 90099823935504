import { Input, Select, Form, Alert } from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TemplateCat } from "../../types/Plan";
import { get, post } from "../../api/Request";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../context/AuthContext";
import MyModal from "../../components/MyModal";
const { TextArea } = Input;

interface Props {
  templateId: number;
  name?: string;
  desc?: string;
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

interface FormProps {
  id: number;
  name: string;
  desc: string;
}

const ShareToCommunity: React.FC<Props> = ({
  templateId,
  name,
  desc,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const { user } = useAuth();

  const [form] = Form.useForm();

  const [cats, setCats] = useState<TemplateCat[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && templateId && showModal)
      get<TemplateCat[]>("template/templateCats/" + templateId)
        .then(r => {
          // console.log(r.data, "rcats");
          setCats(r.data);
        })
        .catch(_ => {});
  }, [templateId, user, showModal]);

  const submit = (v: FormProps) => {
    setLoading(true);

    post("template/addToCommunityTemplate", v)
      .then(r => {
        onDone();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MyModal
      title={t("template.shareToCommunityTemplates")}
      open={showModal}
      onCancel={onCancel}
      okText={t("share")}
      okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            submit(values);
          })
          .catch(info => {
            // console.log("Validate Failed:", info);
          })
          .finally(() => {});
      }}
    >
        <Alert
          className="margin-bottom"
          message={t("template.shareToCommunityInfo")}
          type="info"
          closable
        />

        <Form
          form={form}
          id="smsForm"
          layout="vertical"
          autoComplete="off"
          initialValues={{
            id: templateId,
            name: name ?? "",
            desc: desc ?? ""
          }}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label={t("name")}
            name="name"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="desc"
            label={t("description")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <TextArea autoSize={{ minRows: 3, maxRows: 25 }} />
          </Form.Item>
          <Form.Item
            name="category"
            label={t("category")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select style={{ width: "100%" }}>
              <Option value="">{t("none")}</Option>
              {cats.map(c => (
                <Option value={c.id}>{c.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
    </MyModal>
  );
};

export default ShareToCommunity;
