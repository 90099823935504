import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import { Language } from "../../types/General";
import LanguageSelect from "../../components/LanguageSelect";
interface Props {
  title?: React.ReactNode;
  children: React.ReactNode;
}
const AuthLayout: React.FC<Props> = ({ children, title }) => {
  const { setLanguage, language, isTabletOrMobile } = useGlobalContext();
  const [langUrl, setLangUrl] = useState<Language>();
  const currentURL = window.location.href;
  useEffect(() => {
    if (currentURL.includes("loc.")) setLangUrl(Language.plPl);
  }, [window.location.href]);

  return (
    <>
      {isTabletOrMobile && title}
      <Row
        justify="center"
        // justify="space-around"
        align="middle"
        className="auth-layout"
      >
        <Col id="auth-layout-left">
          <Row justify="center" align="middle">
            <Col className="auth-col" id="auth_logo">
              <div>
                <img className="auth_logo" src="/assets/logo.png" alt="" />

                <LanguageSelect
                  className="margin-vertical"
                  defaultValue={language.toString()}
                  style={{ width: 220 }}
                  onChange={v => setLanguage(v as Language)}
                  size="small"
                />
              </div>
            </Col>
            <Col
              className="auth-col"
              id="auth-layout-right"
              // className="padding-vertical padding-horizontal"
            >
              {!isTabletOrMobile && title}

              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AuthLayout;
