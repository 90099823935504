import React, { useState } from "react";

import { Button, Descriptions, Modal } from "antd";
import { useGlobalContext } from "../../context/GlobalContext";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { formatDate, formatDateTime } from "../../helpers/dateHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { Licence, Status } from "../../types/UserData";
import LanguageSelect from "../../components/LanguageSelect";
import { post } from "../../api/Request";
import { ApiResWithUser } from "../../types/General";
import { config } from "../../data/Constants";
import { UserRole } from "../../types/Practitioner";
import LevelIcon from "../../components/helper/LevelIcon";

const MyAccount: React.FC = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useGlobalContext();

  const [selectedLang, setSelectedLang] = useState(user?.lang);
  // Function to handle the select option change
  const handleLangChange = value => {
    // Display confirmation modal if a different option is selected
    if (user?.lang !== value) {
      Modal.confirm({
        title: "Confirm",
        content: <>{t("account.changeLangMsg")}</>,
        onOk() {
          post<ApiResWithUser>("account/changeAccountLang", {
            lang: value
          }).then(r => {
            if (r.data.user) setUser(r.data.user);
            setSelectedLang(value);
          });
        },
        onCancel() {
          // If the user cancels, reset the select to the previous option
          // setSelectedOption(selectedOption);
        }
      });
    }
  };
  return (
    <>
      {user && (
        <Descriptions
          bordered
          column={{ xxl: 1, xl: 1, lg: 1, md: 2, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label={t("account.status")}>
            {t("account.statuses.st" + user.status)}
            {user.should_pay === true && (
              <Link to="/payment" key="payment" style={{ display: "block" }}>
                <FontAwesomeIcon icon={faPaypal} />{" "}
                {t("payment.startSubscription")}
              </Link>
            )}
          </Descriptions.Item>
          {false && (
            <Descriptions.Item label={t("language")}>
              {/* <Form.Item name="lang" v noStyle> */}
              <LanguageSelect
                onChange={handleLangChange}
                // defaultValue={user.lang}
                value={selectedLang?.toString()}
                style={{ width: "100%" }}
              />
              {/* </Form.Item> */}
            </Descriptions.Item>
          )}

          <Descriptions.Item label={t("account.licence")}>
            {t("account.licences.l" + user.licence)}
            {user.status === Status.STATUS_TRIAL &&
              [UserRole.admin, UserRole.owner].includes(
                user.practitioner.role
              ) && (
                <Link
                  to="/choose-licence"
                  key="cl"
                  style={{ display: "block" }}
                >
                  {t("account.changeLicence")}
                </Link>
              )}
          </Descriptions.Item>
          <Descriptions.Item label={t("pract.roleTitle")}>
            {t("pract.role.rl" + user.practitioner.role)}
            <LevelIcon level={user.practitioner.level} />
            {/* <LevelIcon level={1} />
            <LevelIcon level={2} />
            <LevelIcon level={3} />
            <LevelIcon level={4} />
            <LevelIcon level={5} />
            <LevelIcon level={6} />
            <LevelIcon level={7} />
            <LevelIcon level={8} />
            <LevelIcon level={9} />
            <LevelIcon level={10} />
            <LevelIcon level={11} />
            <LevelIcon level={12} /> */}
          </Descriptions.Item>
          {user.clinics_allowed > 1 && user.licence === Licence.multiClinic && (
            <Descriptions.Item label={t("account.allowedClinicNumber")}>
              {user.current_clinics.length} ({t("account.outOf")}{" "}
              {user.clinics_allowed})
            </Descriptions.Item>
          )}
          <Descriptions.Item label={t("account.allowedPractitionerNumber")}>
            {user.allowed_practitioners_num}
          </Descriptions.Item>
          <Descriptions.Item label={t("account.expireDate")}>
            {formatDateTime(user.expire_date, language)}
          </Descriptions.Item>
          <Descriptions.Item label={t("account.memberSince")}>
            {formatDate(user.created, language)}
          </Descriptions.Item>
          <Descriptions.Item label={t("account.whatsappSmsCredit")}>
            {user.credit_balance} {"  "}
            <Link to="/settings/buy-credit">{t("payments.buyCredit")}</Link>
          </Descriptions.Item>
          {user.status === Status.STATUS_STRIPE_REC &&
            user.practitioner.role !== UserRole.practitioner && (
              <Descriptions.Item label={t("account.yourCard")}>
                <Link to="/go-to-stripe" key="cl2" style={{ display: "block" }}>
                  {t("account.updateCard")}
                </Link>
              </Descriptions.Item>
            )}

          <Descriptions.Item label="">v{config.VERSION}</Descriptions.Item>
          {user.practitioner.role === UserRole.practitioner && false && (
            <Descriptions.Item label="Want to create your own account?">
              <Link to="/settings/create-personal-account">
                Start 14 days free trial
              </Link>
            </Descriptions.Item>
          )}
        </Descriptions>
      )}

      <Button block onClick={() => navigate("/logout", { replace: true })}>
        {t("logout")}
      </Button>
    </>
  );
};

export default MyAccount;
