import { Button, notification } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";

const SysNotifications: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  // Function to add a number to the 'closedNotifications' array in local storage
  function addToClosedNotifications(number: number): void {
    // Get the 'closedNotifications' array from local storage
    const storedData = localStorage.getItem("closedNotifications");
    let closedNotifications: number[] = [];

    if (storedData) {
      // Parse the stored data if it exists
      closedNotifications = JSON.parse(storedData);
    }

    // Add the number to the array
    closedNotifications.push(number);

    // Store the updated array back in local storage
    localStorage.setItem(
      "closedNotifications",
      JSON.stringify(closedNotifications)
    );
  }

  // Function to check if a number is in the 'closedNotifications' array in local storage
  function isNotificationClosed(number: number): boolean {
    // Get the 'closedNotifications' array from local storage
    const storedData = localStorage.getItem("closedNotifications");
    let closedNotifications: number[] = [];

    if (storedData) {
      // Parse the stored data if it exists
      closedNotifications = JSON.parse(storedData);
    }

    // Check if the number exists in the array
    return closedNotifications.includes(number);
  }

  type NotificationType = "success" | "info" | "warning" | "error";

  // Function to display notifications and check if they are closed
  function displayNotifications(notifications) {
    notifications.forEach(notificationItem  => {
      const { id, msg } = notificationItem;
      const notificationKey = `notification_${id}`; // Create a unique key for each notification

      if (!isNotificationClosed(id)) {
        // Notification is not closed, display it
        notification[notificationItem.type as NotificationType]({
          key: notificationKey, // Set a unique key for the notification
          message: "",
          description: <div dangerouslySetInnerHTML={{ __html: msg }} />,
          placement: "bottomRight",
          duration: 0, // Set duration to 0 for manual closure
          onClose: () => {
            // Mark the notification as closed when it's manually closed
            addToClosedNotifications(id);
          },
          btn: (
            <Button
              type="dashed"
              size="middle"
              onClick={() => {
                notification.destroy(notificationKey);
                addToClosedNotifications(id);
              }}
            >
              {t("close")}
            </Button>
          )
        });
      }
    });
  }

  useEffect(() => {
    if (user && user.sys_notifications.notifications.length > 0) {
      displayNotifications(user.sys_notifications.notifications);
    }
  }, [user]);

  return <></>;
};

export default SysNotifications;
