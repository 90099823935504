import React from "react";

const Logo: React.FC = () => {
  return (
    <a href="/patients">
      <img className="main_logo" src="/assets/logo.png" alt="" />
    </a>
  );
};

export default Logo;
