import React from "react";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { LanguageNames } from "../types/General";

const { Option } = Select;
const languageOptions = Object.entries(LanguageNames);

interface LanguageSelectProps extends SelectProps<string> {
  // You can add any additional props specific to the LanguageSelect component here
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({ ...restProps }) => {
  return (
    <Select
      {...restProps}
      showSearch
      filterOption={(input, option) =>
        option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      options={languageOptions.map(([langCode, langName]) => ({
        value: langCode,
        label: langName
      }))}
    ></Select>
  );
};

export default LanguageSelect;
