import { Form, Select } from "antd";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { post } from "../../api/Request";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../context/AuthContext";
import MyModal from "../../components/MyModal";
const { Option } = Select;

interface Props {
  patient?: {
    patientId: number;
    memberId: number;
  };
  showModal: boolean;
  onCancel: () => void;
  onDone: () => void;
}

const TransferPatientModal: React.FC<Props> = ({
  patient,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();

  const [newClinicId, setNewClinicId] = useState<number>(0);
  const { user } = useAuth();

  function handleChange(value) {
    setNewClinicId(value);
  }

  return (
    <MyModal
      title={t("patient.transferToAnotherClinic")}
      open={showModal}
      onCancel={onCancel}
      onOk={() => {
        post("patient/transfer", {
          id: patient?.patientId,
          clinic: newClinicId
        })
          .then(r => {
            onDone();
          })
          .catch(_ => {});
      }}
      destroyOnClose
      okText={t("patient.transfer")}
    >
      <>
        {user && user.clinics.length > 1 && (
          <Form layout="vertical">
            <Form.Item label={t("account.chooseClinic")}>
              <Select
                style={{ width: "100%" }}
                onChange={handleChange}
                showSearch
                optionFilterProp="children"
                filterOption={true}
                key="1"
              >
                {user.clinics
                  ?.filter(c => c.member_id === patient?.memberId)
                  .map(c => (
                    <Option value={c.id} key={c.id}>
                      {c.clinic_name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </>
    </MyModal>
  );
};

export default TransferPatientModal;
