import { Input, Steps, Select, Form, DatePicker, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get, post } from "../../api/Request";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Meeting, MeetingUpsert } from "../../types/Telerehab";
import ValueLabel from "../../components/layout/ValueLabel";
import { formatDateTime } from "../../helpers/dateHelper";
import { useGlobalContext } from "../../context/GlobalContext";
import Paragraph from "antd/lib/typography/Paragraph";
import Link from "antd/lib/typography/Link";
import { config } from "../../data/Constants";
import { Language } from "../../types/General";
import MyModal from "../../components/MyModal";
import dayjs from "dayjs";
import PatientSelect3 from "../../components/PatientSelect3";

const { Step } = Steps;

interface Props {
  upsertData: MeetingUpsert;
  initialStep?: number;
  showModal: boolean;
  onDone: (id: number) => void;
  onCancel: () => void;
}

const ScheduleMeetingModal: React.FC<Props> = ({
  upsertData,
  showModal,
  initialStep,
  onCancel,
  onDone
}) => {
  // console.log("schedule meeting loaded");
  const { t } = useTranslation();
  const { Option } = Select;

  const [form] = Form.useForm();
  const [invitationForm] = Form.useForm();
  const { language } = useGlobalContext();

  const [meetingData, setMeetingData] = useState<MeetingUpsert>();
  const [invitationMsg, setInvitationMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  // const [stepState, setStepState] = useState<typeof Status[]>([
  //   "waiting",
  //   "waiting",
  //   "waiting"
  // ]);

  useEffect(() => {
    if (upsertData.id > 0) setCurrentStep(2);
    else setCurrentStep(0);

    setMeetingData(upsertData);
  }, [upsertData]);

  useEffect(() => {
    if (meetingData !== undefined) {
      form.setFieldsValue({
        ...meetingData,
        date_time: meetingData.date_time
          ? dayjs(meetingData.date_time)
          : undefined
      });
      if (meetingData.id > 0)
        get<string>("telerehab/invitationEmailContent/" + meetingData.id)
          .then(r => {
            setInvitationMsg(r.data);
          })
          .catch(_ => {});
    }
  }, [meetingData, form]);

  const dateFormat =
    language === Language.enUS ? "MMM DD YYYY HH:mm" : "DD MMM YYYY HH:mm";

  useEffect(() => {
    // console.log(meetingData, currentStep, "meeting data");
    // if (currentStep === 1 && meetingData.id > 0)
  }, [meetingData, currentStep]);

  const submit = () => {
    form
      .validateFields()
      .then(v => {
        setLoading(true);

        // console.log(v, "submit fields");
        interface Res {
          id: number;
          message: string;
          meeting: Meeting;
        }
        post<Res>("telerehab/saveMeeting/", {
          ...v,
          date_time: dayjs(v.date_time, "YYYY-MM-D HH:mm:ss")
        })
          .then(r => {
            setMeetingData({
              ...meetingData,
              ...r.data.meeting,
              patients2: v.patients2,
              patients: r.data.meeting.patients.map(p => {
                return p.id;
              })
            });

            setCurrentStep(1);
            // onDone(r.data.id);
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(info => {
        // console.log("Validate Failed:", info);
      })
      .finally(() => {});
    // return;
  };

  const sendInvitation = () => {
    setLoading(true);

    invitationForm
      .validateFields()
      .then(v => {
        // console.log(v, "submit invitation fields");
        interface Res {
          id: number;
          message: string;
          meeting: Meeting;
        }
        post<Res>("telerehab/sendInvitation/", v)
          .then(r => {
            setCurrentStep(2);
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(info => {
        // console.log("Validate Failed:", info);
      })
      .finally(() => setLoading(false));
    // return;
  };

  const onStepChange = current => {
    // console.log("ostep change", current);
    if (current !== undefined) return setCurrentStep(current);
    // if (current === 1 && meetingData.id > 0) {
    // }
    // console.log("onStepChange:", current);
  };

  return (
    <MyModal
      title={t("telerehab.scheduleMeeting")}
      open={showModal}
      destroyOnClose
      onCancel={() => {
        onCancel();
      }}
      footer={null}
      okButtonProps={{ hidden: true }}
      // cancelText={t("close")}
      onOk={() => {}}
    >
      {meetingData !== undefined && (
        <>
          <Steps
            current={currentStep}
            onChange={onStepChange}
            style={{ marginBottom: 16 }}
          >
            {/* {steps.map(item => (
          <Step key={item.key} title={item.title} status="wait" />
        ))} */}

            <Step
              key={0}
              title={
                upsertData.id === 0
                  ? t("telerehab.createMeeting")
                  : t("telerehab.editMeeting")
              }
            />
            <Step
              key={1}
              title={t("telerehab.sendInvitation")}
              disabled={meetingData.id === 0}
              // status={meetingData.id ? "process" : "wait"}
            />
            <Step key={2} title={t("view")} disabled={meetingData.id === 0} />
          </Steps>
          {/* <div className="steps-content">{steps[currentStep].content}</div> */}
          <div className="steps-content">
            {currentStep === 0 && (
              <Form
                form={form}
                id="customEmailForm"
                initialValues={meetingData}
                layout="vertical"
                preserve={false}
                autoComplete="off"
              >
                <Form.Item hidden name="id">
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("telerehab.selectParticipants")}
                  name="patients"
                >
                  <PatientSelect3
                    isMulti={true}
                    // value={meetingData.patients2}
                    onChange={_ => {}}
                  />
                </Form.Item>
                {/* <Form.Item hidden name="patients">
                  <Input />
                </Form.Item> */}
                <Form.Item
                  label={t("telerehab.selectDateTime")}
                  name="date_time"
                  valuePropName="date_time"
                  rules={[
                    {
                      required: true
                      //   message: t("form.fieldRequired", { field: t("firstName") })
                    }
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    // inputReadOnly={true}
                    defaultValue={
                      upsertData.date_time
                        ? dayjs(upsertData.date_time)
                        : undefined
                    }
                    format={dateFormat}
                    minuteStep={5}
                    // showSecond={false}
                    showTime={{ defaultValue: dayjs("12:00", "HH:mm") }}

                    // onChange={e => setPlan({ ...plan, start: e?.format("YYYY-MM-DD") })}
                  />
                </Form.Item>

                <Form.Item
                  name="reminder"
                  label={t("telerehab.reminder")}
                  initialValue=""
                >
                  <Select style={{ width: "100%" }}>
                    <Option value="">{t("none")}</Option>
                    <Option value="10 minute">
                      10 {t("telerehab.minutesBeforeMeeting")}
                    </Option>
                    <Option value="30 minute">
                      30 {t("telerehab.minutesBeforeMeeting")}
                    </Option>
                    <Option value="1 hour">
                      1 {t("telerehab.hourBeforeMeeting")}
                    </Option>
                  </Select>
                </Form.Item>
                <Button
                  hidden={currentStep !== 0}
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    submit();
                  }}
                >
                  {t("save")}
                </Button>
                <Button
                  style={{ float: "right" }}
                  loading={loading}
                  hidden={meetingData.id === 0}
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                >
                  {t("telerehab.sendInvitation")}
                </Button>
              </Form>
            )}
            {currentStep === 1 && invitationMsg && (
              <Form
                form={invitationForm}
                id="customEmailForm"
                initialValues={meetingData}
                layout="vertical"
                preserve={false}
                autoComplete="off"
              >
                <Form.Item hidden name="id">
                  <Input />
                </Form.Item>

                <Form.Item
                  name="content"
                  initialValue={invitationMsg.replace(/\n/g, "<br />")}
                  label={t("plan.emailBody")}
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <ReactQuill theme="snow" modules={{ toolbar: false }} />
                </Form.Item>
                <Form.Item hidden name="patients">
                  <Input />
                </Form.Item>
                <Button
                  onClick={() => {
                    setCurrentStep(0);
                  }}
                >
                  {t("telerehab.editMeeting")}
                </Button>
                <Button
                  hidden={meetingData.id === 0}
                  type="primary"
                  loading={loading}
                  style={{ float: "right" }}
                  onClick={() => {
                    sendInvitation();
                  }}
                >
                  {t("telerehab.sendInvitation")}
                </Button>
              </Form>
            )}
            {currentStep === 2 && meetingData && (
              <>
                <ValueLabel label={t("date")}>
                  {formatDateTime(meetingData.date_time ?? "", language)}
                </ValueLabel>
                <ValueLabel label={t("patient.patients")}>
                  {meetingData.patients2
                    ?.map(p => {
                      return p.label;
                    })
                    .join(", ")}
                </ValueLabel>
                <ValueLabel label={t("telerehab.meetingUrl")}>
                  <Link
                    href={config.TELEREHAB_URL + meetingData.code}
                    target="_blank"
                  >
                    <Paragraph
                      copyable={{
                        text: config.TELEREHAB_URL + meetingData.code,
                        tooltips: [t("copy"), t("copied")]
                      }}
                    >
                      {config.TELEREHAB_URL + meetingData.code}
                    </Paragraph>
                  </Link>
                </ValueLabel>
              </>
            )}
          </div>
        </>
      )}
    </MyModal>
  );
};

export default ScheduleMeetingModal;
