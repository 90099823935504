import React from "react";
import { Select } from "antd";
import { get } from "../../api/Request";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { UserObject } from "../../types/UserData";
import { SelectProps } from "antd/lib/select";

const { Option } = Select;

interface ChooseClinicProps extends SelectProps {
  // You can add any additional props specific to the LanguageSelect component here
}

const ChooseClinicSelect: React.FC<ChooseClinicProps> = ({ ...rest }) => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const chooseClinic = (id: number) => {
    get<UserObject>("account/changeClinic/" + id)
      .then(r => {
        setUser(r.data);
        const regex = /\/patient\/\d+/; // Regular expression to match "patient/" followed by one or more digits (\d+)
        if (regex.test(window.location.pathname)) {
          // if we change clinic on patient profile page redirect to patients
          return navigate("/patients", { replace: true });
        }
        if (window.location.pathname === "/account-banned") {
          // maybe other account are not banned
          return navigate("/patients", { replace: true });
        }
      })
      .catch(_ => {});
  };

  return (
    <Select
      onChange={chooseClinic}
      showSearch
      value={user ? user.clinic.id : 0}
      optionFilterProp="children"
      filterOption={true}
      {...rest}
    >
      {user &&
        user.clinics?.map(c => (
          <Option value={c.id} key={c.id}>
            {c.clinic_name}
          </Option>
        ))}
    </Select>
  );
};

export default ChooseClinicSelect;
