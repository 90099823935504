import { Typography } from "antd";
import React from "react";

interface Props {
  label: string;
  align?: "default" | "inline";
  noMargin?: boolean;
  children?: React.ReactNode;
}
const ValueLabel: React.FC<Props> = ({ label, children, ...props }) => {
  const { Text } = Typography;
  if (props.align === "inline")
    return (
      <div>
        <Text type="secondary">{label}:</Text>
        <Text>{children ? children : "--"}</Text>
      </div>
    );
  return (
    <div style={{ marginBottom: props.noMargin === true ? 0 : 16 }}>
      <Text style={{ fontWeight: "bold" }}>{label}</Text> <br />
      <Text type="secondary">{children ? children : "--"}</Text>
    </div>
  );
};

export default ValueLabel;
