import React, { useState } from "react";
import { Col, Row, Button } from "antd";
import { useTranslation } from "react-i18next";

import { get } from "../../api/Request";
import { useAuth } from "../../context/AuthContext";
import { ApiResWithUser } from "../../types/General";
interface TranslateExercisesProps {
  onRefresh: () => void;
}
const TranslateExercises: React.FC<TranslateExercisesProps> = ({
  onRefresh
}) => {
  const { t } = useTranslation();

  const { user, setUser } = useAuth();
  const [translateLoading, setTranslateLoading] = useState(false);
  const fixMissingTranslations = () => {
    setTranslateLoading(true);
    get<ApiResWithUser>("exercise/fixMissingTranslations/")
      .then(r => {
        if (r.data.user) {
          setUser(r.data.user);
        }
        onRefresh();
        // console.log("done fixing", r.data);
      })
      .catch(_ => {})
      .finally(() => setTranslateLoading(false));
  };

  return user?.missing_translations && user.missing_translations > 0 ? (
    <Row className="box margin-bottom">
      <Col>
        <h2>{t("myExercises.missingTranslations")}</h2>
        <p>{t("myExercises.missingTranslationsInfo")}</p>
        <Button
          type="primary"
          onClick={fixMissingTranslations}
          loading={translateLoading}
        >
          {t("myExercises.translateMissingTranslationsBtn")}
        </Button>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default TranslateExercises;
