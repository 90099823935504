import { ImageType } from "./Plan";

export interface ExerciseInList {
  id: number;
  category_id: number;
  code: string;
  title: string;
  advice_sheet_pdf: string;
  advice_sheet_link?: string;
  desc: string;
  video: string;
  owner_id: number;
  lineart: string;
  lineart_thumbnail: string;
  image: string;
  image_thumbnail: string;
  last_update: string;
  fh: number;
  custom_desc: boolean;
  custom_exercise: number;
}

export interface Filter {
  type: string;
  value: string;
}

export interface Pagination {
  current: string;
  next: string;
}

export interface ExercisesList {
  exercises: ExerciseInList[];
  has_more: boolean;
  total_items: number;
  items_per_page: number;
  total_pages: number;
  current_page: number;
  filter?: Filter;
  pagination?: Pagination;
  lang: string;
}

export interface ExerciseInPlan {
  id: number;
  desc: string;
  title: string;
  video?: string;
  image: string;
  image_thumbnail: string;
  lineart?: string;
  lineart_thumbnail?: string;
  time?: string;
  repetitions?: string;
  sets?: string;
  rest?: string;
  days_per_week?: string;
  bothsides?: boolean;
  advice_sheet_pdf?: string;
}

export enum PlanMode {
  plan = "plan",
  template = "template",
  rmp_template = "rmp_template"
}
export interface PlanUpsertDto {
  id: number;
  mode: PlanMode;
  patient_id?: number;
  name?: string;
  desc?: string;
  notes?: string;
  image_type: ImageType;
  save_as_new_plan?: boolean;
  start?: string;
  end?: string;
  template_category_id?: number;
  duration?: number;
  has_tracking: boolean;
  exercises: ExerciseInPlan[];
  prom?: SingleProm[];
  monitor?: number[];
  lang: string;
}

export interface SingleProm {
  id: number;
  // schedule: "start_end" | "start" | "end";
  schedule: string;
}
export interface SinglePromEdit extends SingleProm {
  index: number;
}
// plan: {
//   id: 0,
//   patient_id: 1,
//   name: "",
//   desc: "",
//   notes: "",
//   image_type: false,
//   attach_diary_on_pdf: 1,
//   save_as_new_plan: false,
//   start: new Date().toISOString(),
//   duration: 4,
//   has_tracking: false
// },
// exercises: [],
// monitor: [],
// prom: [],
// mode: "plan"

export interface ExercisesGetParam {
  category_id: number;
  cat_prefix?: string;
  muscle_id: number;
  diagnosis_id: number;
  my_exercises: boolean;
  my_fav_exercises: boolean;
  search: string;
  page: number;
  // lang: string;
}
