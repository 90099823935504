import React, { useEffect, useState, useRef, useMemo } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import type { SelectProps } from "antd/es/select";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string, page: number) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 200,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [options, setOptions] = useState<ValueType[]>([]);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState<number>();
  const fetchRef = useRef(0);

  // Function to fetch new options
  const fetchNewOptions = async (search: string, currentPage: number) => {
    setFetching(true);
    const newOptions = await fetchOptions(search, currentPage);
    setOptions(currentPage === 1 ? newOptions : [...options, ...newOptions]);
    setFetching(false);
  };

  // Effect for fetching options
  useEffect(() => {
    if (page !== undefined) fetchNewOptions("", page);
  }, [page]);

  // Debounced fetcher for options
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      setPage(1);
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      fetchNewOptions(value, 1).then(() => {
        if (fetchId !== fetchRef.current) {
          return;
        }
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout]);

  // Handle the infinite scroll functionality
  const handleInfiniteScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const isScrolledToEnd =
      target.scrollTop + target.offsetHeight === target.scrollHeight;

    if (isScrolledToEnd && page) {
      setPage(page + 1);
    }
  };

  return (
    <Select
      labelInValue
      allowClear
      filterOption={false}
      onDropdownVisibleChange={open => {
        if (open && page === undefined) {
          setPage(1);
        }
      }}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      onPopupScroll={handleInfiniteScroll}
      options={options}
      loading={fetching} // Added loader indicator
      {...props}
    />
  );
}

export default DebounceSelect;
