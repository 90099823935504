import React, { useState } from "react";
import { Col, Row, List, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserObject } from "../../types/UserData";

import { get } from "../../api/Request";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { useAuth } from "../../context/AuthContext";
import { useMediaQuery } from "react-responsive";
const { Search } = Input;
const { Text } = Typography;

const ChooseClinic: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [filteredClinics, setFilteredClinics] = useState(user?.clinics);
  const chooseClinic = (id: number) => {
    get<UserObject>("account/changeClinic/" + id)
      .then(r => {
        // console.log("done changing", r.data);
        setUser(r.data);
        navigate("/patients", { replace: true });
      })
      .catch(_ => {});
  };

  function onSearch(val: string) {
    if (val === "") setFilteredClinics(user?.clinics);
    else
      setFilteredClinics(
        user?.clinics.filter(
          c => c.clinic_name.toLowerCase().indexOf(val.toLowerCase()) !== -1
        )
      );
    // console.log("search:", val);
  }
  // useEffect(() => {
  //   // console.log(user, "Use auth context");
  // }, [user]);
  return (
    <div className="site-card-wrapper">
      {isTabletOrMobile && (
        <MyPageHeader title={t("account.chooseClinic")}></MyPageHeader>
      )}
      <Row>
        <Col xs={{ span: 24, offset: 0 }} xl={{ span: 10, offset: 7 }}>
          {!isTabletOrMobile && (
            <MyPageHeader title={t("account.chooseClinic")}></MyPageHeader>
          )}
          <List
            className="choose-clinic"
            size="large"
            header={
              <Search
                placeholder={t("startTyping")}
                allowClear
                onChange={v => onSearch(v.target.value)}
                // style={{ width: 200 }}
              />
            }
            // footer={<div>Footer</div>}
            bordered
            dataSource={filteredClinics}
            renderItem={clinic => (
              <List.Item
                onClick={() => chooseClinic(clinic.id)}
                className="pointer"
              >
                <Text className="tmain_col">{clinic.clinic_name}</Text>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChooseClinic;
