import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { Button, Col, List, Row, Select, Skeleton, Space } from "antd";
import { get } from "../../api/Request";
import MyDivider from "../../components/helper/MyDivider";
import ValueLabel from "../../components/layout/ValueLabel";
const { Option } = Select;

type Prescriber = {
  practitioner_id: number;
  practitioner_name: string;
  total_plans: number;
};
type Result = {
  fromDate: string;
  toDate: string;
  period: string;
  practitioners: Prescriber[];
};

const TopPrescribers: React.FC = () => {
  // console.log("load component templates");

  const [topPrescribers, setTopPrescribers] = useState<Result>();
  const { t } = useTranslation();
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState("30");
  const [previous, setPrevious] = useState(0);

  useEffect(() => {
    setLoading(true);
    get("account/topPrescribers", {
      period,
      previous
    })
      .then(res => {
        setTopPrescribers(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [period, previous, user]);
  useEffect(() => {
    // get("account/totalStats").then(res => {
    //   console.log("t stats, ", res.data);
    //   // setTopPrescribers(res.data);
    // });
  }, []);

  return (
    <>
      <MyDivider title={t("settings.topPrescribers")} />
      {topPrescribers !== undefined && !loading && (
        <>
          <div className="top-prescribers">
            {period !== "allTime" && (
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12}>
                  <ValueLabel label="From date:">
                    {topPrescribers.fromDate}
                  </ValueLabel>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <ValueLabel label="To date:">
                    {topPrescribers.toDate}
                  </ValueLabel>
                </Col>
              </Row>
            )}
            <List
              // pagination={{ position, align }}
              dataSource={topPrescribers.practitioners}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<div>{index + 1}) </div>}
                    title={<>{item.practitioner_name}</>}
                  />
                  <div>{item.total_plans}</div>
                </List.Item>
              )}
            />
          </div>
          <Space.Compact
            size="large"
            block
            // direction={isMobile ? "vertical" : "horizontal"}
            style={{ marginBottom: 16, marginTop: 16 }}
            // hidden={noData}
          >
            <Button
              block
              key="prev"
              disabled={period === "allTime"}
              onClick={_ => {
                setPrevious(previous => previous + 1);
              }}
            >
              {t("previous")}
            </Button>
            <Select
              // value={period}
              defaultValue={period}
              onChange={e => {
                setPeriod(e);
                setPrevious(0);
              }}
            >
              <Option value="30">30 {t("days")}</Option>
              <Option value="60">60 {t("days")}</Option>
              <Option value="90">90 {t("days")}</Option>
              <Option value="365">1 {t("year")}</Option>
              <Option value="allTime">{t("allTime")}</Option>
            </Select>
            <Button
              block
              key="next"
              disabled={previous === 0 || period === "allTime"}
              onClick={_ => setPrevious(previous => previous - 1)}
            >
              {t("next")}
            </Button>
          </Space.Compact>
        </>
      )}
      {loading && <Skeleton active />}
    </>
  );
};

function areEqual(prevProps, nextProps) {
  // console.log("prev, next preview", prevProps, nextProps);

  return true;
  // return (
  //   prevProps.key === nextProps.key &&
  //   prevProps.imageType === nextProps.imageType &&
  //   prevProps.added === nextProps.added
  // );
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

const TopPrescribersMemo = React.memo(TopPrescribers, areEqual);
export default TopPrescribersMemo;
// export default React.memo(Templates, areEqual);
// export default Templates;
