import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, Space } from "antd";
import Column from "../../components/layout/Col";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChild } from "@fortawesome/free-solid-svg-icons";
import TelerehabTable from "./TelerehabTable";
import ScheduleMeetingModal from "./ScheduleMeetingModal";
import { MeetingUpsert } from "../../types/Telerehab";

const Telerehab: React.FC = () => {
  // console.log("tr load");

  const { t } = useTranslation();

  const newMeetingData: MeetingUpsert = {
    id: 0
  };
  const [meeting, setMeeting] = useState<MeetingUpsert>(newMeetingData);
  const [showScheduleMeeting, setShowScheduleMeeting] = useState(false);

  return (
    <>
      <Column size="standard" style={{ marginTop: -50 }} key="col">
        <MyPageHeader
          key="ph"
          // onBack={() => null}

          title={t("menu.telerehab")}
          // subTitle="This is a subtitle"
          extra={[
            <Space.Compact key="btns">
              <Button
                key="anp"
                className="phb"
                onClick={() => {
                  setMeeting(meeting);
                  setShowScheduleMeeting(true);
                }}
              >
                <FontAwesomeIcon icon={faChild} />
                {t("telerehab.scheduleNew")}
              </Button>
            </Space.Compact>
          ]}
        />
        <div className="box">
          <TelerehabTable />
          {showScheduleMeeting && (
            <ScheduleMeetingModal
              upsertData={meeting}
              showModal={showScheduleMeeting}
              onCancel={() => {
                setShowScheduleMeeting(false);
              }}
              onDone={() => {
                setShowScheduleMeeting(false);
              }}
            />
          )}
        </div>
      </Column>
    </>
  );
};

export default Telerehab;
