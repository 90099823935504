import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import { useAuth } from "../../context/AuthContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import { get } from "../../api/Request";
import { useTranslation } from "react-i18next";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { config } from "../../data/Constants";
import AuthLayout from "../auth/AuthLayout";
import ValueLabel from "../../components/layout/ValueLabel";
import { Alert, Button, Col, Result, Row, Skeleton } from "antd";
import CurrencyCode from "../../components/helper/CurrencyCode";

const PayNow: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { user, refreshSession } = useAuth();

  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [sessionId, setSessionId] = useState<string | undefined>();
  const [clientEmail, setClientEmail] = useState<string | undefined>();
  const [pageLoading, setPageLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);

  // this one is for elements payment
  // const redirect_status = searchParams.get("redirect_status");
  const redirect_status = searchParams.get("session_id");
  const amount = searchParams.get("amount");
  const c = searchParams.get("c");
  const n = searchParams.get("n");
  const id = searchParams.get("id");
  const curr = searchParams.get("curr");
  const licence = searchParams.get("licence");
  const practitioners = searchParams.get("practitioners");
  const clinics = searchParams.get("clinics");
  const prolong = searchParams.get("prolong");
  const period = searchParams.get("period");

  // const stripePromise = loadStripe(config.STRIPE_KEY);

  useEffect(() => {
    if (redirect_status === "success") {
      // refresh session to get updated user data
      refreshSession();
    }
  }, [redirect_status]);

  const pay = () => {
    setPayLoading(true);
    const stripePromise = loadStripe(config.STRIPE_KEY);
    stripePromise
      .then(s => {
        s?.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as argument here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: sessionId ?? ""
        }).then(function (result) {
          console.log(result, "stripe then");
          //  get("payment/stripeCheckPayment/" + r.data.id).then(r => {
          //    setUser(r.data.user);
          //    navigate("/patients?ref=stripe+payment_completed", {
          //      replace: true
          //    });
          //  });
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
      })
      .finally(() => setPayLoading(false));
  };
  useEffect(() => {
    if (amount) {
      setPageLoading(true);

      get("payment/customPayment", {
        id,
        amount,
        curr,
        licence,
        clinics,
        practitioners,
        prolong,
        period,
        n,
        c
      })
        .then(r => {
          setClientSecret(r.data.clientSecret);
          setSessionId(r.data.id);

          setClientEmail(r.data.email);
        })
        .catch(error => console.error(error))
        .finally(() => setPageLoading(false));
    }
  }, []);

  useEffect(() => {
    if (user?.email) {
      // navigate("/patients", { replace: true });
    }
  }, [user, navigate]);

  const title = <MyPageHeader title={t("payment.customPayment")} />;

  return pageLoading ? (
    <Skeleton active />
  ) : (
    <AuthLayout title={title}>
      {clientEmail && (
        <Row align="middle">
          <Col span={12}>
            <ValueLabel label="Account">
              {clientEmail ?? "Undefined"}
            </ValueLabel>
          </Col>
          <Col span={12}>
            <ValueLabel label="Amount">
              <h4>
                <CurrencyCode currencyCode={curr} /> {amount}
              </h4>
            </ValueLabel>
          </Col>
          {licence !== "" && (
            <Col span={12}>
              <ValueLabel label="Licence">
                <h4> {t("account.licences.l" + licence)}</h4>
              </ValueLabel>
            </Col>
          )}

          {clinics !== "" && (
            <Col span={12}>
              <ValueLabel label="Clinics">{clinics}</ValueLabel>
            </Col>
          )}
          {practitioners !== "" && (
            <Col span={12}>
              <ValueLabel label="Practitioners">{practitioners}</ValueLabel>
            </Col>
          )}
          {prolong !== "" && (
            <Col span={12}>
              <ValueLabel label="Period">
                {prolong} {period}
              </ValueLabel>
            </Col>
          )}
          {n !== "" && <ValueLabel label="Note">{n}</ValueLabel>}
        </Row>
      )}

      {sessionId && (
        <Button
          loading={payLoading}
          htmlType="submit"
          onClick={pay}
          block
          type="primary"
          className="margin-top-16"
        >
          Pay Now
        </Button>
      )}

      {redirect_status && (
        <Result
          status="success"
          title="Payment Successful"
          subTitle="Thank you for your payment. Your account has been updated."
        />
      )}
      {clientEmail && user && clientEmail !== user.email && (
        <Alert
          message="You are currently logged in into different account!"
          type="error"
          className="margin-top-16"
        />
      )}
      {user?.email ? (
        <>
          <Button
            block
            onClick={() => navigate("/patients/", { replace: true })}
            className="margin-top-16"
            type="primary"
          >
            Go to Dashboard
          </Button>
        </>
      ) : (
        <Button
          block
          onClick={() => navigate("/login/", { replace: true })}
          className="margin-top-16"
        >
          Go to Login
        </Button>
      )}
    </AuthLayout>
  );
};

export default PayNow;
