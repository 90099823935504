import { Button, Card, Col, Row, Form, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { post } from "../../api/Request";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { useAuth } from "../../context/AuthContext";
import { Licence } from "../../types/UserData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ChooseLicence: React.FC = () => {
  const { t } = useTranslation();
  const { user, setUser } = useAuth();

  const [clinicNumber, setClinicNumber] = useState(
    user && user.clinics_allowed > 1 ? user.clinics_allowed : 2
  );
  const navigate = useNavigate();
  const selectLicence = (licence: Licence) => {
    post("account/selectLicence", { licence: licence, clinics: clinicNumber })
      .then(r => {
        setUser(r.data.user);

        navigate("/patients", { replace: true });
      })
      .catch(_ => {});
  };
  return (
    <div className="site-card-wrapper">
      <MyPageHeader title={t("account.selectLicence")}></MyPageHeader>
      <div className="choose-licence">
        <Row gutter={16}>
          <Col xs={24} md={24} lg={8}>
            <Card title={t("licences.individual.title")} bordered={true}>
              {t("licences.individual.description")}
              <ul className="licence-features">
                <li>
                  <FontAwesomeIcon icon={faCheck} />{" "}
                  {t("licences.individual.f1")}
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />{" "}
                  {t("licences.individual.f2")}
                </li>
              </ul>
              <div className="btn-bottom">
                <Button
                  type="primary"
                  block
                  disabled={
                    (user && user.licence === Licence.individual) ?? false
                  }
                  onClick={() => {
                    selectLicence(Licence.individual);
                  }}
                >
                  {t("select")}
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={8}>
            <Card
              title={t("licences.clinic.title")}
              bordered={true}
              actions={[]}
            >
              {t("licences.clinic.description")}
              <ul className="licence-features">
                <li>
                  <FontAwesomeIcon icon={faCheck} /> {t("licences.clinic.f1")}
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> {t("licences.clinic.f2")}
                </li>
              </ul>
              <div className="btn-bottom">
                <Button
                  type="primary"
                  block
                  disabled={(user && user.licence === Licence.clinic) ?? false}
                  onClick={() => {
                    selectLicence(Licence.clinic);
                  }}
                >
                  {t("select")}
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={8}>
            <Card
              title={t("licences.multiclinic.title")}
              bordered={true}
              actions={[]}
            >
              {t("licences.multiclinic.description")}
              <ul className="licence-features">
                <li>
                  <FontAwesomeIcon icon={faCheck} />{" "}
                  {t("licences.multiclinic.f1")}
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />{" "}
                  {t("licences.multiclinic.f2")}
                </li>
              </ul>
              <Form.Item label={t("account.numberOfClinics")}>
                <Input
                  type="number"
                  min={2}
                  value={clinicNumber}
                  onChange={e => setClinicNumber(parseInt(e.target.value))}
                />
              </Form.Item>
              <div className="btn-bottom">
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    selectLicence(Licence.multiClinic);
                  }}
                >
                  {t("select")}
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChooseLicence;
