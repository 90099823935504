import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import Parser from "html-react-parser";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../api/Request";
import { UserObject } from "../../types/UserData";
import { useTranslation } from "react-i18next";
import AuthLayout from "./AuthLayout";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { useUrlQuery } from "../../helpers/helper";
import { useGlobalContext } from "../../context/GlobalContext";
import ReCAPTCHA from "react-google-recaptcha";
import MyModal from "../../components/MyModal";
import LanguageSelect from "../../components/LanguageSelect";

interface GroupInfo {
  description?: string;
  id: number;
  name: string;
  logo: string;
  name_long: string;
  request_membership_number: boolean;
}
const Register: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [form] = Form.useForm();
  const { language } = useGlobalContext();

  const { t } = useTranslation();
  let urlQuery = useUrlQuery();
  const { Title } = Typography;

  const [loading, setLoading] = useState(false);
  const [licenceAgreement, setLicenceAgreement] = useState("");
  const [showLicenceAgreement, setShowLicenceAgreement] = useState(false);
  const [group, setGroup] = useState<GroupInfo>();
  const [gdprPP, setGdprPP] = useState("");
  const [showGdprPP, setShowGdprPP] = useState(false);

  useEffect(() => {
    // console.log(language, "lang");
    form.setFieldsValue({ ...form.getFieldsValue, lang: language });
  }, [language]);
  const checkCheckBox = (rule, value: string) => {
    if (!value) return Promise.reject(t("form.error.acceptTermsAndConditions"));
    return Promise.resolve();
  };
  const checkCheckBox2 = (rule, value: string) => {
    if (!value) return Promise.reject(t("auth.imHCPhint"));
    return Promise.resolve();
  };

  const onFinish = values => {
    setLoading(true);
    // console.log(values);
    // return;
    post<UserObject>("auth/register", {
      ...values,
      group_id: group?.id ?? 1
    })
      .then(_ => {
        navigate("/login", { replace: true });
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (user?.email) navigate("/patients", { replace: true });
  }, [user, navigate]);

  useEffect(() => {
    if (licenceAgreement === "" && showLicenceAgreement)
      get<string>("account/licenceAgreement")
        .then(r => {
          setLicenceAgreement(r.data);
        })
        .catch(_ => {});
  }, [showLicenceAgreement]);

  useEffect(() => {
    if (gdprPP === "" && showGdprPP)
      get<string>("account/privacyPolicy")
        .then(r => {
          setGdprPP(r.data);
        })
        .catch(_ => {});
  }, [showGdprPP]);

  useEffect(() => {
    const groupUrl = urlQuery.get("group");
    if (groupUrl) {
      get<GroupInfo>("auth/getRegisterGroupInfo/" + groupUrl)
        .then(r => {
          setGroup(r.data);
        })
        .catch(_ => {});
    }
  }, [urlQuery]);

  useEffect(() => {
    // console.log("group", group);
  }, [group]);

  return (
    <AuthLayout
      title={
        <MyPageHeader
          title={t("auth.registerForTherapist")}
          style={{ paddingBottom: 8 }}
        >
          {group && group.description
            ? Parser(group.description)
            : t("auth.registerSubtitle")}
        </MyPageHeader>
      }
    >
      {group && (
        <div className="box2 margin-bottom">
          <Title hidden level={4} style={{ textAlign: "center" }}>
            {group.name_long}
          </Title>
          {group.logo && (
            <img
              src={group.logo}
              alt={group.name}
              className="margin-bottom"
              style={{
                maxHeight: 130,
                maxWidth: "100%",
                display: "block",
                margin: "0 auto"
              }}
            />
          )}
        </div>
      )}
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="on"
        initialValues={{ lang: language }}
      >
        <Form.Item hidden name="lang">
          <Input />
        </Form.Item>
        <Form.Item
          label={t("form.clinicName")}
          name="clinic_name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("email")} name="email" rules={[{ required: true }]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label={t("auth.password")}
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t("firstName")}
          name="first_name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("lastName")}
          name="last_name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lang"
          label={t("language")}
          rules={[{ required: true }]}
        >
          <LanguageSelect defaultValue={language.toString()} />
        </Form.Item>
        <Form.Item
          name="terms"
          rules={[
            {
              required: true,
              validator: checkCheckBox
            }
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            {t("form.iAgreeToThe")}{" "}
            <Button
              type="link"
              size="small"
              style={{ padding: 0 }}
              onClick={() => setShowLicenceAgreement(true)}
            >
              {t("auth.licenceAgreement")}
            </Button>{" "}
            {t("and")}{" "}
            <Button
              type="link"
              size="small"
              style={{ padding: 0 }}
              onClick={() => setShowGdprPP(true)}
            >
              {t("auth.gdprPP")}
            </Button>
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="is_healthcare_practitioner"
          rules={[
            {
              required: true,
              validator: checkCheckBox2
            }
          ]}
          valuePropName="checked"
        >
          <Checkbox> {t("auth.imHCP")} </Checkbox>
        </Form.Item>
        <Form.Item
          name="recaptcha"
          label="reCAPTCHA"
          rules={[
            {
              required: true,
              message: "Please complete the reCAPTCHA!"
            }
          ]}
        >
          <ReCAPTCHA sitekey="6LcjDjkUAAAAAO1LVNpHpIGJG_jq02P2trjgNunC" />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit" block>
            {t("auth.createAccount")}
          </Button>
        </Form.Item>

        <Form.Item>
          <Button block onClick={() => navigate("/login/", { replace: true })}>
            {t("auth.backToLogin")}
          </Button>
        </Form.Item>
      </Form>
      <MyModal
        title={t("auth.licenceAgreement")}
        open={showLicenceAgreement}
        onCancel={() => setShowLicenceAgreement(false)}
        cancelText={t("close")}
      >
        <div>{Parser(licenceAgreement)}</div>
      </MyModal>
      <MyModal
        title={t("auth.gdprPP")}
        open={showGdprPP}
        onCancel={() => setShowGdprPP(false)}
        cancelText={t("close")}
      >
        <div>{Parser(gdprPP)}</div>
      </MyModal>
    </AuthLayout>
  );
};

export default Register;
