import { AlertProps } from "antd";
import React, { useState, useContext } from "react";

interface AlertMessage {
  message: string;
  type: AlertProps["type"];
}

interface ContextProps {
  messages?: AlertMessage[];
  type?: AlertProps["type"];
  addError: (message: string) => void;
  removeMessage: (i: number) => void;
  removeMessages: () => void;
}

const AlertContext = React.createContext<ContextProps>({
  addError: () => {},
  removeMessage: () => {},
  removeMessages: () => {}
});

export const useAlert = () => {
  return useContext(AlertContext);
};

export default function AlertProvider({ children }) {
  const [messages, setMessages] = useState<AlertMessage[]>([]);

  const removeMessage = (i: number) =>
    setMessages(messages.filter((m, index) => index !== i));

  const addMessage = (message: string, type: AlertProps["type"]) => {
    setMessages([...messages, { message: message, type: type }]);
  };

  const contextValue = {
    messages: messages,
    addError: message => {
      addMessage(message, "error");
    },
    removeMessage: (i: number) => {
      removeMessage(i);
    },
    removeMessages: () => {
      setMessages([]);
    }
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
}
