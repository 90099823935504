import React, { useEffect } from "react";
import { get } from "../../api/Request";
import { useNavigate } from "react-router-dom";

const StripePortalLink: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    get("payment/getStripePortalLink")
      .then(r => {
        console.log(r.data, "stripe link");
        if (r.data.link) window.location.href = r.data.link;
      })
      .catch(_ => {
        navigate("/settings/my-account", { replace: true });
      });
  }, []);
  return <>Loading Stripe link...</>;
};

export default StripePortalLink;
