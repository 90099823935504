import { message, notification } from "antd";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { config as appConfig } from "../data/Constants";
import { Language } from "../types/General";

// import Promise from "promise-polyfill";
var Promise = require("promise-polyfill").default;

// const customEMessage = {
//   error: (content: string, duration: number) => {
//     const key = `${new Date().getTime()}_${Math.random()}`;
//     message.error({
//       key,
//       content: (
//         <>
//           {content}
//           <button
//             className="close-button"
//             onClick={event => {
//               message.destroy(key);
//             }}
//           >
//             Close
//           </button>
//         </>
//       ),
//       duration: duration
//     });
//   }
// };
const axiosInstance = axios.create({
  baseURL: appConfig.API_URL,
  headers: {
    "Content-Type": "application/json"
  },
  // transformRequest: [
  //   data => {
  //     return JSON.stringify(data);
  //   }
  // ],
  // transformResponse: [
  //   data => {
  //     return JSON.parse(data);
  //   }
  // ],
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  response => {
    // console.log(response, "response");
    if (response.data.successToast)
      message.success(response.data.successToast, 6);
    if (response.data.infoToast) message.info(response.data.infoToast, 15);
    if (response.data.warningToast)
      message.warning(response.data.warningToast, 15);
    return Promise.resolve(response);
  },
  error => {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (error.response.data.message) {
        // notification.error({
        //   message: "Error",
        //   description: error.response.data.message,
        //   duration: 3
        // });
        // customEMessage.error(error.response.data.message, 6);
        message.error(error.response.data.message, 6);
      } else if (error.response.data.redirectToLogin) {
        window.location.replace(appConfig.BASE_URL + "logout");
      } else
        notification.error({
          message: "Error " + error.response.status,
          description: error.response.statusText,
          duration: 8,
          closeIcon: true
        });
      // message.error(
      //   error.response.status +
      //     " " +
      //     error.response.statusText +
      //     ", " +
      //     error.request.responseURL
      // );
      // console.log("error from server222", error.response);
      // console.log("error from server", error.response);
      // console.log("error status", error.response.status);
      // message.error(error.response.data.message, 3);

      return Promise.reject(error.response);
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.error("no response received", error.request);
      message.error(
        "Please check you internet connection. No response from server...",
        6
      );
    } else {
      // Something happened in setting up the request and triggered an Error
      console.error("Error #$", error);
      if (error.message) message.error("Request error! " + error.message, 6);
    }
  }
);

export function post<T = any, R = AxiosResponse<T>>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<R> {
  // const json = JSON.stringify(data);
  // const newData = JSON.parse(json);
  // console.log(json, "new json");
  // console.log(newData, "new data");
  let data2 = data ?? {};
  if (data2.lang === undefined && data2 instanceof FormData === false) {
    data2 = {
      ...data,
      lang:
        localStorage.getItem("language") !== null
          ? (localStorage.getItem("language")! as Language)
          : Language.enGB,
      appVersion: appConfig?.VERSION?.toString() ?? "0.0.0"
    };
  }
  return axiosInstance.post<T, R>(url, data2, {
    ...config,
    withCredentials: true
  });
}

export function get<T = any, R = AxiosResponse<T>>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<R> {
  let data2 = data ?? {};
  if (data2.lang === undefined) {
    data2 = {
      ...data,
      lang:
        localStorage.getItem("language") !== null
          ? (localStorage.getItem("language")! as Language)
          : Language.enGB,
      appVersion: appConfig?.VERSION?.toString() ?? "0.0.0"
    };
  }
  url = url + "?" + new URLSearchParams(data2).toString();
  return axiosInstance.get<T, R>(url, { ...config, withCredentials: true });
}
