import React, { useEffect } from "react";
import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout';
import { useLocation, useNavigate } from "react-router-dom";
import { NavBar } from "antd-mobile";
import { useGlobalContext } from "../../context/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface Props extends PageHeaderProps {
  mobileRight?: React.ReactNode;
}

interface Props extends PageHeaderProps { 
  mobileRight?: React.ReactNode;
  hidden?: boolean; 
  customBackText?: string;
  backUrl?: string;
}
const MyPageHeader: React.FC<Props> = ({ ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isTabletOrMobile } = useGlobalContext();

  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (props.hidden) return <></>;
  if (isTabletOrMobile) {
    return (
      <div className="top">
        <NavBar
          backArrow={
            location.key || props.backUrl !== undefined ? (
              props.customBackText !== undefined ? (
                <span style={{ fontSize: 14 }}>{props.customBackText}</span>
              ) : (
                <FontAwesomeIcon icon={faChevronLeft} />
              )
            ) : (
              ""
            )
          }
          onBack={() => {
            if (props.backUrl?.length) navigate(props.backUrl);
            else navigate(-1);
            // navigate.goBack();
          }}
          right={props.mobileRight ?? <></>}
        >
          {props.title}
        </NavBar>
        {props.extra && <div className="scroll">{props.extra}</div>}
      </div>
    );
  }
  return (
    <PageHeader
      onBack={() => {
        // history.goBack();
        navigate(-1);
        // window.history.back();
      }}
      {...props}
    />
  );
};

export default React.memo(MyPageHeader);
