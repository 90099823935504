import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Descriptions, Result } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { Licence } from "../../types/UserData";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ApiResWithUser } from "../../types/General";
import { UserRole } from "../../types/Practitioner";
import MyDivider from "../../components/helper/MyDivider";
import ColHalf from "../../components/layout/ColHalf";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import LanguageSelect from "../../components/LanguageSelect";

interface Props {
  newClinic: boolean;
}
const UpsertClinic: React.FC<Props> = ({ newClinic }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      if (![UserRole.admin, UserRole.owner].includes(user.practitioner.role))
        navigate("/settings");

      if (!newClinic) form.setFieldsValue(user.clinic);
      else {
        form.resetFields();
        form.setFieldsValue({ id: 0 });
      }
    }
  }, [user, navigate, form, newClinic]);

  const onFinish = values => {
    setLoading(true);
    post<ApiResWithUser>("account/upsertClinic", values)
      .then(r => {
        if (r.data.user !== undefined) {
          if (r.data.user) setUser(r.data.user);
          // if (r.data.user.clinics.length > 1)
          navigate("/settings/edit-clinic", { replace: true });
        }
        // else navigate("/patients", {replace:true});
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  return (
    <>
      {newClinic && user && (
        <>
          <Descriptions
            bordered
            column={{ xxl: 1, xl: 1, lg: 1, md: 2, sm: 2, xs: 1 }}
          >
            {user.clinics_allowed > 1 &&
              user.licence === Licence.multiClinic && (
                <Descriptions.Item label={t("account.allowedClinicNumber")}>
                  {user.current_clinics.length} ({t("account.outOf")}{" "}
                  {user.clinics_allowed})
                </Descriptions.Item>
              )}
          </Descriptions>
          {user.current_clinics.length === user.clinics_allowed && (
            <Result
              icon={<FontAwesomeIcon icon={faXmark} size="4x" />}
              title={t("account.upgradeToAddMoreClinics")}
              subTitle={t("account.contactSupport")}
            />
          )}
        </>
      )}
      {user && (
        <Form
          name="basic"
          form={form}
          layout="vertical"
          className="segmented"
          onFinish={onFinish}
          hidden={
            newClinic && user.current_clinics.length === user.clinics_allowed
          }
          autoComplete="on"
          preserve={false}
        >
          <Form.Item hidden name="id" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <ColHalf>
              <Form.Item
                label={t("clinicName")}
                name="clinic_name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </ColHalf>
            <ColHalf>
              <Form.Item
                label={t("language")}
                name="lang"
                initialValue={user.lang}
                rules={[{ required: true }]}
              >
                <LanguageSelect />
              </Form.Item>
            </ColHalf>
          </Row>

          <MyDivider
            title={t("clinic.contactInfo")}
            className="margin-top-32"
          />
          <Form.Item label={t("clinic.website")} name="website">
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <ColHalf>
              <Form.Item
                label={t("email")}
                name="email"

                // rules={[{ required: true, message: "Please input your email!" }]}
              >
                <Input type="email" />
              </Form.Item>
            </ColHalf>
            <ColHalf>
              <Form.Item label={t("clinic.phone")} name="phone">
                <Input />
              </Form.Item>
            </ColHalf>
          </Row>

          <Row gutter={16}>
            <ColHalf>
              <Form.Item label={t("clinic.mobile")} name="mobile">
                <Input />
              </Form.Item>
            </ColHalf>
            <ColHalf>
              <Form.Item label={t("clinic.fax")} name="fax">
                <Input />
              </Form.Item>
            </ColHalf>
          </Row>

          <MyDivider title={t("clinic.address")} />
          <Row gutter={16}>
            <ColHalf>
              <Form.Item label={t("clinic.address")} name="address">
                <Input />
              </Form.Item>
            </ColHalf>
            <ColHalf>
              <Form.Item label={t("clinic.address2")} name="address2">
                <Input />
              </Form.Item>
            </ColHalf>
            <ColHalf>
              <Form.Item label={t("clinic.city")} name="city">
                <Input />
              </Form.Item>
            </ColHalf>
            <ColHalf>
              <Form.Item label={t("clinic.postCode")} name="post_code">
                <Input />
              </Form.Item>
            </ColHalf>
          </Row>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" block>
              {newClinic ? t("settings.addNewClinic") : t("save")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default UpsertClinic;
