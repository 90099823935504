import React, { useState } from "react";
import { Collapse, Input } from "antd";
import { Muscle } from "../../types/Plan";
import { useTranslation } from "react-i18next";
import { defaultParam } from "./UpsertPlan";
import { ExercisesGetParam } from "../../types/PlanUpsert";

const { Panel } = Collapse;
const { Search } = Input;
interface Props {
  muscles: Muscle[];
  onSelect: (e: ExercisesGetParam) => void;
  onTitleChange: (title: string) => void;
}

const UPCatsMuscle: React.FC<Props> = ({
  muscles,
  onSelect,
  onTitleChange
}) => {
  const { t } = useTranslation();

  // console.log("UPC muscles rendering");

  const [muscleSearch, setMuscleSearch] = useState("");

  return (
    <>
      <Search
        allowClear
        onChange={e => {
          setMuscleSearch(e.target.value);
          // console.log(e);
        }}
        style={{ width: "100%" }}
      />
      <Collapse accordion>
        {muscles &&
          muscles
            .filter(muscl =>
              muscl.name
                .toLocaleLowerCase()
                .includes(muscleSearch.toLocaleLowerCase())
            )
            .map(m => (
              <Panel
                className="no-panel-content"
                showArrow={false}
                header={
                  <div
                    style={{ width: "100%" }}
                    onClick={() => {
                      onTitleChange(t("planUpsert.muscle") + ": " + m.name);

                      onSelect({
                        ...defaultParam,
                        muscle_id: m.id
                      });
                    }}
                  >
                    {m.name}
                  </div>
                }
                key={"musc" + m.id.toString()}
              ></Panel>
            ))}
      </Collapse>
    </>
  );
};

export default React.memo(UPCatsMuscle);
