import { Descriptions, List, Skeleton } from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { PractitionerStats } from "../../types/Practitioner";
import { get } from "../../api/Request";
import { useGlobalContext } from "../../context/GlobalContext";
import { formatDateTime } from "../../helpers/dateHelper";
import MyDivider from "../../components/helper/MyDivider";

interface Props {
  practitionerId: number;
}

const PractitionerTotalStats: React.FC<Props> = ({ practitionerId }) => {
  const { t } = useTranslation();

  const { language } = useGlobalContext();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<PractitionerStats>();

  const getStats = () => {
    setLoading(true);
    get<PractitionerStats>("practitioner/stats/" + practitionerId)
      .then(r => {
        setStats(r.data);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (practitionerId > 0) getStats();
  }, [practitionerId]);

  return (
    <>
      {loading && <Skeleton active />}
      {stats && !loading && (
        <>
          <Descriptions
            bordered
            column={{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label={t("patient.patients")}>
              {stats.patients_count}
            </Descriptions.Item>
            <Descriptions.Item label={t("pract.stats.exercisePlans")}>
              {stats.plans_count}
            </Descriptions.Item>
            <Descriptions.Item label={t("menu.templates")}>
              {stats.templates_count}
            </Descriptions.Item>
            {/* <Descriptions.Item  label={t("pract.stats.emailsSent")}>
              {stats.emails_sent_count}
            </Descriptions.Item>
            <Descriptions.Item  label={t("pract.stats.smsSent")}>
              {stats.sms_sent_count}
            </Descriptions.Item>
            <Descriptions.Item  label={t("pract.stats.whatsappSent")}>
              {stats.whatsapp_sent_count}
            </Descriptions.Item> */}
          </Descriptions>
          {stats.exercises_stats.length > 0 && (
            <>
              <MyDivider title={t("pract.stats.mostUsedExercises")} />

              <Descriptions bordered column={1}>
                {stats.exercises_stats.map(e => (
                  <Descriptions.Item label={e.e.title} key={e.e.id}>
                    {e.total}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </>
          )}
          {stats.login_log.length > 0 && (
            <>
              <MyDivider title={t("pract.stats.loginLog")} />
              <List
                itemLayout="horizontal"
                dataSource={stats.login_log.map(l => {
                  return { date: formatDateTime(l.time, language) };
                })}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta description={item.date} />
                  </List.Item>
                )}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PractitionerTotalStats;
