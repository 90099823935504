import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import {
  faChild,
  faCog,
  faFile,
  faHouseUser,
  faList
} from "@fortawesome/free-solid-svg-icons";
import { TabBar } from "antd-mobile";
import { useLocation } from "react-router-dom";
import { useLocationState } from "react-router-use-location-state";
import { useGlobalContext } from "../../context/GlobalContext";
// import { useLocationState } from "react-router-use-location-state";

const TabMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { isIos } = useGlobalContext();
  const { pathname } = location;

  // const [activeTab, setActiveTab] = useState("library");
  const [tab, setTab] = useLocationState("tab", "");
  const [exercisesCount, _] = useLocationState("exercisesCount", 0);
  const tabs = [
    {
      key: "/patients",
      title: t("menu.myPatients"),
      icon: <FontAwesomeIcon icon={faHouseUser} />
      // badge: Badge.dot
    },
    {
      key: "/my-exercises",
      title: t("menu.myExercises"),
      icon: <FontAwesomeIcon icon={faChild} />
      // badge: Badge.dot
    },
    {
      key: "/templates",
      title: t("menu.templates"),

      icon: <FontAwesomeIcon icon={faFile} />
      // badge: Badge.dot
    },
    // {
    //   key: "/telerehab",
    //   title: t("menu.telerehab"),

    //   icon: <FontAwesomeIcon icon={faFile} />
    //   // badge: Badge.dot
    // },

    {
      key: "/settings",
      title: t("settings.myAccount"),

      icon: <FontAwesomeIcon icon={faCog} />
    }
  ];
  const setRouteActive = (value: string) => {
    navigate(value);
  };

  return (
    <>
      {pathname === "/exercise-plan" && (
        <TabBar
          activeKey={tab}
          onChange={e => setTab(e)}
          className="up-bar"
          safeArea={true}
          style={{ padding: isIos ? "5px 5px 16px 5px" : "" }}
        >
          <TabBar.Item
            key="library"
            icon={<FontAwesomeIcon icon={faList} />}
            title={t("planUpsert.library")}
          />
          <TabBar.Item
            key="exercises"
            icon={<FontAwesomeIcon icon={faChild} />}
            title={t("planUpsert.exercises")}
          />
          <TabBar.Item
            key="preview"
            icon={<FontAwesomeIcon icon={faList} />}
            title={t("planUpsert.preview")}
            badge={exercisesCount}
          />
        </TabBar>
      )}
      {pathname !== "/exercise-plan" && (
        <TabBar
          activeKey={pathname}
          onChange={value => setRouteActive(value)}
          safeArea={true}
          style={{ padding: isIos ? "5px 5px 16px 5px" : "" }}
        >
          {tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      )}
    </>
  );
};

export default TabMenu;
