import { Language, RepsFormat } from "./General";
import { Filters, Pagination, TemplateList } from "./Patient";
import { ExerciseInPlan } from "./PlanUpsert";

export enum ImageType {
  photo = 0,
  lineart = 1
}

export interface MonitorOptionList {
  id: number;
  name: string;
  patient_question: string;
  from: number;
  to: number;
  from_label: string;
  to_label: string;
  inverted_graph: number;
}
export interface PromList {
  id: number;
  created: string;
  prom_name: string;
  prom_description: string;
  last_update: string;
  form?: any;
}

export enum PlanLayout {
  default = "default",
  superLarge = "super-large",
  largeImage = "large-image",
  compressed = "compressed",
  twoCol = "2-col",
  summary = "summary"
}

export enum TemplateTypes {
  none = 0,
  user_template = 1,
  rmp_community = 2
}

export interface UserTemplateCat {
  id: number;
  name: string;
  member_id: number;
}
export interface RmpTemplateCat {
  id: number;
  name: string;
  active: number;
}

export interface TemplateCat {
  id: number;
  active: number;
  name: string;
}

export interface TemplateTableProps {
  pageSize: number;
  page: number;
  sort: string;
  sortDirection: "ascend" | "descend" | string;
  search: string;
  templateType: "rmp" | "community" | "user" | string;
  catId: number;
  practitionerId: number;
}

export interface Plan {
  id: number;
  is_template: TemplateTypes;
  template_category_id?: number;
  has_tracking: boolean;
  name: string;
  desc: string;
  practitioner_id?: number;
  notes: string;
  image_type: ImageType;
  start: string | null;
  end: string | null;
  duration: number;
  created: string;
  last_update: string;
  patient_id: number;
  member_id: number;
  lang: Language;
  translations: Translation[];
  token: string;
}

export interface Translation {
  id: number;
  lang: Language;
}
export interface Exercise extends ExerciseInPlan {
  custom_exercise: boolean;
  reps_formated: RepsFormat;
}

export interface Monitor {
  id: number;
  name: string;
  patient_question: string;
  from: number;
  to: number;
  from_label: string;
  to_label: string;
  inverted_graph: number;
}

export interface PromResult {
  id: number;
  prom_id: number;
  plan_id: number;
  answers: string;
  schedule: string;
  created: string;
  last_update: string;
}

export interface Prom {
  prom_name: string;
  id: number;
  form: string;
  schedule: "start_end" | "start" | "end";
  results: PromResult[];
}

export interface PlanViewDTO {
  plan: Plan;
  exercises: Exercise[];
  monitor: Monitor[];
  monitor_ids: number[];
  prom_ids: number[];
  prom: Prom[];
  prom_results: number;
}

export interface Category {
  id: number;
  parent_id: number;
  name: string;
  cat_prefix: string;
  sort_order: number;
  active: number;
  count: number;
  childs?: Category[];
}

export interface Advice {
  id: number;
  parent_id: number;
  name: string;
  cat_prefix: string;
  sort_order: number;
  active: number;
}

export interface Diagnosis {
  id: number;
  name: string;
}

export interface Muscle {
  id: number;
  name: string;
}

export interface TemplateTableDTO {
  data: TemplateList[];
  total_items: number;
  total_items_filtered: number;
  total_pages: number;
  current_page: number;
  search_term: string;
  pagination: Pagination;
  filters: Filters;
}

export interface AllCats {
  cats: Category[];
  muscles: Muscle[];
  advices: Advice[];
  diagnosis: Diagnosis[];
}
