import { Alert, Button, Input, Radio } from "antd";
import Form from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PatientUpsert } from "../../types/Patient";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";
import { useGlobalContext } from "../../context/GlobalContext";
import { Language } from "../../types/General";
import { ImageType } from "../../types/Plan";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { post } from "../../api/Request";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import MyModal from "../../components/MyModal";

import dayjs from "dayjs";
import PhoneInput from "antd-phone-input";

interface Props {
  initialData: PatientUpsert;
  showModal: boolean;
  onDone: (id: number, name: string) => void;
  onDoneAndCNP?: (id: number, name: string) => void;
  onCancel: () => void;
}

const UpsertPatientModal: React.FC<Props> = ({
  initialData,
  showModal,
  onCancel,
  onDone,
  onDoneAndCNP
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { language } = useGlobalContext();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [patientExist, setPatientExist] = useState<null | number>(null);

  const exerciseImageType = [
    { key: 1, label: t("photo"), value: ImageType.photo },
    { key: 2, label: t("lineart"), value: ImageType.lineart }
  ];

  const checkCheckBox = (rule, value: string) => {
    if (!value) return Promise.reject(t("form.error.patientConsentRequired"));
    return Promise.resolve();
  };

  const checkDob = (rule, value: string) => {
    if (
      value === "" ||
      value === undefined ||
      value === null ||
      value === "__-__-____"
    )
      return Promise.resolve();

    if (value) {
      // console.log(value, "value");
      if (
        dayjs(
          value,
          language === Language.enUS ? "MM-DD-YYYY" : "DD-MM-YYYY",
          true
        ).isValid() === false
      )
        return Promise.reject(t("form.error.invalidDate"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    setPatientExist(null);
    form.resetFields();

    form.setFieldsValue({
      ...initialData,
      phone3: {
        countryCode:
          initialData.dial_code ?? user?.settings.default_phone_dial_code,
        phoneNumber: initialData.phone ?? ""
      }
    });
  }, [initialData, form]);

  const submit = (cnp?: boolean) => {
    form
      .validateFields()
      .then(v => {
        interface Res {
          id: number;
          message: string;
        }
        setLoading(true);
        post<Res>("patient/upsert", v)
          .then(r => {
            if (cnp && onDoneAndCNP !== undefined)
              onDoneAndCNP(r.data.id, v.first_name + " " + v.last_name);
            else onDone(r.data.id, v.first_name + " " + v.last_name);
          })
          .catch(_ => {})
          .finally(() => setLoading(false));
      })
      .catch(info => {
        // console.log("Validate Failed:", info);
      })
      .finally(() => {});
  };

  const checkForDuplicatePatient = () => {
    try {
      const patient = form.getFieldsValue();
      if (patient.first_name.length > 0 && patient.last_name.length > 0)
        post("patient/checkForDuplicate", patient).then(r => {
          if (r.data.patient_id) setPatientExist(r.data.patient_id);
          else setPatientExist(null);
        });
    } catch (error) {
      console.error("Error checking for duplicate patient:", error);
      return null;
    }
  };

  return (
    <MyModal
      confirmLoading={true}
      maskClosable={false}
      centered={true}
      title={
        initialData.id > 0
          ? t("patient.updatePatient")
          : t("patient.addNewPatient")
      }
      open={showModal}
      onCancel={onCancel}
      destroyOnClose={true}
      okText={
        initialData.id > 0
          ? t("patient.updatePatient")
          : t("patient.addNewPatient")
      }
      okButtonProps={{
        loading: loading
      }}
      onOk={() => {
        submit();
      }}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("cancel")}
        </Button>,
        <Button
          key="ok1"
          type="primary"
          onClick={v => {
            submit();
          }}
        >
          {initialData.id > 0
            ? t("patient.updatePatient")
            : t("patient.addNewPatient2")}
        </Button>,
        <Button
          hidden={initialData.id > 0 || onDoneAndCNP === undefined}
          key="ok2"
          type="primary"
          onClick={v => {
            submit(true);
          }}
        >
          {t("patient.addAndCreatePlan")}
        </Button>
      ]}
    >
      {initialData && showModal && form && (
        <Form
          form={form}
          id="patientForm"
          layout="vertical"
          onValuesChange={(v, patient) => {
            // console.log(v, t);
            // if (patient.first_name.length > 0 && patient.last_name.length > 0)
            //   post("patient/checkForDuplicate", patient).then(r => {
            //     if (r.data.patient_id) setPatientExist(r.data.patient_id);
            //     else setPatientExist(null);
            //   });
          }}
        >
          <Form.Item hidden name="id">
            <Input required />
          </Form.Item>
          <Form.Item
            label={t("firstName")}
            name="first_name"
            rules={[
              {
                required: true,
                message: t("form.fieldRequired", { field: t("firstName") })
              }
            ]}
          >
            <Input onBlur={checkForDuplicatePatient} />
          </Form.Item>
          <Form.Item
            label={t("lastName")}
            name="last_name"
            rules={[
              {
                required: true,
                message: t("form.fieldRequired", { field: t("lastName") })
              }
            ]}
          >
            <Input onBlur={checkForDuplicatePatient} />
          </Form.Item>
          <Form.Item label={t("patient.title2")} name="title">
            <Input placeholder={t("patient.titlePlaceholder")} />
          </Form.Item>
          <Form.Item
            label={t("form.email")}
            name="email"
            rules={[
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email"
              }
            ]}
            normalize={value => value.trim()}
          >
            <Input onBlur={checkForDuplicatePatient} />
          </Form.Item>
          {patientExist !== null && (
            <Alert
              message={t("patient.alreadyExists")}
              description={
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    onCancel();
                    navigate("/patient/" + patientExist);
                  }}
                >
                  {t("patient.viewPatient")}
                </Button>
              }
              type="warning"
              showIcon
            />
          )}

          {user?.settings.use_patient_email_cc_and_bcc_fields === true && (
            <>
              <Form.Item
                label={t("form.emailCC")}
                name="email_cc"
                rules={[
                  {
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email"
                  }
                ]}
                normalize={value => value.trim()}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("form.emailBCC")}
                name="email_bcc"
                rules={[
                  {
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email"
                  }
                ]}
                normalize={value => value.trim()}
              >
                <Input />
              </Form.Item>
            </>
          )}

          <Form.Item label={t("phone")} name="phone3">
            <PhoneInput
              enableSearch={true}
              onInput={event => {
                let value = event.target.value;

                // Split the input value by spaces
                let parts = value.split(" ");

                // Check if the second part starts with '0' and remove it
                if (parts[1] && parts[1].charAt(0) === "0") {
                  parts[1] = parts[1].slice(1);
                }

                // Join the parts back together and set the modified value
                event.target.value = parts.join(" ");
              }}
              disableParentheses={true}
              preferredCountries={["uk", "us", "ca", "au"]}
            />
          </Form.Item>

          <Form.Item
            name="dob"
            label={
              language === Language.enUS
                ? t("form.dob") + " " + t("form.format-mdy")
                : t("form.dob") + " " + t("form.format-dmy")
            }
            rules={[
              {
                validator: checkDob
              }
            ]}
          >
            <MaskedInput
              mask="00-00-0000"
              size="large"
              placeholder={
                language === Language.enUS ? "mm-dd-yyyy" : "dd-mm-yyyy"
              }
            />
          </Form.Item>
          <Form.Item label={t("form.exerciseImageType")} name="image_type">
            <Radio.Group
              options={exerciseImageType}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {/* <Form.Item name="send_login_details" valuePropName="checked">
          <Checkbox>{t("form.allowPatientLogin")}</Checkbox>
        </Form.Item> */}
          {initialData.id === 0 && (
            <Form.Item
              tooltip={t("help.patientConsent")}
              name="patient_consent"
              rules={[
                {
                  required: true,
                  validator: checkCheckBox
                }
              ]}
              valuePropName="checked"
            >
              <Checkbox>{t("form.patientConsent")}</Checkbox>
            </Form.Item>
          )}
        </Form>
      )}
    </MyModal>
  );
};

export default UpsertPatientModal;
