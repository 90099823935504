import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { ApiResWithUser } from "../../types/General";

const IntegrationApiKey: React.FC = () => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = () => {
    setLoading(true);
    post<ApiResWithUser>("integrations/upsertApiKey", form.getFieldsValue())
      .then(r => {
        if (r.data.user) setUser(r.data.user);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // console.log(user);
  }, [user, navigate]);

  return (
    <Form
      name="basic"
      form={form}
      layout="vertical"
      initialValues={{
        api_key: user?.settings.integration_api_key
      }}
      onFinish={onFinish}
      autoComplete="on"
    >
      <Form.Item
        label={t("settings.integrationApiKey", {
          i: user?.clinic.integration_enabled.toLocaleUpperCase()
        })}
        name="api_key"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" block>
          {t("save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default IntegrationApiKey;
