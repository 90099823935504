import { Form, Result, Descriptions, List, Skeleton } from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../context/AuthContext";
import {
  PractitionerStats,
  PractitionerUpsert
} from "../../types/Practitioner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { get } from "../../api/Request";
import { useGlobalContext } from "../../context/GlobalContext";
import { formatDateTime } from "../../helpers/dateHelper";
import MyDivider from "../../components/helper/MyDivider";
import MyModal from "../../components/MyModal";
import PractitionerPlanChart from "../account/PractitionerPlanChart";
import PractitionerTotalStats from "./PractitionerTotalStats";

interface Props {
  practitioner: PractitionerUpsert;
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

const PractitionerStatsModal: React.FC<Props> = ({
  practitioner,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [form] = Form.useForm();
  const { language } = useGlobalContext();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if (practitioner.id > 0) getStats();
  }, [practitioner]);

  return (
    <MyModal
      title={t("pract.stats.title")}
      open={showModal}
      onCancel={onCancel}
      okText={t("close")}
      width="940px"
      destroyOnClose
      // okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        onCancel();
      }}
    >
      {practitioner && user && form && (
        <Result
          icon={<FontAwesomeIcon icon={faUser} size="4x" />}
          title={practitioner.first_name + " " + practitioner.last_name}
          subTitle={practitioner.email}
        />
      )}
      {practitioner && (
        <>
          <MyDivider title={t("account.planChart")} />

          <PractitionerPlanChart practitionerId={practitioner.id} />
          <PractitionerTotalStats practitionerId={practitioner.id} />
        </>
      )}
    </MyModal>
  );
};

export default PractitionerStatsModal;
