import React, { useEffect, useState } from "react";
import { PlanViewDTO } from "../../types/Plan";
import { useAuth } from "../../context/AuthContext";
import { Column } from "@ant-design/plots";
import { get } from "../../api/Request";
import { MonitorResult } from "../../types/Tracking";
import { getChartColor, getPatientSalutation } from "../../helpers/helper";
import { Result } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import ValueLabel from "../../components/layout/ValueLabel";
import Paragraph from "antd/lib/typography/Paragraph";
import { Patient2 } from "../../types/Patient";
import { useTranslation } from "react-i18next";

interface Props {
  plan: PlanViewDTO;
  patient: Patient2;
}
const PlanMonitorResult: React.FC<Props> = ({ plan, patient }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>();

  const getData = () => {
    get<MonitorResult[]>("tracking/monitorChartData/" + plan.plan.id)
      .then(r => {
        // console.log(r.data, "m data");
        setData(
          r.data.map(d => {
            return {
              d,
              config: {
                data: d.chart_data,
                xField: "x",
                yField: "y",
                columnWidthRatio: 0.8,
                colorField: "x",
                color: ({ x }) => {
                  const data = d.chart_data.find(d => d.x === x);
                  if (data) return getChartColor(data.y, d.inverted).color;
                },
                label: {
                  position: "",
                  // 'top', 'bottom', 'middle',
                  style: {
                    // fill: "#FFFFFF",
                    opacity: 0.6
                  }
                  // content: originData => {
                  //   const val = parseInt(originData.value);
                  //   if (val > 6) return "Warning";
                  // }
                },
                xAxis: {
                  label: {
                    autoHide: true,
                    autoRotate: false
                  }
                },

                meta: {
                  x: {
                    alias: ""
                  },
                  y: {
                    alias: d.name
                  }
                },
                slider: {
                  start: 0,
                  end: 1
                }
              }
            };
          })
        );
      })
      .catch(e => {});
  };
  useEffect(() => {
    getData();
  }, [plan]);

  return (
    <>
      {user && data !== undefined && (
        <>
          {data.map(m => (
            <div key={m.d.name} style={{ textAlign: "center" }}>
              <h2>{m.d.name}</h2>
              <h3>{m.d.patient_question}</h3>
              {m.config.data.length > 0 && <Column {...m.config} />}
              {m.config.data.length === 0 && (
                <Result
                  style={{
                    background: "#f0f0f0",
                    borderRadius: 8,
                    margin: "16px"
                  }}
                  icon={<FontAwesomeIcon icon={faChartLine} size="4x" />}
                  title={t("plan.noTrackRehabRes")}
                  subTitle={t("plan.noTrackRehabRes2")}
                  extra={
                    <>
                      <h3>{getPatientSalutation(patient)}</h3>
                      <ValueLabel label={t("patient.trAccessCodeLabel")}>
                        <Paragraph
                          copyable={{
                            text:
                              "https://trackrehab.com/c/" + patient.access_code,
                            tooltips: [t("copy"), t("copied")]
                          }}
                        >
                          {patient.access_code}
                        </Paragraph>
                      </ValueLabel>
                    </>
                  }
                />
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default PlanMonitorResult;
