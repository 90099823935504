import { Descriptions, Skeleton } from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../context/AuthContext";
import { TotalStats } from "../../types/Practitioner";
import { get } from "../../api/Request";
import MyDivider from "../../components/helper/MyDivider";

const AccountTotalStats: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<TotalStats>();

  const getStats = () => {
    setLoading(true);
    get<TotalStats>("account/totalStats/")
      .then(r => {
        setStats(r.data);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (user) getStats();
  }, [user]);

  return (
    <>
      {loading && <Skeleton active />}
      {stats && !loading && (
        <>
          <MyDivider title={t("account.totalStats")} />
          <Descriptions
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label={t("patient.patients")}>
              {stats.patients_count}
            </Descriptions.Item>
            <Descriptions.Item label={t("pract.stats.exercisePlans")}>
              {stats.plans_count}
            </Descriptions.Item>
            <Descriptions.Item label={t("menu.templates")}>
              {stats.templates_count}
            </Descriptions.Item>
            <Descriptions.Item label={t("pract.stats.trSessions")}>
              {stats.tr_sessions}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </>
  );
};

export default AccountTotalStats;
