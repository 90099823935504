import React, { useRef, useState } from "react";
import { Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";
import UpsertPatientModal from "../../pages/patients/UpsertPatientModal";
import { ImageType } from "../../types/Plan";
import type { MenuProps } from "antd";

type MenuItem = Required<MenuProps>["items"][number];
// import type { MenuProps } from "antd";

// type MenuItem = Required<MenuProps>["items"][number];
const MainMenu: React.FC = () => {
  const [showPatientModal, setShowPatientModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  const myPatientsLink = useRef<HTMLAnchorElement>(null);
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type
    } as MenuItem;
  }

  const items: MenuItem[] = [
    { label: "item 1", key: "item-1" }, // remember to pass the key prop
    {
      label: <Link to="/templates">{t("menu.templates")}</Link>,
      key: "item-2"
    }, // which is required
    {
      label: <Link to="/patients">{t("menu.myPatients")}</Link>,
      key: "item-mp",
      children: [
        {
          label: (
            <Link to="/patients" ref={myPatientsLink}>
              {t("menu.myPatients")}
            </Link>
          ),
          key: "item-6"
        }
      ]
    }
  ];

  return (
    <div className="wrapper">
      <Logo />
      <Menu
        items={items}
        hidden
        theme="dark"
        mode="horizontal"
        triggerSubMenuAction="hover"
        // selectedKeys={[current]}
        // onClick={e => setCurrent(e.key)}
      />
      <Menu
        theme="dark"
        mode="horizontal"
        // onClick={e => handleClick(e)}
        // selectedKeys={[current]}
        triggerSubMenuAction="hover"
      >
        {user === null && (
          <>
            <Menu.Item key="mail">
              <Link to="/login">{t("login")}</Link>
            </Menu.Item>
          </>
        )}
        {user != null && (
          <>
            <Menu.Item key="create-plan">
              <Link to="/exercise-plan?id=0&mode=plan">
                {t("plan.createNewPlan")}
              </Link>
            </Menu.Item>
            <Menu.SubMenu
              key="patients"
              title={t("menu.myPatients")}
              onTitleClick={() => myPatientsLink.current?.click()}
            >
              <Menu.Item key="my_patients">
                <Link to="/patients" ref={myPatientsLink}>
                  {t("menu.viewMyPatients")}
                </Link>
              </Menu.Item>
              <Menu.Item
                key="new_patient"
                onClick={() => setShowPatientModal(true)}
              >
                {t("menu.addNewPatient")}
              </Menu.Item>
            </Menu.SubMenu>
            {/* <Menu.Item key="alipay">
              <a
                href="https://ant.design"
                target="_blank"
                rel="noopener noreferrer"
              >
                Navigation Four - Link
              </a>
            </Menu.Item> */}
            {/* <Menu.SubMenu
                key="templates"
                title={t("menu.templates")}
                onTitleClick={() => navigate("templates")}
              >
                <Menu.Item key="templates2">
                  <Link to="/templates">{t("menu.myTemplates")}</Link>
                </Menu.Item>
              </Menu.SubMenu> */}
            <Menu.Item key="templates">
              <Link to="/templates">{t("menu.templates")}</Link>
            </Menu.Item>
            <Menu.Item key="myexercises">
              <Link to="/my-exercises">{t("menu.myExercises")}</Link>
            </Menu.Item>
            <Menu.Item key="telerehab">
              <Link to="/telerehab">{t("menu.telerehab")}</Link>
            </Menu.Item>
            <Menu.Item key="stats">
              <Link to="/stats">{t("menu.stats")}</Link>
            </Menu.Item>
            <Menu.Item key="contact">
              <Link to="/contact">{t("menu.contact")}</Link>
            </Menu.Item>
          </>
        )}
      </Menu>

      {user && user.settings && (
        <UpsertPatientModal
          initialData={{
            id: 0,
            first_name: "",
            last_name: "",
            send_login_details: true,
            patient_consent: true,
            phone: "",
            dial_code: user?.settings.default_phone_dial_code,
            image_type: ImageType.photo
          }}
          showModal={showPatientModal}
          onDone={id => {
            setShowPatientModal(false);
            navigate("/patient/" + id);
          }}
          onDoneAndCNP={id => {
            setShowPatientModal(false);
            navigate("/exercise-plan?patient=" + id);
          }}
          onCancel={() => {
            setShowPatientModal(false);
          }}
        />
      )}
    </div>
  );
};

export default MainMenu;
