import { Col, ColProps } from "antd";
import React from "react";
interface Props extends ColProps { 
  children: React.ReactNode;
}

const ColHalf: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={12} {...rest}>
      {children}
    </Col>
  );
};

export default ColHalf;
