import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";
import AuthLayout from "./AuthLayout";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { useUrlQuery } from "../../helpers/helper";
interface AuthFormDTO {
  code: string;
  password: string;
}
const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  let urlQuery = useUrlQuery();

  const onFinish = (values: AuthFormDTO) => {
    setLoading(true);
    post("resetPasswordCode", {
      code: values.code,
      password: values.password
    })
      .then(_ => {
        navigate("/login", { replace: true });
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  const checkPassword = (_, value: string) => {
    if (value) {
      if (form.getFieldValue("password") !== value)
        return Promise.reject(t("auth.passwordDoesntMatch"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (user?.email) navigate("/patients", { replace: true });
  }, [user, navigate]);

  return (
    <AuthLayout>
      <MyPageHeader title={t("auth.resetPassword")}></MyPageHeader>

      <div className="">
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="on"
        >
          <Form.Item hidden name="code" initialValue={urlQuery.get("c")}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t("auth.newPassword")}
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t("auth.confirmPassword")}
            name="password_c"
            rules={[
              { required: true },
              {
                validator: checkPassword
              }
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" block>
              {t("auth.resetPassword")}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              type="dashed"
              block
              onClick={() => navigate("/login/", { replace: true })}
            >
              {t("auth.backToLogin")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordForm;
